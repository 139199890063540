import localForage from 'localforage';

import LocalForageKeys from './localForageKeys';
import { ddlog } from './miscellaneous';
type KEYS = keyof LocalForageKeys;

const localForageWrapper = {
  ...localForage,
  getItem: async <T extends KEYS>(key: T): Promise<LocalForageKeys[T] | null> => {
    try {
      return localForage.getItem(key);
    } catch (error) {
      ddlog.warn('Error in localForage(GET):', { error });
      return null;
    }
  },
  setItem: async <T extends KEYS>(key: T, value: LocalForageKeys[T]) => {
    try {
      return localForage.setItem(key, value);
    } catch (error) {
      ddlog.warn('Error in localForage(SET):', { error });
    }
  },
};

export default localForageWrapper;
