import { IEnvironment } from 'config/env';

const env: IEnvironment = {
  ENV: '',
  GQL_URL: '',
  MEDIA_URL: '',
  TF_SVCGATEWAY_URL: 'https://ef.io',
  SUPPORT_URL: '',
  AVAILABILITY_INTERVAL: 0,
  BOOKING_INTERVAL: 0,
  USER_INTERVAL: 0,
  ENTRY_STATUS_INTERVAL: 0,
  ACR_TODO_INTERVAL: 0,
  LESSON_DEFINITION_INTERVAL: 0,
  UNCLAIMED_LESSONS_INTERVAL: 0,
  DISCONNECT_AFTER: 0,
  HELP_CENTER_URL: '',
  TECH_CHECK_URL_TEMPLATE: '',
  LOGIN: {
    SIGNIN_SIGNUP_USER_FLOW: '',
    RESET_USER_FLOW: '',
    AUTHORITY_BASE_URL: '',
    CLIENT_ID: '',
    TOKEN_SCOPE: '',
  },
  DATADOG: {
    APPLICATION_ID: '',
    CLIENT_TOKEN: '',
    SERVICE_NAME: '',
    VIRTUAL_ENV: '',
  },
  API_KEYS: {},
  EVC_TUNNELS: {
    US_NV: '',
    CN_SH: '',
  },
  VERSION: '',
};

export default env;
