import React from 'react';
import { CSSTransition } from 'react-transition-group';

import Profile from '../Profile';
import Settings from '../Settings';
import { DrawerTypes } from './DrawerTypes';
import { DrawerWrapper, BackgroundOverlay, Drawer } from './styledComponents';

interface SideBarDrawerProps {
  teacherId: string;
  school?: string;
  drawer?: DrawerTypes | null;
  showOverlay?: boolean;
  closeDrawer: () => void;
}

const SideBarDrawer = ({ teacherId, school, drawer, closeDrawer, showOverlay }: SideBarDrawerProps) => {
  const hasDrawer = drawer !== null && drawer !== undefined;
  const showDrawer = hasDrawer || showOverlay;
  return (
    <DrawerWrapper style={{ width: showDrawer ? '100vw' : 0 }}>
      <CSSTransition unmountOnExit in={showDrawer} timeout={300} classNames="fade" appear>
        <BackgroundOverlay onClick={closeDrawer} />
      </CSSTransition>

      <CSSTransition unmountOnExit timeout={200} classNames="slide" appear in={drawer === DrawerTypes.PROFILE}>
        <Drawer>
          <Profile teacherId={teacherId} school={school} isLarge={false} isOpen setIsOpen={closeDrawer} />
        </Drawer>
      </CSSTransition>

      <CSSTransition unmountOnExit timeout={200} classNames="slide" appear in={drawer === DrawerTypes.SETTINGS}>
        <Drawer>
          <Settings teacherId={teacherId} close={closeDrawer} />
        </Drawer>
      </CSSTransition>
    </DrawerWrapper>
  );
};

export default SideBarDrawer;
