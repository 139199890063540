import { rgba } from 'polished';
import React, { SyntheticEvent } from 'react';
import styled, { css } from 'styled-components';

import Icon from 'components/Icon';
import { getStylesForInvalid, InputStyleProps, InputStyles } from 'components/sharedStyles';
import { SpacingScale } from 'theme/index';
import { logEvent } from 'utils/analytics/helpers';

const TickTransitionTime = 200;

export interface SizeableCheckboxProps extends InputStyleProps {
  placeholder?: string;
  className?: string;
  name?: string;
  value?: string;
  checked?: boolean;
  onChange?: (e: SyntheticEvent) => void;
  size?: number;
  analytics?: string;
  analyticsTeacherId?: string;
}

const Checkbox = styled.input.attrs({ type: 'checkbox' })`
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 16px;
  height: 16px;
  appearance: none;
  outline: none;
  border: none;
  box-sizing: border-box;
  margin: 0;
`;

const InputWrapper = styled.div`
  position: relative;
  display: inline-flex;
  margin-right: ${({ theme }) => theme.spacingScale.withUnits(SpacingScale.xs)};
`;

// visible checkbox
const CheckboxMask = styled.div<SizeableCheckboxProps>`
  ${InputStyles}
  padding: 0;
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;

  ${(props) => props.invalid && getStylesForInvalid(props.theme.color.warning, props.theme.color.warning)}

  ${(props) => (props.focus ? `box-shadow: 0 0 0 3px ${props.theme.color.focus};` : '')}
  ${Checkbox}:active ~ &,
  ${Checkbox}:focus ~ & {
    box-shadow: 0 0 0 3px ${(props) => rgba(props.theme.color.focus, 0.4)};
  }

  ${Checkbox}:checked ~ & {
    background: ${({ theme }) => theme.color.tfBlue};
    transition: background 0.01s linear;
    border: 1px solid ${({ theme }) => theme.color.tfBlue};
  }

  ${(props) =>
    props.invalid &&
    css`
      ${Checkbox}:checked ~ & {
        background: ${props.theme.color.warning};
        transition: background 0.01s linear;
        border: 1px solid ${props.theme.color.warning};
      }
    `}

  ${Checkbox}:not(:checked) ~ & {
    background: ${({ theme }) => theme.color.white};
    transition: background ${TickTransitionTime}ms linear ${TickTransitionTime / 2}ms;
  }
  ${(props) =>
    props.loading &&
    css`
      ${Checkbox} ~ & {
        box-shadow: none;
        background: ${props.theme.color.efGrey};
        border-color: ${props.theme.color.border.disabled};
        color: ${props.theme.color.text.disabled};
      }
    `}

  ${Checkbox}:disabled ~ & {
    box-shadow: none;
    background: ${({ theme }) => theme.color.efGrey};
    border-color: ${({ theme }) => theme.color.border.disabled};
    color: ${({ theme }) => theme.color.text.disabled};
  }
`;

const IconWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 1px;
`;

const StyledIcon = styled(Icon)`
  ${Checkbox}:not(:checked) ~ ${CheckboxMask} & {
    opacity: 0.01 !important;
    transform: scale(0.01);
    transition: opacity ${TickTransitionTime}ms ease-out, transform ${TickTransitionTime}ms ease-out;
  }

  ${Checkbox}:checked ~ ${CheckboxMask} & {
    opacity: 1;
    transition: opacity ${TickTransitionTime}ms ease-out, transform ${TickTransitionTime}ms ease-out;
  }

  > svg {
    padding-right: 2px;
    margin-bottom: 3px;
  }
`;

const ResizeableCheckbox: React.FunctionComponent<SizeableCheckboxProps> = ({
  checked,
  disabled,
  name,
  onChange,
  placeholder,
  size,
  value,
  analytics,
  analyticsTeacherId,
}) => (
  <InputWrapper>
    <Checkbox
      placeholder={placeholder}
      name={name}
      onChange={onChange!}
      onClick={() => analytics && logEvent(analytics, { Filter: value, 'Teacher ID': analyticsTeacherId })}
      disabled={disabled}
      value={value}
      checked={checked}
      size={size}
    />
    <CheckboxMask checked={checked} size={size}>
      <IconWrapper>
        <StyledIcon icon="tick" size={size && size - 4} color={disabled ? 'gray' : 'white'} />
      </IconWrapper>
    </CheckboxMask>
  </InputWrapper>
);

export default ResizeableCheckbox;
