import { styled } from '@mui/material/styles';

import { PrimaryButton } from 'components/Button';

export const Page = styled('div')<{ background: string }>`
  width: 100%;
  height: 100%;
  margin: 0;
  background-color: ${(props) => props.background};
  display: flex;
  justify-content: center;
  text-align: center;
  color: ${({ theme }) => theme.color.inkBlack};
`;

export const Halo = styled('img')`
  position: absolute;
  transform: translate3d(-620px, -300px, 0);
  top: 50%;
  left: 50%;
  width: 556px;
  height: 596px;
`;

export const Container = styled('div')`
  width: 466px;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const LogoContainer = styled('div')`
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-bottom: 32px;
`;

export const LoginButton = styled(PrimaryButton)`
  margin-top: 24px;
  margin-bottom: 10px;
  width: 186px;
`;

export const Footer = styled('div')`
  margin-bottom: 20px;
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  font-size: 10px;
  line-height: 2;
`;

export const ContentWhiteCard = styled('div')`
  width: 466px;
  min-height: 466px;
  border-radius: 4px;
  box-shadow: 25px 25px 50px 0 rgba(118, 143, 197, 0.37);
  background-color: #ffffff;
  position: relative;
`;

export const Aligned = styled('div')`
  margin: 56px 37px 0 38px;
`;

export const Title4 = styled('h4')`
  font-size: 28px;
  line-height: 1.21;
  font-weight: bold;
  margin: 0px 20px 8px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: center;
  color: ${({ theme }) => theme.color.inkBlack};
`;
export const SummaryBody = styled('p')`
  width: 386px;
  height: 48px;
  margin: 8px 0 10.7px;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: ${({ theme }) => theme.color.inkBlack};
`;

export const RequestId = styled('p')`
  margin: 0;
  padding: 0;
  font-size: 10px;
  text-align: center;
  color: ${({ theme }) => theme.color.inkBlack};
`;

export const ErrorImage = styled('img')`
  width: 188px;
  height: 179px;
  margin: 2px 82.7px 24.3px 80px;
  object-fit: contain;
`;
