import { gql, useQuery } from '@apollo/client';

import { School } from 'model/misc';

const SCHOOLS_GQL = gql`
  query Schools {
    schools {
      edges {
        id
        name
      }
    }
  }
`;

export const useSchools = () => {
  const { data, ...rest } = useQuery<{ schools: { edges: School[] } }>(SCHOOLS_GQL);

  return {
    schools: data?.schools?.edges,
    ...rest,
  };
};
