import React from 'react';

import * as S from './avatar.styled';

export interface AvatarProps {
  profilePicture?: string;
  initials: string;
  size: number;
  fontSize: number;
  fontColor?: string;
  avatar?: string;
  paddingTop?: number;
}

export const Avatar = ({ initials, size, fontSize, fontColor, avatar, paddingTop, profilePicture }: AvatarProps) => (
  <S.AvatarWrapper
    data-qa="avatar"
    size={size}
    fontSize={fontSize}
    fontColor={fontColor}
    avatar={avatar}
    paddingTop={paddingTop}
    hideBackground={!!profilePicture}
  >
    {!profilePicture ? initials : <S.AvatarImg data-qa="avatar-img" src={profilePicture} alt="avatar" size={size} />}
  </S.AvatarWrapper>
);
