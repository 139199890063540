import TeacherAvailAbility from 'cypress/fixtures/teachers/data/availability.json';
import TeacherAxisBooking from 'cypress/fixtures/teachers/data/axis-bookings.json';
import TeacherUnclaimedBooking from 'cypress/fixtures/teachers/data/unclaimed-bookings.json';
import { HttpResponse, http } from 'msw';

import env from 'config/env';

// Hardcode teacher id for now
const teacherId = '983426e9-6a2d-4e92-ba36-42085c9520cf';
export const TeacherMockHandler = [
  http.get(`${env.TF_SVCGATEWAY_URL}/v1/teachers/${teacherId}/availAbility`, () =>
    HttpResponse.json(TeacherAvailAbility),
  ),
  http.get(`${env.TF_SVCGATEWAY_URL}/v1/teachers/${teacherId}/axis-bookings`, () =>
    HttpResponse.json(TeacherAxisBooking),
  ),
  http.get(`${env.TF_SVCGATEWAY_URL}/v1/teachers/${teacherId}/unclaimed-bookings`, () =>
    HttpResponse.json(TeacherUnclaimedBooking),
  ),
  http.get(`${env.TF_SVCGATEWAY_URL}/v1/group-lessons/teacher-join-requests`, () => HttpResponse.json({ data: [] })),
];
