import { IconProps as IIconProps } from './Icon';
import { IconUnion as IIconUnion } from './IconDeclarations';

export type IconProps = IIconProps;

export { IconSize, IconRotation } from './shared';

export { LoadingSpinner } from './LoadingSpinner';
export { default, SuffixIcon } from './Icon';

export type IconUnion = IIconUnion;
