import { TextField as MuiTextField, Autocomplete } from '@mui/material';
import { rgba } from 'polished';
import React from 'react';
import { Helmet } from 'react-helmet';
import styled, { css, useTheme } from 'styled-components';

import Icon from 'components/Icon';
import { SpacingScale } from 'theme/index';
import { spacing } from 'theme/spacing';

export interface InputStyleProps {
  focus?: boolean;
  invalid?: boolean;
  valid?: boolean;
  loading?: boolean;
  complete?: boolean;
  readOnly?: boolean;
  disabled?: boolean;
}

export const ELLIPSIS_CSS = `
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const BOX_SHADOW = `
  &:hover, &:focus {
    box-shadow: 0 2px 12px 0 #009eeb;
    outline: 0;
  }
`;

export const Ellipsis = styled.div`
  width: 100%;
  ${ELLIPSIS_CSS}
`;

const DEFAULT_WIDTH = 1158;

const addPxIfNeeded = (n: number | string) => (typeof n === 'number' ? n + 'px' : n);

export const CenterItems = styled.div<{ maxW?: number | string }>`
  position: relative;
  margin: 0 auto;
  max-width: ${(props) => addPxIfNeeded(props.maxW ?? DEFAULT_WIDTH)};
`;

export const ZIndex50 = styled.div`
  z-index: 500;
  position: relative;
  width: 100%;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 64px;
`;

export const AppLayout = styled.div<{ mobile?: boolean }>`
  display: flex;
  flex-direction: row;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  overflow: hidden;

  ${(props) => props.mobile && 'flex-direction: column;'}
`;

export const getStylesForInvalid = (shadowColor: string, borderColor: string) =>
  `box-shadow: 0 4px 12px ${rgba(shadowColor, 0.5)};
   border-color: ${borderColor};`;

export const InputStyles = css<InputStyleProps>`
  background: ${({ theme }) => theme.color.white};
  box-sizing: border-box;
  border-radius: ${({ theme }) => theme.form.input.borderRadius};
  color: ${({ theme }) => theme.color.text.default};
  font-size: ${({ theme }) => theme.form.input.fontSize};

  /* height of the component is 40px = 24 + 7 + 7 + 1 + 1 */
  line-height: 24px;

  ${({ theme }) => `
    padding: ${theme.spacingScale.withUnits(SpacingScale.xs)}
      ${theme.spacingScale.withUnits(SpacingScale.s)};`}

  ${({ theme }) => `
    border: ${theme.form.input.borderWidth} solid ${theme.color.border.untouched};
  `}
  box-shadow: 0 2px 8px 0 ${({ theme }) => theme.color.shadow.untouched};
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: border 0.25s linear, box-shadow 1s cubic-bezier(0.175, 0.885, 0.32, 1.275);

  ${(props) =>
    props.complete &&
    `
    border-color: ${props.theme.color.border.interactive};
    color: ${props.theme.color.text.default};
    box-shadow: none;
  `}

  ${(props) =>
    props.invalid &&
    css`
      background: ${props.theme.color.white};
      border-color: ${props.theme.color.warning};
      box-shadow: none;
    `}

  /* FOCUS */
  &:focus {
    outline: none;
  }

  ${(props) => props.focus && getStylesForInvalid(props.theme.color.tfBlue, props.theme.color.tfBlue)}

  ${(props) =>
    props.focus && props.invalid && getStylesForInvalid(props.theme.color.warning, props.theme.color.warning)}

    ${(props) =>
    props.invalid &&
    css`
      color: ${props.theme.color.warning};
      &:focus {
        ${getStylesForInvalid(props.theme.color.warning, props.theme.color.warning)}
      }
    `}
  /* END FOCUS  */

  /* DISABLED */
  &:disabled {
    box-shadow: none;
    background: ${({ theme }) => theme.color.efGrey};
    border-color: ${({ theme }) => theme.color.border.disabled};
    color: ${({ theme }) => theme.color.text.disabled};
    pointer-events: none;
  }

  ${(props) =>
    props.readOnly &&
    css`
      box-shadow: none;
      background: ${props.theme.color.white};
      border-color: ${props.theme.color.border.grey};
      color: ${props.theme.color.text.disabled};
    `}

  /* END DISABLED */

  ${(props) =>
    (props.valid || props.invalid) &&
    css`
      padding-right: ${props.theme.spacingScale.withUnits(SpacingScale.l)};
    `}

  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #000;
    opacity: 1; /* Firefox */
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #000;
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #000;
  }
`;

const BODY_WIDTH = '1158px';

export const BodyWrapper = styled.div`
  background-color: ${({ theme }) => theme.color.white};
  width: ${BODY_WIDTH};
  min-height: 20vh;
  position: relative;
  padding: ${spacing('m')} ${spacing('l')};
  border-radius: ${({ theme }) => theme.form.input.borderRadius};
`;

export const ScrollableWrapper = styled.div<{ backgroundColor?: string }>`
  width: 100%;
  height: 100%;
  position: relative;
  ${(props) => props.backgroundColor && `background-color: ${props.backgroundColor}`}
`;

export const Scrollable = styled.div<{ mobile?: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: auto;
  ${(props) =>
    props.mobile &&
    `
  &::-webkit-scrollbar {
    background-color: #fff;
  }
  `}
`;

export const ScreenWrapper: React.FC<{ backgroundColor?: string; AppTitle?: string }> = ({
  children,
  backgroundColor,
  AppTitle,
}) => (
  <ScrollableWrapper backgroundColor={backgroundColor}>
    <Scrollable id="scrollable"> {children} </Scrollable>
    {AppTitle && (
      <Helmet>
        <title>{AppTitle}</title>
      </Helmet>
    )}
  </ScrollableWrapper>
);

export const HeaderBar = styled.div`
  display: flex;
  justify-content: space-between;
  height: 87px;
`;

export const Heading = styled.h1`
  font-size: ${({ theme }) => theme.fontSize['28']};
  align-self: center;
`;

export const CapitalizeFirst = styled.div`
  &:first-letter {
    text-transform: uppercase;
  }
`;

export const StyledAutocomplete = styled(Autocomplete)`
  height: 32px;
  max-height: 32px;
  & + .MuiAutocomplete-popper.MuiAutocomplete-popperDisablePortal {
    min-width: auto;
    .MuiPaper-root {
      margin: 1px 0 !important;
    }
  }
  & .MuiAutocomplete-hasClearIcon {
    padding-right: 0;
  }

  .MuiFormControl-root,
  MuiInputBase-root {
    max-height: 32px;
  }
  &.hideEndAdornment .MuiButtonBase-root {
    display: none;
  }

  .time .MuiAutocomplete-input {
    padding-top: 9px;
    padding-bottom: 9px;
  }

  & [role='listbox'] {
    max-height: 150px !important;
    overflow: hidden;
  }

  .MuiInputBase-root,
  .MuiAutocomplete-input,
  .MuiAutocomplete-popper {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    font-size: 14px !important;
    color: ${({ theme }) => theme.color.inkBlack} !important;
    height: 32px;
    max-height: 32px;
  }

  .MuiAutocomplete-inputRoot .MuiAutocomplete-input {
    height: 32px;
    max-height: 32px;
  }
` as typeof Autocomplete;

export const AutocompleteWrapper = styled.div`
  display: inline-block;
  margin-left: 21px;

  & ul {
    padding-left: 4px;
    padding-right: 4px;
  }

  & #studentName-popup {
    max-height: 175px;
  }
  & #lessonType-popup {
    max-height: 175px;
  }
  & #time-popup {
    max-height: 210px;
  }
`;

export const Option = styled.span`
  font-size: 14px;
`;

export const TextField = styled(MuiTextField)`
  padding-top: 0;
  padding-bottom: 0;
  font-size: 14px;
  box-shadow: ${({ theme }) => theme.gradients.boxShadow.input};

  .MuiFormLabel-root {
    top: -8px;
    left: 2px;
    padding-top: 0;
    padding-bottom: 0;
    font-size: 14px;
  }

  & label.Mui-focused {
    color: rgba(0, 0, 0, 0.87);
  }

  & .MuiOutlinedInput-root {
    border: 0;
    &.Mui-focused fieldset {
      border-color: #dbdbdb;
      box-shadow: ${({ theme }) => theme.gradients.boxShadow.input};
    }
  }
`;

export const TimeTextField = styled(TextField)`
  & .MuiOutlinedInput-root {
    width: 6.5rem;
    padding-right: 0 !important;
  }
`;

export const ChevronEndAdornment = ({ disabled }: { disabled?: boolean }) => {
  const theme = useTheme();

  return (
    <span style={{ position: 'absolute', right: 14, top: 4, transform: 'rotate(90deg)' }}>
      <Icon icon="chevron" color={disabled ? theme.color.borderGrey : 'currentcolor'} />
    </span>
  );
};

export const ClampLines = styled.div<{ clamp: number }>`
  display: -webkit-box;
  -webkit-line-clamp: ${({ clamp }) => clamp};
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const TableBase = {
  Table: styled.table`
    ${({ theme }) => css`
      position: relative;
      width: 100%;
      border-spacing: 0px 1em;
      border-collapse: separate;
      table-layout: fixed;
      .Mui-checked .MuiSvgIcon-root {
        color: ${theme.color.tfBlue};
        fill: ${theme.color.tfBlue};
      }
    `}
  `,
  Row: styled.tr`
    ${({ theme }) => css`
      &.active {
        td {
          border-color: ${theme.color.tfBlue};
        }
      }
    `}
  `,
  Head: styled.th<{ $width?: number; align?: string }>`
    text-align: ${(props) => (props.align ? props.align : 'left')};
    ${(props) => props.$width && `width: ${props.$width}px;`};
  `,
  Cell: styled.td<{ align?: string }>`
    text-align: ${(props) => (props.align ? props.align : 'left')};
    ${({ theme }) => css`
      position: relative;
      font-size: ${theme.fontSize[14]};
      border-top: solid 1px ${theme.color.efGrey};
      border-bottom: solid 1px ${theme.color.efGrey};
      vertical-align: middle;
      height: 54px;
      .Mui-disabled {
        filter: grayscale(0.9) brightness(1.5);
      }

      &:first-child {
        border-left: solid 1px ${theme.color.efGrey};
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }

      &:last-child {
        border-right: solid 1px ${theme.color.efGrey};
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    `};
  `,
};

export const Table = {
  ...TableBase,
  CellEllipsis: styled(TableBase.Cell)`
    max-width: 100px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    padding-right: ${({ theme }) => theme.spacingScale.withUnits(SpacingScale.s)};
  `,
  CellTime: styled(TableBase.Cell)`
    min-width: 145px;
  `,
  CellRight: styled(TableBase.Cell)`
    text-align: right;
  `,
};

export const InputIconContainer = styled.div`
  position: relative;
  left: -3px;
  top: 2px;
`;
