import Announcements from 'cypress/fixtures/announcements.json';
import acrs from 'cypress/fixtures/teacherfirst/dashboard/full/acr-draft.json';
import bookingsClassEntry from 'cypress/fixtures/teacherfirst/dashboard/full/bookings-class-entry.json';
import bookingsConfirmed from 'cypress/fixtures/teacherfirst/dashboard/full/bookings-confirmed.json';
import { HttpResponse, graphql, http } from 'msw';

import env from 'config/env';

export const handlers = [
  http.get(`${env.TF_SVCGATEWAY_URL}/v1/bookings`, ({ request }) => {
    const url = new URL(request.url);
    const teacherID = url.searchParams.get('teacherId');

    if (!teacherID) {
      return new HttpResponse(null, { status: 404 });
    }

    return HttpResponse.json(bookingsConfirmed);
  }),
  http.get(`${env.TF_SVCGATEWAY_URL}/v1/acrs`, ({ request }) => {
    const url = new URL(request.url);
    const teacherID = url.searchParams.get('teacherId');

    if (!teacherID) {
      return new HttpResponse(null, { status: 404 });
    }

    return HttpResponse.json(acrs);
  }),
  http.get(`${env.TF_SVCGATEWAY_URL}/v1/bookings/booking-id-3/class-entry-info`, () =>
    HttpResponse.json(bookingsClassEntry),
  ),

  graphql.query('Announcements', () => HttpResponse.json(Announcements)),
];
