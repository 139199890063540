import { styled } from '@mui/material/styles';

export const AvatarWrapper = styled('div')<{
  size: number;
  fontSize: number;
  fontColor?: string;
  avatar?: string;
  paddingTop?: number;
  hideBackground?: boolean;
}>`
  position: relative;
  background-image: ${(props) =>
    props.hideBackground ? 'none' : `url('/assets/avatar/${props.avatar || 'tf'}_avatar.svg')`};
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  padding-top: ${({ paddingTop = 6 }) => paddingTop}px;
  justify-content: center;
  align-items: center;
  font-size: ${(props) => props.fontSize}px;
  height: ${(props) => props.size}px;
  width: ${(props) => props.size}px;
  min-height: ${(props) => props.size}px;
  min-width: ${(props) => props.size}px;
  font-weight: bold;
  color: ${(props) => props.fontColor || props.theme.color.educationBlue};
`;

export const AvatarImg = styled('img')<{
  size: number;
}>`
  height: ${(props) => props.size}px;
  width: ${(props) => props.size}px;
  border-radius: 50%;
  object-fit: cover;
  object-position: center;
`;
