import { v4 as uuidv4 } from 'uuid';

import { STUDENTS_KEY } from 'config/localStorageKeys';

const firstNames = ['Optimus', 'Snake', 'Spider', 'Donald', 'Ragnar', 'Geralt', 'Frodo'];
const lastNames = ['Prime', 'Eyes', 'Man', 'Duck', 'Lothbrock', 'from Rivia', 'Baggins'];

const randElem = <T>(arr: T[]): T => arr[Math.floor(Math.random() * arr.length)];

const getRandomName = (): string => `${randElem(firstNames)} ${randElem(lastNames)}`;

export interface IBookingStudentData {
  reactKey: string;
  id: string;
  name: string;
}

const generateBookingStudentData = (id = uuidv4()): IBookingStudentData => ({
  reactKey: uuidv4(),
  id,
  name: getRandomName(),
});

const generateTestBookingStudentsData = (n: number) =>
  new Array<number>(n).fill(0).map(() => generateBookingStudentData());

export const saveTestBookingStudentData = (bsd: IBookingStudentData[]) => {
  localStorage[STUDENTS_KEY] = JSON.stringify(bsd);
};

const resetStudentsInLS = (n: number) => {
  const res = generateTestBookingStudentsData(n);
  saveTestBookingStudentData(res);
  return res;
};

const getStudentsDataFromLs = () => {
  const fromLS = localStorage[STUDENTS_KEY];
  if (!fromLS) {
    return resetStudentsInLS(0);
  }
  try {
    return JSON.parse(fromLS) as IBookingStudentData[];
  } catch {
    return resetStudentsInLS(0);
  }
};

export const getNStudent = (n: number) =>
  [...getStudentsDataFromLs(), ...generateTestBookingStudentsData(n)].slice(0, n);
