import { getAccessToken } from 'context/Auth/AuthProvider';
import {
  API_PREFIX,
  DEFAULT_HTTP_HEADERS,
  DEFAULT_REQUEST_PARAMS,
  IMakeRequestArgs,
  IMakeRequestProcessedArgs,
} from 'network/helpers/config';
import { compose, ddlog } from 'utils/miscellaneous';
import { URL } from 'utils/window';

const AUTH_TYPE = 'Bearer';

// NOTE: default: 'include', omit needs to be set explicitly
// 'omit' - don't include authentication credentials (e.g. cookies) in the request
// 'include' - include credentials in requests to all sites
// NOT SUPPORTED 'same-origin' - include credentials in requests to the same site
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const addAuthorizationHeader = async (makeRequestArgs: any) => {
  if (makeRequestArgs.credentials !== 'include') {
    return makeRequestArgs;
  }
  const accessToken = await getAccessToken();
  if (!accessToken) {
    ddlog.error('getAccessToken returned empty token');
    throw new Error('failed to grab token, refresh app to acquire fresh token');
  }
  return {
    ...makeRequestArgs,
    headers: {
      ...makeRequestArgs.headers,
      authorization: `${AUTH_TYPE} ${accessToken}`,
    },
  };
};

// NOTE: if you set body as null it will be stringified to 'null'
// If this causes more trouble than value please change the if-condition to:
// if (typeof makeRequestArgs.body === 'object' && makeRequestArgs.body) {
export const processBodyParam = (makeRequestArgs: IMakeRequestArgs) => {
  if (typeof makeRequestArgs.body === 'object') {
    return {
      ...makeRequestArgs,
      body: makeRequestArgs.body instanceof FormData ? makeRequestArgs.body : JSON.stringify(makeRequestArgs.body),
    };
  }

  return makeRequestArgs;
};

export const applyDefaults = (makeRequestArgs: IMakeRequestArgs) => ({
  ...DEFAULT_REQUEST_PARAMS,
  ...makeRequestArgs,
  pathname: makeRequestArgs.pathname,
  headers: Object.fromEntries(
    Object.entries({
      ...DEFAULT_HTTP_HEADERS,
      ...makeRequestArgs.headers,
    }).filter(([, val]) => typeof val === 'string'),
  ),
});

export const processSearchParams = (makeRequestArgs: IMakeRequestArgs) =>
  !makeRequestArgs.searchParams
    ? makeRequestArgs
    : {
        ...makeRequestArgs,
        searchParams: new URLSearchParams(
          Object.entries(makeRequestArgs.searchParams).map(([a, b]) => [a, b?.toString()]),
        ).toString(),
      };

export const processParams = compose(
  applyDefaults, // has to be the first
  processSearchParams,
  processBodyParam,
);

const partitionParams = ({
  body,
  headers,
  method,
  mode,
  cache,
  credentials,
  redirect,
  referrerPolicy,
  ...customParams
}: IMakeRequestProcessedArgs) => ({
  initObject: { body, headers, method, mode, cache, credentials, redirect, referrerPolicy },
  customParams,
});

const getUrl = ({ origin, pathname, searchParams, apiPrefix = API_PREFIX }: IMakeRequestProcessedArgs) => {
  const prefixedPath = apiPrefix + (pathname.startsWith('/') ? pathname : '/' + pathname);
  const url = new URL(origin + prefixedPath);
  url.search = searchParams || '';
  return url.toString();
};

export const partitionAndConvertParams = (args: IMakeRequestArgs) => {
  const processedArgs: IMakeRequestProcessedArgs = processParams(args);
  return {
    url: getUrl(processedArgs),
    ...partitionParams(processedArgs),
  };
};
