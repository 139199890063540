export const tips = [
  'Remember you can mute students by clicking on their video pod.',
  'Make sure to do a tech check at the start of each session.',
  'If a student seems particularly strong, try asking them a more complex or open-ended question.',
  'If a student seems shy, consider asking stronger students to go first to set an example, and then returning to them after.',
  'Try pairing students in some activities to give them as much speaking time as possible. This frees you up to monitor language, take notes and give feedback at the end of the activity.',
  'Consider combining the self-introductions with a warmer activity. This gets students using the target language while giving them the chance to get to know each other.',
  'Take notes about your students for use later in the lesson, such as where they’re from, what they do, or anything else relevant. Return back to these later to personalize the lesson.',
  'Timers are a versatile tool. You can use them to set expectations for how much students should say, make sure each student gets time to speak, or just add some fun!',
  'For listen-only students, thank them for coming to class and let them know that they’re welcome to use the Chat Pod for any questions and join in anytime if they’d like to participate.',
  "If the Group Lesson has a low number of participating students, try playing the part of a student in some tasks. Provide answers to questions, correct your 'mistakes,' participate in the Practice Task, and assign yourself a role in the Final Task.",
  'Use the time in the lobby to preview the day’s lessons, prioritizing objectives, target language, and grammar points.',
  "Remember that you’re expected to teach ‘up to' the 45-minute mark, not for 45 minutes. If your classroom opens at 10:05, it’s fine to finish at 10:45 (no worries if you overrun a bit, though!).",
  'Check the material notes for extension questions and concept checking questions. These can help you push stronger students, or if you’re progressing through the material too quickly.',
  'In an active classroom, consider asking a student to read the instructions on the slide aloud to decrease your talk time.',
  'Before providing corrective feedback, encourage students to try self-correcting. Often, with time to think, students will come to the correct form on their own.',
  '“Remember that a person’s name is to that person the sweetest and most important sound in any language.” - Dale Carnegie',
  'For reading activities, use the annotation tools (underline and unfilled box) to mark pronunciation errors that can be reviewed after the student is finished reading.',
  'If a student is experiencing audio issues, try using the chat pod to communicate with them while you move forward with the lesson.',
  'Consider using different coloured text when giving written feedback.',
  'Think carefully about which errors to correct. Was the mistake a simple slip, or an error which affected their meaning? Was the error to do with the lesson’s target language? Not every error needs correcting; use your best judgement.',
  'Confident students who are making errors with target language can benefit from interruptions where you give timely feedback.',
  'Be wary of interrupting shy students who may lose confidence easily. Delaying feedback to after the activity might be better for these students.',
  'Giving feedback on a student’s language is great, but make sure to respond to the content of what they are saying, too.',
  'Concept Check Questions are great for checking understanding. Start with yes/no questions then move to more open ones (e.g. Is this a hospital? How do you know?).',
  "Don't be afraid to cut-off an overly talkative student. Explain that you'd like to hear from another student, and move on.",
  'When asking questions in class, try mixing up the order of students you ask.',
];
