import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import Error from 'components/Error';
import LoadingMessage from 'components/Loading';
import { useEVCJoinClassroom } from 'network/Classroom';
import { EnterClassButton } from 'shared/views/Calendar/components/PopupRenderers/popup-renderers.styled';
import Events from 'utils/analytics/events';
import { logEvent } from 'utils/analytics/helpers';
import { ddlog } from 'utils/miscellaneous';

interface IClassroomRouteParams {
  bookingId: string;
  participantId: string;
}

interface RedirectButtonProps {
  text: string;
  classroomUrl: string;
  bookingId: string;
}

const RedirectButton = (props: RedirectButtonProps) => (
  <div
    style={{
      position: 'absolute',
      display: 'flex',
      alignItems: 'center',
      height: '100%',
      width: '100%',
      justifyContent: 'center',
    }}
    onClick={() => logEvent(Events.MANUAL_REDIRECT_ENTER_CLASS, { 'Booking ID': props.bookingId })}
  >
    {props.text}
    <a href={props.classroomUrl}>
      <EnterClassButton disabled={false}>Enter class</EnterClassButton>
    </a>
  </div>
);

const Classroom = (props: RouteComponentProps<IClassroomRouteParams>) => {
  const bookingId = props.match.params.bookingId;
  const participantId = props.match.params.participantId;
  const [isError, setIsError] = useState<boolean>(false);
  const [isRedirectError, setIsRedirectError] = useState<boolean>(false);
  const { data: classroomUrl, error } = useEVCJoinClassroom(participantId, bookingId);

  useEffect(() => {
    if (error) {
      setIsError(true);
      ddlog.error(`failed to enter classroom: ${error}, message: ${error?.message}, stack: ${error?.stack}`, {
        error,
      });
    }
  }, [error]);

  useEffect(() => {
    if (classroomUrl) {
      try {
        ddlog.info('redirecting to the classroom');
        window.location.replace(classroomUrl);
      } catch (err) {
        setIsRedirectError(true);
        ddlog.error(
          `failed to redirect to classroom: ${err}, message: ${(err as Error)?.message}, stack: ${
            (err as Error)?.stack
          }, classroomUrl: ${classroomUrl}`,
          {
            error: err,
          },
        );
      }
    }
  }, [classroomUrl]);

  if (isRedirectError && classroomUrl) {
    return (
      <RedirectButton
        text="We were unable to send you to your classroom. Please click here to enter."
        classroomUrl={classroomUrl}
        bookingId={bookingId}
      />
    );
  }
  if (isError) {
    return (
      <div
        style={{ height: '100%', position: 'absolute', left: '0px', width: '100%', overflow: 'hidden' }}
        onClick={() => logEvent(Events.MANUAL_RETRY_ENTER_CLASS, { 'Booking ID': bookingId })}
      >
        <Error message="Failed to enter classroom." hideHomeButton />
      </div>
    );
  }
  if (classroomUrl) {
    return (
      <RedirectButton
        text="Sending you to your classroom. Click here if this is taking too long."
        classroomUrl={classroomUrl}
        bookingId={bookingId}
      />
    );
  }

  return <LoadingMessage msg="Connecting to classroom..." />;
};

export default Classroom;
