export const layoutUnit = 32;

export const gridColumns = 12;

// Used to define section spacings per breakpoint

export type Size = 's' | 'm' | 'l' | 'xl';

export interface ISized<T> {
  s: T;
  m: T;
  l: T;
  xl: T;
}

/* eslint-disable   */
const layoutScale: ISized<ISized<number>> = {
  s: {
    s: layoutUnit,
    m: layoutUnit * 1.5,
    l: layoutUnit * 2,
    xl: layoutUnit * 3,
  },
  m: {
    s: layoutUnit * 1.5,
    m: layoutUnit * 2,
    l: layoutUnit * 3,
    xl: layoutUnit * 4,
  },
  l: {
    s: layoutUnit * 2,
    m: layoutUnit * 3,
    l: layoutUnit * 4,
    xl: layoutUnit * 5,
  },
  xl: {
    s: layoutUnit * 3,
    m: layoutUnit * 4,
    l: layoutUnit * 5,
    xl: layoutUnit * 6,
  },
};

const columnGutters: ISized<number> = {
  s: 16,
  m: 16,
  l: 24,
  xl: 24,
};

export interface ContainerSetting {
  marginX: string;
  maxWidth: string;
}

const containerSettings: ISized<ContainerSetting> = {
  s: {
    marginX: '24px',
    maxWidth: 'none',
  },
  m: {
    marginX: '32px',
    maxWidth: 'none',
  },
  l: {
    marginX: 'auto',
    maxWidth: '960px',
  },
  xl: {
    marginX: 'auto',
    maxWidth: '1200px',
  },
};
/* eslint-enable   */

export { layoutScale, columnGutters, containerSettings };
