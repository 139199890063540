import moment from 'moment-timezone';

export enum AdminTimezones {
  UK = 'Europe/London',
  USA = 'America/New_York',
  SHANGHAI = 'Asia/Shanghai',
  JOHANNESBURG = 'Africa/Johannesburg',
}

export const AdminTimezoneLabels: Record<AdminTimezones, string> = {
  [AdminTimezones.USA]: 'America/New_York',
  [AdminTimezones.UK]: 'Europe/London',
  [AdminTimezones.SHANGHAI]: 'Asia/Shanghai',
  [AdminTimezones.JOHANNESBURG]: 'Africa/Johannesburg',
};

export const adminTimezones = Object.keys(AdminTimezoneLabels) as AdminTimezones[];

export const timezones = [
  ...moment.tz.names().filter((name) => !(name.indexOf('/') < 0 && name !== 'UTC') && !name.startsWith('Etc/')),
] as const;

type TimezoneOptionMap = {
  [K in (typeof timezones)[number]]: string;
};

export type Timezone = (typeof timezones)[number];

interface ITimezoneData {
  tzName: Timezone;
  tzPresentationName: string;
}

const timezoneSorted: ITimezoneData[] = timezones
  .map((name) => ({ tzName: name, tzPresentationName: moment.tz(name).format('Z') }))
  .sort((a, b) => {
    if (a.tzPresentationName === b.tzPresentationName) {
      if (a.tzName === 'UTC') {
        return -1;
      }
      return a.tzName === b.tzName ? 0 : a.tzName > b.tzName ? 1 : -1;
    }
    const afc = a.tzPresentationName.charAt(0);
    const bfc = b.tzPresentationName.charAt(0);
    if (afc === '-') {
      if (bfc === '+') {
        return -1;
      }
      return a.tzPresentationName > b.tzPresentationName ? -1 : 1;
    }

    if (bfc === '-') {
      return 1;
    }

    return a.tzPresentationName > b.tzPresentationName ? 1 : -1;
  })
  .map((tz) => ({
    tzName: tz.tzName,
    tzPresentationName: `${tz.tzName.replace('_', ' ')} (GMT ${tz.tzPresentationName})`,
  }));

export const timezoneOptionsSorted = timezoneSorted.map((tz: ITimezoneData) => tz.tzName);

export const timezoneOptionMap: TimezoneOptionMap = timezoneSorted.reduce(
  (acc, tz: ITimezoneData) => ({ ...acc, [tz.tzName]: tz.tzPresentationName }),
  {},
);

export const getInitialTimezone: () => Timezone = () => moment.tz.guess() as Timezone;
export const getNYTimezone: () => Timezone = () => 'America/New_York' as Timezone;
