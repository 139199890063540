import { LogsEvent } from '@datadog/browser-logs';
import AmplitudeSingleton from 'amplitude-js';
import moment from 'moment-timezone';

import { NOOP } from 'utils/miscellaneous';

export const USE_LOGGING = process.env.NODE_ENV === 'development';

export const amplitudeContainer: {
  amplitude?: AmplitudeSingleton.AmplitudeClient;
} = {};

export const logEvent = (event: string, data?: unknown, callback: () => void = NOOP) => {
  if (USE_LOGGING) {
    console.log(`${event}: ${JSON.stringify(data)}`); // eslint-disable-line no-console
  } else {
    amplitudeContainer.amplitude?.logEvent(event, data, callback);
  }
  callback();
};

export const weeksProcessor = (weeks: string[]) => {
  const currentWeekStart = moment().startOf('isoWeek');
  return weeks.map((weekString) => moment(weekString, 'YYYY-MM-DDTHH:mm:ss.SSSSZ').diff(currentWeekStart, 'weeks'));
};

export const isBefore24H = (isoDateString: string) =>
  moment(isoDateString, 'YYYY-MM-DDTHH:mm:ss.SSSSZ').diff(moment(), 'h') < 24;

export const amplitudePostFailed = (log: LogsEvent) =>
  log.http?.url === 'https://api.amplitude.com/' && log.http.method === 'POST' && log.http.status_code === 0;

export const discardNoiseFromLogs = (log: LogsEvent) => {
  if (amplitudePostFailed(log)) {
    return false;
  }

  return true;
};
