import { DefaultTheme } from 'styled-components';

const rootUnit: number = 8;

const _zero = 0;
const _xxs = rootUnit / 2;
const _xs = rootUnit;
const _s = rootUnit * 2;
const _m = rootUnit * 3;
const _l = rootUnit * 4;
const _xl = rootUnit * 5;
const _xxl = rootUnit * 8;

export enum SpacingScale {
  zero,
  xxs,
  xs,
  s,
  m,
  l,
  xl,
  xxl,
}

// this takes a SpacingScale enum and returns the equivalent string with units
// for splicing into a style
export const withUnits = (ss: SpacingScale): string => `${withoutUnits(ss)}px`;

export const withoutUnits = (ss: SpacingScale): number => {
  switch (ss) {
    case SpacingScale.zero:
      return _zero;
    case SpacingScale.xxs:
      return _xxs;
    case SpacingScale.xs:
      return _xs;
    case SpacingScale.s:
      return _s;
    case SpacingScale.m:
      return _m;
    case SpacingScale.l:
      return _l;
    case SpacingScale.xl:
      return _xl;
    case SpacingScale.xxl:
      return _xxl;
  }
};

export const spacing =
  (scale: 'zero' | 'xxs' | 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl') =>
  ({ theme }: { theme: DefaultTheme }) =>
    theme.spacingScale.withUnits(SpacingScale[scale]);
