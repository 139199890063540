import moment from 'moment-timezone';

import { AxisBooking, Booking, IBookingWithAcrInfo } from 'model/Booking';
import { ILessonDef } from 'model/LessonDefinitions';
import {
  ClassroomType,
  IContentDef,
  IEVCContentDef,
  EVCContentLessonType,
  EVCContentLessonTypeLessonMapping,
  EVCLayoutCode,
  LessonBookingType,
} from 'model/misc';
export const getClassroomType = (lessonDef: ILessonDef | undefined) => lessonDef?.classroom?.classroomType;

export const getClassroomLayout = (lessonDef: ILessonDef | undefined) => lessonDef?.classroom?.evc?.layout;

export const getContentProviderLessonType = (lessonDef: ILessonDef | undefined) => lessonDef?.content?.evc?.lessonType;

export const getBookingContents = (booking: Booking) => booking.content?.evc?.items;

export const getNumberOfBookingContents = (booking: Booking) => getBookingContents(booking)?.length;

export const getLessonDefinitionById = (
  booking: Booking | undefined,
  lessonDefs: Map<string, ILessonDef> | undefined,
) => !!booking && lessonDefs?.get(booking.lessonDefinitionId);

export const isOfflineLesson = (booking: Booking | undefined, lessonDefs: Map<string, ILessonDef> | undefined) =>
  !!booking && getClassroomType(lessonDefs?.get(booking.lessonDefinitionId)) === ClassroomType.NONE;

export const isOfflineLessonDef = (booking: Booking | undefined, lessonDef: ILessonDef | undefined) =>
  !!booking && getClassroomType(lessonDef) === ClassroomType.NONE;

export const isGroupLesson = (booking: Booking | undefined) => booking?.bookingType === LessonBookingType.Group;
export const isPrivateLesson = (booking: Booking | undefined) => booking?.bookingType === LessonBookingType.Private;
export const isScheduledLesson = (booking: Booking | undefined) => booking?.bookingType === LessonBookingType.Scheduled;

export const isEvcLesson = (booking: Booking | undefined, lessonDefs: Map<string, ILessonDef> | undefined) =>
  !!booking && getClassroomType(lessonDefs?.get(booking.lessonDefinitionId)) === ClassroomType.EVC;

export const isEvcLayoutLesson = (booking: Booking | undefined, lessonDefs: Map<string, ILessonDef> | undefined) =>
  !!booking &&
  (getClassroomLayout(lessonDefs?.get(booking.lessonDefinitionId)) === EVCLayoutCode.SPACES ||
    getClassroomLayout(lessonDefs?.get(booking.lessonDefinitionId)) === EVCLayoutCode.HC_SPACES);

export const getEvcContentId = (booking: Booking | undefined) => !!booking && booking.content?.id;

export const getUrlForEvc = (uriPrefix: string, contentId: string) =>
  `https://evc.ef${uriPrefix}/evc15/meeting/home/hc-preview#/topic/${contentId}`;

export const getEvcUrl = (uri: string) => (uri.includes('.com.cn') ? '.com' : '.com.cn');

export const isOnlineGroupLesson = (booking: Booking | undefined, lessonDefs: Map<string, ILessonDef> | undefined) =>
  !!booking &&
  getClassroomType(lessonDefs?.get(booking.lessonDefinitionId)) === ClassroomType.EVC &&
  getContentProviderLessonType(lessonDefs?.get(booking.lessonDefinitionId)) === EVCContentLessonType.Group;

export const isClassTypeOfOfflineGroupLesson = (lessonDef: ILessonDef | undefined) =>
  getClassroomType(lessonDef) === ClassroomType.NONE &&
  getContentProviderLessonType(lessonDef) === EVCContentLessonType.Group;

export const getContentDefinition = (arg?: IContentDef): IEVCContentDef | undefined => arg?.[arg?.provider];

export const getLessonType = (lessonDef: ILessonDef | undefined) =>
  getContentDefinition(lessonDef?.content)?.lessonType;

export const getLessonTypeLabel = (lessonDef: ILessonDef | undefined) => {
  const lt = getLessonType(lessonDef);
  return lt && EVCContentLessonTypeLessonMapping[lt];
};

export const isBookingInProgress = (booking: Booking) =>
  moment().isBetween(booking.start, booking.end, 'millisecond', '[)');

export const getStudentState = (booking: IBookingWithAcrInfo | AxisBooking) =>
  (booking as AxisBooking)?.studentState || (booking as IBookingWithAcrInfo)?.students?.[0]?.state;

export const getRejectionReason = (booking: Booking): string | undefined => booking?.students?.[0]?.rejectionReason;
