import { styled, Grid, Modal } from '@mui/material';

export const Wrapper = styled(Grid)`
  padding: ${({ theme }) => theme.spacing(4)};
`;

export const EvcModal = styled(Modal)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const EvcModalContentWrapper = styled('div')`
  padding: 32px 24px 12px 24px;
  background-color: #f2f2f2;
`;

// evc iframe link needs hard coded values to prevent extra padding on resizing.
export const EvcModalContent = styled('iframe')`
  min-height: 457px;
  min-width: 1300px;
`;

export const HeaderText = styled('div')`
  color: ${({ theme }) => theme.color.inkBlack};
  font-size: ${({ theme }) => theme.typography.pxToRem(16)};
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
`;
