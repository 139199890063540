import { plainToClass, Transform, Type } from 'class-transformer';
import { Moment } from 'moment-timezone';

import { DASH } from 'config/constants';
import { BookingStudent, IStudentExternalID } from 'model/Students';
import { isGroupLesson } from 'utils/bookings';
import { getEvcBookingContentStage } from 'utils/calendar';

import { EvcBookingContent } from './BookingContent';
import { ILessonBookingType } from './misc';
import { MomentTransformer } from './transformers/Moment';

export const axisLessonId = 'AXIS lesson';

export interface IStudentProfile {
  id: string;
  firstName: string;
  lastName: string;
  level?: string;
  acrType?: string;
  countryCode: string;
  email: string;
  emailSubscribed: boolean;
  version: number;
}

export enum BookingStatus {
  confirmed = 'confirmed',
  started = 'started',
  pending = 'pending',
  cancelled = 'cancelled',
  completed = 'completed',
  rejected = 'rejected',
}

export enum LessonStatus {
  tns = 'tns',
  sns = 'sns',
  is = 'is',
  ms = 'ms',
  ps = 'ps',
  taught = 'taught',
  tl = 'tl',
  lg = 'lg',
  sc = 'sc',
  slc = 'slc',
  slc2 = 'slc2',
  tc = 'tc',
  tlc = 'tlc',
  tlc2 = 'tlc2',
  sns_is = 'sns_is',
  sns_tl = 'sns_tl',
  is_lg = 'is_lg',
  ms_lg = 'ms_lg',
  ps_lg = 'ps_lg',
  tl_lg = 'tl_lg',
  sitedown = 'sitedown',
  ncc = 'ncc',
  ncc_tl = 'ncc_tl',
  lsc = 'lsc',
  lsc2 = 'lsc2',
  subout = 'sub-out',
}

export enum HoursBooked {
  hours = 'hours',
  booked = 'booked',
}

const hoursBookedLabelMap: Record<string, string> = {
  [HoursBooked.hours]: 'Hours',
  [HoursBooked.booked]: 'Booked',
};

const hoursBookedDescriptionMap: Record<string, string> = {
  [HoursBooked.hours]: 'Total paid hours',
  [HoursBooked.booked]: 'Total booked lessons',
};

export const lessonStatusLabelMap: Record<LessonStatus, string> = {
  [LessonStatus.tns]: 'TNS',
  [LessonStatus.sns]: 'SNS',
  [LessonStatus.is]: 'IS',
  [LessonStatus.ms]: 'MS',
  [LessonStatus.ps]: 'PS',
  [LessonStatus.taught]: 'Taught',
  [LessonStatus.tl]: 'TL',
  [LessonStatus.lg]: 'LG',
  [LessonStatus.sc]: 'SC',
  [LessonStatus.slc]: 'SLC',
  [LessonStatus.slc2]: 'SLC2',
  [LessonStatus.tc]: 'TC',
  [LessonStatus.tlc]: 'TLC',
  [LessonStatus.tlc2]: 'TLC2',
  [LessonStatus.sns_is]: 'SNS, IS',
  [LessonStatus.sns_tl]: 'SNS, TL',
  [LessonStatus.is_lg]: 'IS, LG',
  [LessonStatus.ms_lg]: 'MS, LG',
  [LessonStatus.ps_lg]: 'PS, LG',
  [LessonStatus.tl_lg]: 'TL, LG',
  [LessonStatus.sitedown]: 'Sitedown',
  [LessonStatus.ncc]: 'NCC',
  [LessonStatus.ncc_tl]: 'NCC, TL',
  [LessonStatus.lsc]: 'LSC',
  [LessonStatus.lsc2]: 'LSC2',
  [LessonStatus.subout]: 'Subout',
};

export const lessonStatusDescriptionMap: Record<LessonStatus, string> = {
  [LessonStatus.tns]: 'Teacher no show',
  [LessonStatus.taught]: 'Taught',
  [LessonStatus.sns]: 'Student no show',
  [LessonStatus.sc]: 'Student cancellation',
  [LessonStatus.slc]: 'Student late cancellation',
  [LessonStatus.slc2]: 'Student last minute cancellation',
  [LessonStatus.tc]: 'Teacher cancellation',
  [LessonStatus.tlc]: 'Teacher late cancellation',
  [LessonStatus.tlc2]: 'Teacher last minute cancellation',
  [LessonStatus.tl]: 'Teacher late',
  [LessonStatus.ms]: 'Minimal stay',
  [LessonStatus.ps]: 'Partial stay',
  [LessonStatus.is]: 'Insufficient stay',
  [LessonStatus.lg]: 'Late grade',
  [LessonStatus.sns_is]: 'Student no show, Insufficient stay',
  [LessonStatus.sns_tl]: 'Student no show, Teacher late',
  [LessonStatus.is_lg]: 'Insufficient stay, Late grade',
  [LessonStatus.ms_lg]: 'Minimal stay, Late grade',
  [LessonStatus.ps_lg]: 'Partial stay, Late grade',
  [LessonStatus.tl_lg]: 'Teacher late, Late grade',
  [LessonStatus.sitedown]: 'Sitedown',
  [LessonStatus.ncc]: 'No class created',
  [LessonStatus.ncc_tl]: 'No class created, Teacher late',
  [LessonStatus.lsc]: 'Late subout class',
  [LessonStatus.lsc2]: 'Last minute subout class',
  [LessonStatus.subout]: 'Sub out',
};

export interface ILessonStatusesOptions {
  value: string;
  name: string;
}

export const lessonStatuses = Object.keys(lessonStatusLabelMap) as LessonStatus[];
export const lessonStatusesOptions = lessonStatuses
  .map(
    (value) =>
      ({
        value,
        name: `${lessonStatusLabelMap[value]} - ${lessonStatusDescriptionMap[value]}`,
      } as ILessonStatusesOptions),
  )
  .filter((value) => value.value !== 'tsp' && value.value !== 'ttp' && value.value !== 'ttp2');

export const getBookingError = (booking: Booking) => ({
  hasBookingErrorState: booking.centerStatus && booking.centerStatus !== LessonStatus.taught,
  bookingErrorDesc: lessonStatusDescription(booking.centerStatus!),
});

export const lessonStatusLabel = (lessonStatus: LessonStatus): string => lessonStatusLabelMap[lessonStatus] || DASH;

export const lessonStatusDescription = (lessonStatus: LessonStatus): string =>
  lessonStatusDescriptionMap[lessonStatus] || DASH;

export const hoursBookedLabel = (hoursBooked: HoursBooked): string => hoursBookedLabelMap[hoursBooked] || DASH;

export const getTitle = (booking: Booking) => bookingStageAndTitle(booking).title;

export const hoursBookedDescription = (hoursBooked: HoursBooked): string =>
  hoursBookedDescriptionMap[hoursBooked] || DASH;

export class Lobby {
  @Type(() => Range)
  public student!: Range;
  @Type(() => Range)
  public teacher!: Range;
}

export class AxisBooking {
  @Transform(MomentTransformer)
  public start!: Moment;

  @Transform(MomentTransformer)
  public end!: Moment;

  public id!: string;
  public teacherId!: string;
  public axisClassId!: number;
  public students!: string[];
  public studentState?: string;

  // eslint-disable-next-line
  constructor(data: any) {
    Object.assign(this, plainToClass(AxisBooking, data));
  }
}

export class Booking {
  @Transform(MomentTransformer)
  public start!: Moment;

  @Transform(MomentTransformer)
  public end!: Moment;

  @Type(() => Content)
  public content?: Content;

  @Type(() => ClassroomUrl)
  public classroomUrls?: ClassroomUrl[] | null;

  @Type(() => Lobby)
  public lobby?: Lobby;

  public id!: string;
  public isHalf?: boolean;
  public teacherId?: string;
  public bookingType: ILessonBookingType | undefined;
  public students!: BookingStudent[];
  public lessonStatus!: LessonStatus;
  public centerStatus?: LessonStatus;
  public paidSeconds?: number;

  public lessonDefinitionId!: string;
  public lessonStatusReviewed!: boolean;

  public lessonGroupId?: string;
  public lessonGroupName?: string;
  public classRecordId?: string;

  public status!: BookingStatus;

  // eslint-disable-next-line
  constructor(data: any) {
    Object.assign(this, plainToClass(Booking, data));
  }
}

export class Range {
  @Transform(MomentTransformer)
  public start!: Moment;

  @Transform(MomentTransformer)
  public end!: Moment;

  // eslint-disable-next-line
  constructor(data: any) {
    Object.assign(this, plainToClass(Range, data));
  }
}

export class ClassSummary {
  @Type(() => SummaryTotal)
  public total!: SummaryTotal;

  @Type(() => ClassSummaryDetails)
  public details!: ClassSummaryDetails[];

  // eslint-disable-next-line
  constructor(data: any) {
    Object.assign(this, plainToClass(ClassSummary, data));
  }
}

export class SummaryTotal {
  public count!: number;
  public paidSeconds!: number;

  // eslint-disable-next-line
  constructor(data: any) {
    Object.assign(this, plainToClass(SummaryTotal, data));
  }
}

export class ClassSummaryDetails extends SummaryTotal {
  public lessonStatus!: LessonStatus;

  // eslint-disable-next-line
  constructor(data: any) {
    super(data);
    Object.assign(this, plainToClass(ClassSummaryDetails, data));
  }
}

export class ClassroomUrl {
  @Type(() => ClassroomUrlActive)
  public active!: ClassroomUrlActive;

  public id!: string;
  public userId!: string;

  // eslint-disable-next-line
  constructor(data: any) {
    Object.assign(this, plainToClass(ClassroomUrl, data));
  }
}

export class Content {
  public id!: string;
  public custom!: string;
  public evc?: {
    items: EvcBookingContent[];
  };

  // eslint-disable-next-line
  constructor(data: any) {
    Object.assign(this, plainToClass(ClassroomUrl, data));
  }
}

export class ClassroomUrlActive {
  @Transform(MomentTransformer)
  public start!: Moment;
  @Transform(MomentTransformer)
  public end!: Moment;

  // eslint-disable-next-line
  constructor(data: any) {
    Object.assign(this, plainToClass(ClassroomUrlActive, data));
  }
}

export const isBookingWithCustomContent = (booking: Booking) => !!(booking.content?.custom && !booking.content.id);

export const isBookingWithOnDemandEvcContent = (booking: Booking) =>
  !!(booking.content && isBookingWithEvcContent(booking) && booking.content.evc!.items.length > 1);

export const isBookingWithEvcContent = (booking: Booking) => !!(booking.content && booking.content.evc);

export const bookingStageAndTitle = (booking: Booking) => {
  let stage = '';
  let title = '';
  // custom content
  if (isBookingWithCustomContent(booking)) {
    stage = booking.content?.custom || '';
    title = stage;
  }
  // evc content
  if (isBookingWithEvcContent(booking)) {
    const evcContent = booking.content?.evc?.items?.[0];
    stage = getEvcBookingContentStage(evcContent, '');
    title = evcContent?.title || '';
  }
  // on demand evc content
  if (isBookingWithOnDemandEvcContent(booking!)) {
    stage = 'Level on demand';
    title = 'Topic on demand';
  }

  if (isGroupLesson(booking) && !title?.length) {
    title = 'Online group lesson';
  }

  return {
    stage,
    title,
  };
};

export const getBookingTitle = (booking: Booking): string => {
  if (isBookingWithOnDemandEvcContent(booking)) {
    return DASH;
  } else if (isBookingWithEvcContent(booking)) {
    return booking.content?.evc?.items?.[0]?.title || '';
  }

  return booking?.content?.custom || '';
};

export interface IUnclaimedLessonGroup {
  id: string;
  start: Moment;
  end: Moment;
  details: IUnclaimedLessonGroupDetail[];
  count?: number;
  hasConflict: boolean;
  toTheRight: boolean;
}

export interface IUnclaimedLessonGroupDetail {
  id: string;
  lessonDefinitionId: string;
  count: number;
  contentId?: string;
  contentTopic?: string;
  customTopic?: string;
}

export interface IUnclaimedLessonGroupAPI {
  range: {
    start: string;
    end: string;
  };
  details: [
    {
      lessonDefinitionId: string;
      contentData: {
        custom: string;
        id: string;
        evc: {
          items: [
            {
              id: string;
              level: string;
              unit: string;
              stage: string;
              title: string;
              description: string;
              htmlMaterialUrl: string;
              audio: [
                {
                  url: string;
                },
              ];
            },
          ];
        };
      };
      count: number;
    },
  ];
  count: number;
}

export const isAxisBooking = (bk: Booking | AxisBooking): bk is AxisBooking => !!(bk as AxisBooking).axisClassId;

export const hasOverlap = (bk1: Booking | AxisBooking, bk2: Booking | AxisBooking): boolean => {
  const leftLimit = Math.max(bk1.start.unix(), bk2.start.unix());
  const rightLimit = Math.min(bk1.end.unix(), bk2.end.unix());
  return leftLimit < rightLimit;
};

export const axisBookingWithStudent = (bk: Booking | AxisBooking): bk is AxisBooking =>
  isAxisBooking(bk) && bk.students.length > 0;

export const isUnclaimedBooking = (bk: Booking | IUnclaimedLessonGroup | AxisBooking): bk is IUnclaimedLessonGroup =>
  !!(bk as IUnclaimedLessonGroup).details;

export interface IAttendanceRecords {
  enterTime: string | null;
  exitTime: string | null;
  teacherId?: string;
  studentId?: IStudentExternalID;
  participantRole: 'student' | 'teacher' | 'suppport';
  userDevice: string;
}

export interface CombinedAttendanceRecord extends IAttendanceRecords {
  duration: number;
}

export interface IEvents {
  id: string;
  event: string;
  description: string;
  at: string;
  byUser: string;
  forTeacher: string;
}

export enum BookForStudentState {
  FORM = '1',
  CONFIRM = '2',
  LOADING = '3',
  PENDING = '4',
  ERROR = '5',
}

export interface IBookingWithAcrInfo extends Booking {
  acrSubmitted?: boolean;
}

export enum RejectedBookingError {
  NO_LESSON_TYPE_ACCESS = 'no_lesson_type_access',
  NO_TOPIC_ACCESS = 'no_topic_access',
  CONFLICTING_BOOKING = 'conflicting_booking',
  UNKNOWNN = 'unknown',
  MAX_ALLOWED_LESSONS_BOOKED = 'max_allowed_lessons_booked',
  NO_CREDITS = 'no_credits',
}

export const SuboutStatuses = [
  LessonStatus.tc,
  LessonStatus.tlc,
  LessonStatus.tlc2,
  LessonStatus.subout,
  LessonStatus.lsc,
  LessonStatus.lsc2,
];

export interface IBookingModal {
  booking: Booking;
  isStudentsFirstLesson?: boolean;
  anchor: HTMLElement;
}

export enum StudentState {
  pending = 'pending',
  confirmed = 'confirmed',
  cancelled = 'cancelled',
  rejected = 'rejected',
}

export enum BookedBy {
  student = 'by_student',
  teacher = 'by_teacher',
}

export class StudentBooking {
  @Transform(MomentTransformer)
  public start!: Moment;

  @Type(() => Content)
  public content?: Content;

  public id!: string;
  public teacherId!: string;
  public lessonDefinitionId!: string;
  public studentState!: StudentState;
  public bookedBy!: BookedBy;
  public studentId!: IStudentExternalID;
}

export const studentStateDescriptionMap: Record<StudentState, string> = {
  [StudentState.pending]: 'Pending',
  [StudentState.confirmed]: 'Confirmed',
  [StudentState.cancelled]: 'Cancelled',
  [StudentState.rejected]: 'Rejected',
};
