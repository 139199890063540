import useSWR from 'swr';

import env from 'config/env';
import { IEVCJoinDetails } from 'model/Classroom';
import { EVCLocation } from 'model/misc';
import { EvcProxyTunnel } from 'model/profiles';
import { apolloClient } from 'network/graphql';
import {
  EVC_CLASSROOM_IDS_FOR_USER,
  EVC_CLASSROOM_JOIN_DETAILS,
  EvcClassroomInfos,
  EvcClassroomJoinDetails,
} from 'network/graphql/EvcClassroom';
import { ddlog } from 'utils/miscellaneous';

import makeRequest from './helpers/makeRequest';

const getEVCJoinClassroomKey = (participantId: string, bookingId: string) =>
  `bookings/${bookingId}/classrooms/evc/participants/${participantId}/token`;

export const useEVCJoinClassroom = (participantId: string, bookingId: string) =>
  useSWR<string>(getEVCJoinClassroomKey(participantId, bookingId), () =>
    fetchEVCJoinClassroomDetails(participantId, bookingId),
  );

const getEvcTunnelForTeacher = (teacherId: string) =>
  makeRequest<{ evcChinaTunnel: 'DIRECT'; teacherId: string }>({
    pathname: `evc/teacher-settings/${teacherId}`,
  }).then((response) =>
    makeRequest<EvcProxyTunnel>({
      pathname: `evc/tunnels/${response.evcChinaTunnel}`,
    }),
  );

export const useEvcTunnelForTeacher = (teacherId: string) =>
  useSWR(`evc/teacher-settings/${teacherId}`, () => getEvcTunnelForTeacher(teacherId));

export const fetchEVCJoinClassroomDetails = async (participantId: string, bookingId: string): Promise<string> => {
  const evcDetails = await makeRequest<IEVCJoinDetails>({
    pathname: `bookings/${bookingId}/classrooms/evc/participants/${participantId}/token`,
    method: 'POST',
  });
  let domain = env.EVC_TUNNELS.US_NV;

  // If evc Location is North Virginia, default to the hardcoded domain from config
  // otherwise get the evc proxy tunnel configured for particular teacher and use that as domain
  if (evcDetails.location === EVCLocation.SH) {
    const evcTunnelForTeacher = await getEvcTunnelForTeacher(participantId).catch((error) => {
      ddlog.error('Failed to get evc tunnel details', error);
    });

    if (!!evcTunnelForTeacher?.domain) {
      domain = `https://${evcTunnelForTeacher.domain}`;
    } else {
      domain = env.EVC_TUNNELS.CN_SH;
    }
  }
  const joinPath = `${domain}/evc15/meeting/home?token=${evcDetails.attendanceToken}&accesskey=${evcDetails.accessKey}`;
  return joinPath;
};

export const createToken = (evcClassroomId: string, participantId: string) =>
  makeRequest<CreateTokenResponse>({
    method: 'POST',
    pathname: `/evc-classrooms/${evcClassroomId}/participants/${participantId}/token`,
  });

interface CreateTokenResponse {
  accessToken: string;
}

export const addUserParticipant = (evcClassroomId: string, role: string, userId: string) =>
  makeRequest<AddParticipantResponse>({
    method: 'POST',
    pathname: `/evc-classrooms/${evcClassroomId}/participants`,
    body: {
      role,
      participant: {
        type: 'user',
        userId,
      },
    },
  });

interface AddParticipantResponse {
  id: string;
}

export const fetchEvcClassroomJoinUrl = async (evcClassroomId: string, participantId: string) => {
  const tokenResp = await createToken(evcClassroomId, participantId);
  const apolloResp = await apolloClient.query<EvcClassroomJoinDetails>({
    query: EVC_CLASSROOM_JOIN_DETAILS,
    variables: {
      evcClassroomId,
      participantId,
    },
  });

  let domain = env.EVC_TUNNELS.US_NV;
  if (apolloResp.data.evcClassroom.location === EVCLocation.SH) {
    domain = env.EVC_TUNNELS.CN_SH;
  }
  return `${domain}/evc15/meeting/home?token=${apolloResp.data.evcClassroomParticipant.attendanceToken}&accesskey=${tokenResp.accessToken}`;
};

export const observerEvcEntryData = async (bookingId: string, userId: string) => {
  const apolloResp = await apolloClient.query<EvcClassroomInfos>({
    query: EVC_CLASSROOM_IDS_FOR_USER,
    variables: {
      classroomsFilter: {
        bookingId,
      },
      participantsFilter: {
        userId,
      },
    },
  });
  if (apolloResp.data.evcClassrooms.edges.length !== 1) {
    throw new Error('expected to find just 1 classroom');
  }

  const classroom = apolloResp.data.evcClassrooms.edges[0];
  const evcClassroomId = classroom.id;
  let participantId;
  if (classroom.participants.length < 1) {
    // this participant is not registered, and needs to be registered
    const resp = await addUserParticipant(evcClassroomId, 'support', userId);
    participantId = resp.id;
  } else {
    participantId = classroom.participants[0].id;
  }
  return {
    evcClassroomId,
    participantId,
  };
};
