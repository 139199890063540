import { CSSProperties } from 'react';
import styled from 'styled-components';

export const ToasterWrapper = styled.div<{ responsive?: boolean }>`
  ${(props) =>
    props.responsive &&
    `
.MuiSnackbarContent-root {
flex-direction: column;
align-items: center;
}
.MuiSnackbarContent-message {
text-align: center;
}
.MuiSnackbarContent-action {
margin: 0 auto;
}
`}
`;

export const subcenterStyle: CSSProperties = {
  fontSize: '14px',
  lineHeight: '22px',
  textAlign: 'right',
  margin: 'auto',
  minWidth: 50,
};

export const DismissButton = styled.button`
  margin-left: -5px;
  margin-right: 8px;
  width: 80px;
  height: 30px;
  border-radius: 3px;
  box-sizing: border-box;
  border: 0.5px solid #4caf50;
  background-color: transparent;
  border-color: white;
  cursor: pointer;
  color: white;
  text-align: center;
  text-shadow: none;
`;
