import React, { createContext, ReactNode, useContext } from 'react';
import { Redirect } from 'react-router-dom';

import LoadingMessage from 'components/Loading';
import env from 'config/env';
import { UNAUTHORIZED_USER_PATH } from 'config/internalUrlPaths';
import { IUser } from 'model/profiles';
import { useMe } from 'network/User';
import { ddlog } from 'utils/miscellaneous';

export interface IUserContext {
  user: IUser;
}

export const UserContext = createContext<IUserContext>({} as IUserContext);

interface Props {
  children: ReactNode;
}

export const ProvideUser = (props: Props) => {
  const { user, error } = useMe(env.USER_INTERVAL);

  if (error) {
    ddlog.error('failed to call user me', { error: { message: error.message, stack: error.stack } });

    if (error?.response?.message === 'Unauthorized') {
      return <Redirect to={UNAUTHORIZED_USER_PATH} />;
    }
  }

  if (!user) {
    return <LoadingMessage />;
  }

  return (
    <UserContext.Provider
      value={{
        user,
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};

export const useUserContext = () => useContext(UserContext);

export default useUserContext;
