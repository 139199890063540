import moment, { Moment } from 'moment-timezone';

import { Booking, IUnclaimedLessonGroup } from 'model/Booking';
import { EvcBookingContent } from 'model/BookingContent';
import {
  DateRange,
  getEmptyWeek,
  getEmptyWeekObj,
  IAvailabilitySelection,
  mapWeekDay,
  namesGetDay,
  WeekAvailability,
  Weekday,
  weekDays,
  IAvSelection,
  ICellCoordinate,
  ExclusiveAvailabilityResponse,
  AvailabilityBlock,
} from 'model/Calendar';
import { SelectionBorder } from 'shared/views/Calendar/components/Calendar/types';
import { ddlog, groupBy } from 'utils/miscellaneous';

export const splitContinuesSequences = (arr: number[]) => {
  const result = [];
  for (let from = 0, j = arr.length, to; from < j; ) {
    // this pushes pointer k to edge of continues sequence
    for (to = from; to < j && arr[to + 1] - arr[to] === 1; to++); // eslint-disable-line curly
    result.push({ from: arr[from], to: arr[to] });
    from = to + 1;
  }
  return result;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const convertCombinedAvailabilityToCalendarFormat = (payload: DateRange[]): any => {
  const groupedWeekly = groupBy(payload, (item: DateRange) => momentToStartOfWeekISOString(item[0]));

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return Object.keys(groupedWeekly).reduce((acc: any, weekId: string) => {
    const weekContents = groupedWeekly[weekId];
    const grouped = groupBy<DateRange>(weekContents, (item: DateRange) => namesGetDay[item[0].day()]);

    const numberRanges: Record<Weekday, number[]> = {
      sunday: [],
      monday: [],
      tuesday: [],
      wednesday: [],
      thursday: [],
      friday: [],
      saturday: [],
    };
    namesGetDay.forEach((k) => {
      numberRanges[k] = grouped[k]?.flatMap((daterange) => dateRangeToNumberRange(daterange)) || [];
    });
    acc[weekId] = numberRanges;
    return acc;
  }, {});
};

export const generateSequenceFromTo = (perhapsFrom: number, perhapsTo: number) => {
  const [from, to] = sortFromTo(perhapsFrom, perhapsTo);
  const result = [];
  for (let i = from; i <= to; i++) {
    result.push(i);
  }
  return result;
};

// Math.ceil and Math.floor here take care of date ranges starting/ending not on full or half hours
export const dateRangeToNumberRange = ([from, to]: DateRange) =>
  getExclusiveRange(
    Math.ceil((from.hours() * 60 + from.minutes()) / 30),
    Math.floor((to.hours() * 60 + to.minutes()) / 30) + (to.isSame(to.clone().endOf('day')) ? 1 : 0),
  );

const getExclusiveRange = (from: number, to: number) => {
  const result = [];
  if (from < to) {
    for (let i = from; i < to; i++) {
      result.push(i);
    }
  }
  return result;
};

const sortFromTo = (perhapsFrom: number, perhapsTo: number) => {
  const from = Math.min(perhapsFrom, perhapsTo);
  const to = from === perhapsFrom ? perhapsTo : perhapsFrom;
  return [from, to];
};

export const convertBookingsToCalendarMap = (bookings: Booking[]) =>
  bookings.reduce((acc, booking) => {
    const date = booking.start;
    const weekday = namesGetDay[date.day()];
    const slotId = Math.floor(date.hours() * 2 + date.minutes() / 30);

    acc[weekday][slotId] = booking;

    return acc;
  }, getEmptyWeekObj());

const cloneWeekAvailability = (av: WeekAvailability) =>
  av
    ? weekDays.reduce<WeekAvailability>((acc, weekday) => {
        acc[weekday] = av[weekday] || [];
        return acc;
      }, {} as WeekAvailability) // eslint-disable-line
    : getEmptyWeek(); // eslint-disable-line @typescript-eslint/consistent-type-assertions

export const mergeAvailabilities = (
  av: WeekAvailability,
  weekStart: Moment,
  weekEnd: Moment,
  selection?: IAvailabilitySelection,
) => {
  if (!selection) {
    return av;
  }
  const now = moment();
  if (now.isAfter(weekEnd)) {
    return av;
  }
  const isACurrentWeek = now.isBetween(weekStart, weekEnd, null, '[)');

  const makeUnavailable = av[selection.from.weekday].includes(selection.from.hourNum);
  const [editDayStart, editDayEnd] = sortFromTo(
    mapWeekDay.get(selection.from.weekday)! - 1,
    mapWeekDay.get(selection.to.weekday)! - 1,
  );
  const [editHourStart, editHourEnd] = sortFromTo(selection.from.hourNum, selection.to.hourNum);
  const avUpdateStart = isACurrentWeek ? now : weekStart;
  const currDay = avUpdateStart.day() - 1;
  const currHour = Math.floor((avUpdateStart.hour() * 60 + avUpdateStart.minute()) / 30);
  const result: WeekAvailability = cloneWeekAvailability(av);
  for (let editedDayNum = Math.max(editDayStart, currDay); editedDayNum <= editDayEnd; editedDayNum++) {
    const hStart = isACurrentWeek && editedDayNum === currDay ? Math.max(currHour + 1, editHourStart) : editHourStart;
    const hEnd = isACurrentWeek && editedDayNum === currDay ? Math.max(currHour, editHourEnd) : editHourEnd;
    if (hEnd >= hStart) {
      const editedDay = weekDays[editedDayNum];
      result[editedDay] = makeUnavailable
        ? av[editedDay].filter((n: number) => n < hStart || n > hEnd)
        : Array.from(new Set(av[editedDay].concat(generateSequenceFromTo(hStart, hEnd)))).sort((a, b) => a - b);
    }
  }
  return result;
};

const start = 0;
const end = 1;
export const splitRangesOnDayEnds = (arr: DateRange): DateRange[] => {
  const range = arr.map((date) => date.clone()) as DateRange;
  const result: DateRange[] = [];

  while (!range[start].isSame(range[end], 'day')) {
    const endOfFirstDay = range[start].clone().endOf('day');
    const startOfSecondDay = range[start].clone().startOf('day').add(1, 'day');
    result.push([range[start], endOfFirstDay]);
    range[start] = startOfSecondDay;
  }
  result.push(range);

  return result;
};

const adjustForDst = (date: Moment) => {
  const diff = date.utcOffset() - date.clone().startOf('isoWeek').utcOffset();
  return date.subtract(diff, 'minutes');
};

export const getWeekRange = (weekStart: Moment) => ({
  start: weekStart.toISOString(),
  end: weekStart
    .clone()
    .endOf('isoWeek')
    // Add millisecond to include the right edge of the date range.
    .add(1, 'millisecond')
    .toISOString(),
});

export const combineWeekAvailability = (av: WeekAvailability, weekId: string) => {
  const startOfRange = moment(weekId, 'YYYY-MM-DDTHH:mm:ss.SSSSZ');
  return {
    dateRange: getWeekRange(startOfRange),
    openIntervals: splitContinuesSequences(
      weekDays.reduce((acc, day) => {
        const offset = (mapWeekDay.get(day)! - 1) * 48; // 48 halfs of hours per day
        acc.push(...av[day].map((a) => a + offset));
        return acc;
      }, [] as number[]),
    ).map(({ from, to }) => {
      const startMoment = startOfRange.clone().add(from * 30, 'minutes');
      const endMoment = startOfRange.clone().add(to * 30 + 30, 'minutes');

      return {
        start: adjustForDst(startMoment).toISOString(),
        end: adjustForDst(endMoment).toISOString(),
      };
    }),
  };
};

export const getEvcBookingContentStage = (bc: EvcBookingContent | undefined, fallback: string) =>
  `${(bc && bc.stage) || ''}`.trim() || fallback;

export const weekSlotId = (weekday: Weekday, slotId: number) => (mapWeekDay.get(weekday)! - 1) * 48 + slotId;

export const timeToWeekSlotId = (weekStart: Moment, time: Moment) =>
  Math.floor(time.diff(weekStart, 'day')) * 48 + Math.ceil((time.hours() * 60 + time.minutes()) / 30);

export const isBookingInProgress = (booking: Booking) =>
  moment().isBetween(booking.start, booking.end, 'millisecond', '[)');

export const isBookingInPast = (booking: Booking) => moment().isAfter(booking.end);

export const flattenAvailability = (weekAvailability: WeekAvailability) =>
  weekDays.flatMap((wd) => weekAvailability[wd]?.map((slotID) => weekSlotId(wd, slotID)) || []);

export const autoScrollCalendar = (pos: IAvSelection) => {
  const el = document.getElementById(pos.end.toString());
  const scroll = document.getElementById('calendar-scroll');
  if (el && scroll) {
    const rect = el.getBoundingClientRect();
    if (rect.top <= 300) {
      scroll.scrollBy({
        top: -30,
        behavior: 'smooth',
      });
    } else if (rect.bottom > window.innerHeight - 60) {
      scroll.scrollBy({
        top: 30,
        behavior: 'smooth',
      });
    }
  }
  return true;
};

export const getVerticalAvailabilitySelection = (avSelection: IAvSelection, isDayView: boolean, day: number) => ({
  from: {
    weekday: weekDays[isDayView ? day - 1 : Math.floor(avSelection.start / 48)],
    hourNum: avSelection.start % 48,
  },
  to: {
    weekday: weekDays[isDayView ? day - 1 : Math.floor(avSelection.start / 48)],
    hourNum: avSelection.end % 48,
  },
});

export const getAvailabilitySelection = (avSelection: IAvSelection, isDayView: boolean, day: number) => ({
  from: idxToCoordinate(avSelection.start, isDayView, day),
  to: idxToCoordinate(avSelection.end, isDayView, day),
});

const idxToCoordinate = (idx: number, isDayView?: boolean, day?: number) => {
  if (isDayView && day) {
    return {
      weekday: weekDays[day - 1],
      hourNum: idx % 48,
    };
  } else {
    return {
      weekday: weekDays[Math.floor(idx / 48)],
      hourNum: idx % 48,
    };
  }
};

// given current selection `selection` and `currIdx` of the cell,
// calculate what borders have to be highlighted
export const selectionBoundaries = (
  selection: IAvSelection,
  currIdx: number,
  isSameDaySelection?: boolean,
): SelectionBorder[] => {
  const startCoorddinate = idxToCoordinate(selection.start);
  const endCoordinate = idxToCoordinate(selection.end);
  // x-coordinate is 0..6 week day, y-coordinate is 0..47 half hour
  const startX = mapWeekDay.get(startCoorddinate.weekday)!;
  const endX = isSameDaySelection ? mapWeekDay.get(startCoorddinate.weekday)! : mapWeekDay.get(endCoordinate.weekday)!;

  // selection box in X-Y plane
  // X stands for weekdays 0..6
  // Y stands for half-hours 0..47
  const minX = Math.min(startX, endX);
  const maxX = Math.max(startX, endX);
  const minY = Math.min(startCoorddinate.hourNum, endCoordinate.hourNum);
  const maxY = Math.max(startCoorddinate.hourNum, endCoordinate.hourNum);

  // current selection
  const curr = idxToCoordinate(currIdx);
  const x = mapWeekDay.get(curr.weekday)!;
  const y = curr.hourNum;

  const boundaries: SelectionBorder[] = [];
  if (minY <= y && y <= maxY) {
    if (minX === x) {
      boundaries.push('left');
    }
    if (maxX === x) {
      boundaries.push('right');
    }
  }

  if (minX <= x && x <= maxX) {
    if (minY === curr.hourNum) {
      boundaries.push('top');
    }
    if (maxY === curr.hourNum) {
      boundaries.push('bottom');
    }
  }

  return boundaries;
};

const isDayLessThanOrEqualTo = (day1: Weekday, day2: Weekday) => weekDays.indexOf(day1) <= weekDays.indexOf(day2);

/** getHalfHourNumFromDate
 * This calculates hour numbers assuming the dateTime is exactly on an hourmark (e.g. 13:00) or half hour mark (e.g. 13:30).
 * */
export const getHalfHourNumFromDate = (dateTime: Date) => {
  // Since each cell is half hour, we multiply hours by 2
  const halfHourBlocks = dateTime.getHours() * 2;
  // Last 30 minutes may count as an extra block,
  const maybeLast30MinutesBlock = dateTime.getMinutes() >= 30 ? 1 : 0;

  return halfHourBlocks + maybeLast30MinutesBlock;
};

const getWeekDay = (date: Date) => moment(date).isoWeekday();

export const getIsExclusive = (cellDate: Date, selectionBlocks: IAvailabilitySelection[]): boolean => {
  if (!selectionBlocks.length) {
    return false;
  }
  const cellDayMoment = moment(cellDate);
  return selectionBlocks.some((block) => {
    const startOfWeek = cellDayMoment.clone().startOf('isoWeek');
    let startWeekDay = mapWeekDay.get(block.from.weekday);
    let endWeekDay = mapWeekDay.get(block.to.weekday);

    if (!startWeekDay || !endWeekDay) {
      // This should never be true but if it is, we should not crash the app
      ddlog.error('unable to get week day from block in getIsExclusive', block);
      startWeekDay = 1;
      endWeekDay = 1;
    }

    const blockStart = startOfWeek
      .clone()
      .add(startWeekDay - 1, 'days')
      .add(block.from.hourNum * 30, 'minutes');
    const blockEnd = startOfWeek
      .clone()
      .add(endWeekDay - 1, 'days')
      .add(block.to.hourNum * 30, 'minutes');

    return cellDayMoment.isBetween(blockStart, blockEnd, null, '[]');
  });
};

const hasTopEdge = (cellDate: Date, selectionBlocks: IAvailabilitySelection[]) =>
  selectionBlocks.some((block) => {
    const halfHoursNum = getHalfHourNumFromDate(cellDate);
    const cellDay = getWeekDay(cellDate);

    return (
      block.from.hourNum === halfHoursNum &&
      isDayLessThanOrEqualTo(block.from.weekday, weekDays[cellDay - 1]) &&
      isDayLessThanOrEqualTo(weekDays[cellDay - 1], block.to.weekday)
    );
  });

const hasBottomEdge = (cellDate: Date, selectionBlocks: IAvailabilitySelection[]) =>
  selectionBlocks.some((block) => {
    const halfHoursNum = getHalfHourNumFromDate(cellDate);
    const cellDay = getWeekDay(cellDate);

    return (
      block.to.hourNum === halfHoursNum &&
      isDayLessThanOrEqualTo(block.from.weekday, weekDays[cellDay - 1]) &&
      isDayLessThanOrEqualTo(weekDays[cellDay - 1], block.to.weekday)
    );
  });

export const getExclusiveCellBorder = (
  cellDate: Date,
  selectionBlocks: IAvailabilitySelection[],
): SelectionBorder[] => {
  const selectionBorders: SelectionBorder[] = ['left', 'right'];

  if (hasTopEdge(cellDate, selectionBlocks)) {
    selectionBorders.push('top');
  }
  if (hasBottomEdge(cellDate, selectionBlocks)) {
    selectionBorders.push('bottom');
  }

  return selectionBorders;
};

export const sortSelectionFromTo = (selection: IAvailabilitySelection): IAvailabilitySelection => {
  const [newFrom, newTo] = sortFromTo(selection.from.hourNum, selection.to.hourNum);

  return {
    from: { weekday: selection.from.weekday, hourNum: newFrom },
    to: { weekday: selection.to.weekday, hourNum: newTo },
  };
};

export const trimSelectionToOnlyFuture = ({
  selection,
  rangeEnd,
  rangeStart,
  now,
}: {
  selection: IAvailabilitySelection;
  rangeEnd: Moment;
  rangeStart: Moment;
  now: Moment;
}): IAvailabilitySelection | undefined => {
  // If selecting in the past week, dont do anything
  if (now.isAfter(rangeEnd.clone().endOf('isoWeek'))) {
    return undefined;
  }

  // If selecting in a future week
  if (now.clone().endOf('isoWeek').isBefore(rangeStart.clone().startOf('isoWeek'))) {
    return selection;
  }

  // If selecting in current week, allow only selections from present
  const currentDay = now.get('weekday');
  const selectionDay = selection.from.weekday;
  if (currentDay > Number(mapWeekDay.get(selectionDay))) {
    return undefined;
  }

  // If selecting in the future but within current week, allow
  if (currentDay < Number(mapWeekDay.get(selectionDay))) {
    return selection;
  } else {
    // If selecting from today, check if selection is in past
    const currentHourNum = getHalfHourNumFromDate(now.toDate());
    if (selection.to.hourNum <= currentHourNum) {
      return undefined;
    }
    const modifiedSelection = { ...selection };

    // If selecting from past to future, trim down the selection to only have future.
    if (selection.from.hourNum <= currentHourNum) {
      modifiedSelection.from.hourNum = currentHourNum + 1;
    }
    return modifiedSelection;
  }
};

export const trimSelectionToAvoidOverlaps = (
  availabilityBlocks: IAvailabilitySelection[],
  selection?: IAvailabilitySelection,
): IAvailabilitySelection | undefined => {
  if (!selection) {
    return undefined;
  }

  let isEclipsed = false;
  const modifiedSelection = { ...selection };
  availabilityBlocks.every((block) => {
    // Are they on the same day?
    if (block.from.weekday === selection.from.weekday && block.to.weekday === selection.to.weekday) {
      // Is selection overlapping above the block?
      if (block.from.hourNum < selection.from.hourNum && selection.from.hourNum < block.to.hourNum) {
        if (selection.to.hourNum <= block.to.hourNum) {
          isEclipsed = true;
          return false;
        } else {
          modifiedSelection.from.hourNum = block.to.hourNum + 1;
        }
      } else if (block.from.hourNum <= selection.to.hourNum && selection.to.hourNum < block.to.hourNum) {
        // selection overlapping below the block
        if (selection.from.hourNum >= block.from.hourNum) {
          isEclipsed = true;
          return false;
        } else {
          modifiedSelection.to.hourNum = block.from.hourNum - 1;
        }
      }

      if (selection.from.hourNum <= block.from.hourNum && selection.to.hourNum >= block.to.hourNum) {
        isEclipsed = true;
        return false;
      }
    }
    return true;
  });
  if (isEclipsed) {
    return undefined;
  }
  return modifiedSelection;
};

export const getMomentFromCellCoordinate = (cellCoordinate: ICellCoordinate, weekId: string) => {
  const weekStartDate = moment(weekId);
  return weekStartDate
    .add(mapWeekDay.get(cellCoordinate.weekday)! - 1, 'days')
    .add(Math.floor(cellCoordinate.hourNum / 2), 'hours')
    .add(cellCoordinate.hourNum % 2 === 1 ? 30 : 0, 'minutes');
};

export const dateTimeStringToICellCoordinate = (dateString: string): ICellCoordinate => ({
  weekday: weekDays[getWeekDay(new Date(dateString)) - 1],
  hourNum: getHalfHourNumFromDate(new Date(dateString)),
});

export const mapExclusiveAvailabilityResponseToBlocks = ({
  id,
  start: dateStart,
  end: dateEnd,
  lessonDefinitions,
}: ExclusiveAvailabilityResponse): AvailabilityBlock => ({
  id,
  lessonTypes: lessonDefinitions,
  block: {
    from: dateTimeStringToICellCoordinate(dateStart),
    to: dateTimeStringToICellCoordinate(moment(dateEnd).subtract(1, 'millisecond').format()),
  },
});

export const filterExclusiveAvailabilityByWeekId =
  (rangeStart: Moment, rangeEnd: Moment) =>
  ({ start: dateStart, end: dateEnd }: ExclusiveAvailabilityResponse) =>
    rangeStart.isSameOrBefore(moment(dateStart)) &&
    rangeEnd.clone().add(1, 'millisecond').isSameOrAfter(moment(dateEnd));

export const momentToStartOfWeekISOString = (date: Moment): string => date.clone().startOf('isoWeek').toISOString();

export const markUnclaimedLessonWithConflicts = (
  unclaimedLessons: IUnclaimedLessonGroup[] | undefined,
): IUnclaimedLessonGroup[] => {
  if (!unclaimedLessons?.length) {
    return [];
  }

  return unclaimedLessons.map((unclaimedLesson) => {
    const { start: bookingStart } = unclaimedLesson;
    const hasConflict = unclaimedLessons.some(
      (lesson) =>
        lesson.start.isSame(bookingStart.clone().add(30, 'minute')) ||
        lesson.start.isSame(bookingStart.clone().subtract(30, 'minute')),
    );

    const toTheRight = hasConflict && bookingStart.minutes() === 30;

    return {
      ...unclaimedLesson,
      hasConflict,
      toTheRight,
    };
  });
};

const leftPadWithZeros = (num: number) => new Intl.NumberFormat('en-US', { minimumIntegerDigits: 2 }).format(num);

export const classTimes = Array(24)
  .fill('')
  .flatMap((_, index) => [`${leftPadWithZeros(index)}:00`, `${leftPadWithZeros(index)}:30`]);
