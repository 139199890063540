enum Events {
  LANDING_PAGE = 'Landing Page: View',

  ADMIN_CLASS_RECORDS_VIEW = 'Admin Class Records: View',

  AVAILABILITY_EDIT = 'Availability: Click Edit',
  AVAILABILITY_SAVE = 'Availability: Click Save',
  AVAILABILITY_CANCEL = 'Availability: Click Cancel',
  AVAILABILITY_REPEAT = 'Availability: Click Repeat',
  AVAILABILITY_SUBOUT = 'Availability: Booking Subout',
  AVAILABILITY_SUBOUT_COUNT = 'Availability: Subout Count',

  DASHBOARD_VIEW = 'Dashboard: View',
  DASHBOARD_ENTER_CLASS = 'Dashboard: Enter Class',
  DASHBOARD_TECH_CHECK = 'Dashboard: Tech Check',
  DASHBOARD_ACR = 'Dashboard: Click ACR',
  DASHBOARD_CLASS_PREP = 'Dashboard: Click Class Prep',
  DASHBOARD_MINDFLASH = 'Dashboard: Click training link',

  ACR_VIEW = 'ACR: View',
  ACR_SAVE = 'ACR: Click Save',
  ACR_SUBMIT = 'ACR: Click Submit',
  ACR_FORM_INVALIDATED = 'ACR: Form Invalidated',
  ACR_CLASS_PREP = 'ACR: Click Class Preparation',
  ACR_TEMPLATE_CATEGORY = 'ACR: Click Template Category',
  ACR_TEMPLATE_COPIED = 'ACR: Copy Template',
  ACR_TEMPLATE_EDITED = 'ACR: Edit Template',
  ACR_TEMPLATE_CREATE = 'ACR: Clone Template',
  ACR_OPEN_STUDENT_BIO_EDIT = 'ACR: Open Student Bio Edit',
  ACR_SAVE_STUDENT_BIO_EDIT = 'ACR: Save Student Bio Edit',

  CLASS_PREP_VIEW = 'Class Prep: View',
  CLASS_PREP_ENTER_CLASS = 'Class Prep: Enter Class',
  CLASS_PREP_PREVIEW_PPT = 'Class Prep: Click Preview PPT',
  CLASS_PREP_PREVIEW_PPT_OPEN_MODAL = 'Class Prep: Open Preview PPT Modal',
  CLASS_PREP_PREVIEW_PPT_PREV = 'Class Prep: Click PPT Previous',
  CLASS_PREP_PREVIEW_PPT_NEXT = 'Class Prep: Click PPT Next',
  CLASS_PREP_STUDENT_REPORT = 'Class Prep: Click Student Report',
  CLASS_PREP_STUDENT_REPORT_NEXT = 'Class Prep: Student Report Click Next',
  CLASS_PREP_STUDENT_REPORT_PREV = 'Class Prep: Student Report Click Prev',
  CLASS_PREP_STUDENT_REPORT_CHART = 'Class Prep: Click Student Report Chart',
  CLASS_PREP_ACR_TOGGLE = 'Class Prep: Toggle ACR',
  CLASS_PREP_OPEN_STUDENT_BIO_EDIT = 'Class Prep: Open Student Bio Edit',
  CLASS_PREP_SAVE_STUDENT_BIO_EDIT = 'Class Prep: Save Student Bio Edit',

  CALENDAR_VIEW = 'Calendar: View',
  CALENDAR_EDIT_VIEW = 'Edit Calendar: View',
  CALENDAR_ACR = 'Calendar: Click ACR',
  CALENDAR_ENTER_CLASS = 'Calendar: Enter Class',
  CALENDAR_CLASS_PREP = 'Calendar: Click Class Prep',
  CALENDAR_BOOKING = 'Calendar: Click Booking',
  CALENDAR_SUBOUT = 'Calendar: Click Subout',
  CALENDAR_SUBOUT_SUBMIT = 'Calendar: Submit Subout',
  CALENDAR_SUBOUT_CANCEL = 'Calendar: Cancel Subout',
  CALENDAR_TODAY = 'Calendar: Click Today',
  CALENDAR_WEEK_VIEW = 'Calendar: Click Week View',
  CALENDAR_DAY_VIEW = 'Calendar: Click Day View',
  CALENDAR_DAYS_CHANGE = 'Calendar: Days Change',
  CALENDAR_MESSAGE_STUDENT = 'Calendar: Message a student',
  CALENDAR_BOOK_FOR_STUDENT = 'Calendar: Book for a student',
  CALENDAR_BOOK_FOR_GROUP = 'Calendar: Book for a group',
  CALENDAR_EDIT_RESERVE_TIMESLOT = 'Calendar: Edit reserve timeslot',
  CALENDAR_RESERVE_TIMESLOT_XHR = 'Calendar: Enable/Disable Reserve timeslot X hr flag',
  CALENDAR_UNASSIGNED_LESSON = 'Unassigned Lesson: Click Booking',
  CALENDAR_UNASSIGNED_LESSON_CLAIM = 'Unassigned Lesson: Click Claim Booking',

  TEACHER_SEARCH_FILTER_CENTER = 'Teacher Search: Filter Center',
  TEACHER_SEARCH_FILTER_SKILLS = 'Teacher Search: Filter Skills',
  TEACHER_SEARCH_FILTER_LANGUAGE = 'Teacher Search: Filter Language',
  TEACHER_SEARCH_FILTER_INTERESTS = 'Teacher Search: Filter Interests',

  TEACHER_SEARCH_PROFILE = 'Teacher Search: Click Profile',
  TEACHER_SEARCH_EDIT_PROFILE = 'Teacher Search: Edit Profile',
  TEACHER_SEARCH_EDIT_PROFILE_SKILLS = 'Teacher Search: Edit Profile Skills',
  TEACHER_SEARCH_EDIT_PROFILE_LANGUAGE = 'Teacher Search: Edit Profile Language',
  TEACHER_SEARCH_EDIT_PROFILE_INTEREST = 'Teacher Search: Edit Profile Interest',

  SCHEDULING_VIEW = 'Lesson Scheduling: View',
  SCHEDULING_FILTER_CENTER = 'Lesson Scheduling: Filter Center',
  SCHEDULING_FILTER_SKILLS = 'Lesson Scheduling: Filter Skills',
  SCHEDULING_FILTER_LANGUAGE = 'Lesson Scheduling: Filter Language',
  SCHEDULING_LESSON = 'Lesson Scheduling: Click Lesson',
  SCHEDULING_LESSON_CANCEL = 'Lesson Scheduling: Cancel Lesson',
  SCHEDULING_LESSON_SUBOUT = 'Lesson Scheduling: Subout Lesson',
  SCHEDULING_LESSON_REASSIGN = 'Lesson Scheduling: Reassign Lesson',
  SCHEDULING_REFRESH = 'Lesson Scheduling Refresh',

  STUDENT_PORTAL_VIEW = 'Student Portal: View',

  STUDENT_REVIEWS_VIEW = 'Student Reviews: View',

  ASSIGN_LESSONS_SELECT = 'Assign Lessons: Select Teacher',
  ASSIGN_LESSONS_SEARCH = 'Assign Lessons: Search Teacher',

  UNASSIGNED_VIEW = 'Unassigned Lessons: View',
  UNASSIGNED_FILTER_CENTER = 'Unassigned Lessons: Filter Center',
  UNASSIGNED_FILTER_LESSON = 'Unassigned Lessons: Filter Lesson',
  UNASSIGNED_ASSIGN_LESSON = 'Unassigned Lessons: Assign Lesson',

  CLASS_RECORDS_VIEW = 'Class Records: View',
  CLASS_RECORDS_FILTER_LESSON = 'Class Records: Filter Lesson Type',
  CLASS_RECORDS_FILTER_SYSTEM_STATUS = 'Class Records: Filter System Status',
  CLASS_RECORDS_FILTER_CENTER_STATUS = 'Class Records: Filter Center Status',
  CLASS_RECORDS_LESSON_DETAILS = 'Class Records: Click Lesson Details',
  CLASS_RECORDS_EDIT_LESSON_STATUS = 'Class Records: Edit Lesson Status',
  CLASS_RECORDS_EDIT_LESSON_STATUS_BULK = 'Class Records: Bulk Edit Lesson Status',
  CLASS_RECORDS_PAGE_ITEMS = 'Class Records: Adjust Items Per Page',

  CLASS_SUMMARY_VIEW = 'Class Summary: View',
  CLASS_SUMMARY_TEACHER_SEARCH = 'Class Summary: Search by teacher name',
  CLASS_SUMMARY_FILTER_CENTER = 'Class Summary: Filter Center',
  CLASS_SUMMARY_FILTER_LESSON = 'Class Summary: Filter Lesson Type',
  CLASS_SUMMARY_DATE_CHANGE = 'Class Summary: Date change',

  INVOICE_VIEW = 'Invoice: View',
  INVOICES_LIST_VIEW = 'Invoices List: View',
  INVOICE = 'Invoices: Click invoice',
  INVOICE_ADJUSTMENT_OPEN = 'Invoices: Click invoice item adjustment edit',
  INVOICE_ADJUSTMENT_SAVE = 'Invoices: Click invoice item adjustment save',
  INVOICE_ADJUSTMENT_CANCEL = 'Invoices: Click invoice item adjustment cancel',
  INVOICE_ADJUSTMENT_REMOVE = 'Invoices: Click invoice item adjustment remove',
  INVOICE_DISPUTE_FEE = 'Invoices: Click invoice item dispute',
  INVOICE_REMOVE_DISPUTE_FEE = 'Invoices: Click invoice item remove dispute',
  INVOICE_SUBMIT_DISABLED = 'Invoices: Click disabled invoice submit',
  INVOICE_DOWNLOAD_INVOICE = 'Invoices: Click download pdf',
  INVOICE_SUBMIT_CONTESTED = 'Invoice: Submit Contested',
  INVOICE_SUBMIT = 'Invoice: Submit',
  INVOICE_SUBMIT_AUTO_APPROVE_AGREEMENT_SUBMIT = 'Invoice: Submit Auto Approval Agreement Submit',
  INVOICE_SUBMIT_AUTO_APPROVE_AGREEMENT_CANCEL = 'Invoice: Submit Auto Approval Agreement Cancel',
  INVOICE_SUBMIT_ADJUSTMENT_AGREEMENT_SUBMIT = 'Invoice: Submit Adjustment Agreement Submit',
  INVOICE_SUBMIT_ADJUSTMENT_AGREEMENT_CANCEL = 'Invoice: Submit Adjustment Agreement Cancel',

  EXIT_SURVEYS_IGNORE_SCORE = 'Exit Surveys: Ignore exit survey score',
  EXIT_SURVEYS_UNIGNORE_SCORE = 'Exit Surveys: Unignore exit survey score',

  CALENDAR_STUDENT_ACTION = 'Calendar: Student action click',

  SETTINGS = 'Settings: View',
  SETTINGS_SECONDARY_TIMEZONE_CHECK = 'Settings: Enable/Disable Secondary Timezone',
  SETTINGS_SECONDARY_TIMEZONE = 'Settings: Select Secondary Timezone',

  WEBCAL = 'Settings: Copy webcal link',

  HELP_CENTER = 'Click Help Center',

  LOGOUT = 'Click Logout',

  MANUAL_REDIRECT_ENTER_CLASS = 'Classroom: Manual redirect',
  MANUAL_RETRY_ENTER_CLASS = 'Classroom: Manual retry',

  ANNOUNCEMENT_LOAD_MORE = 'Announcement: load more',
  ANNOUNCEMENT_ARCHIVE = 'Announcement: archive',
  ANNOUNCEMENT_VIEW = 'Announcement: view',
  ANNOUNCEMENT_CREATE = 'Announcement: create',
  ANNOUNCEMENT_POST = 'Announcement: post',
  ANNOUNCEMENT_PREVIEW = 'Announcement: preview',
  ANNOUNCEMENT_MODAL_NEXT = 'Announcement modal: next',
  ANNOUNCEMENT_MODAL_PREVIOUS = 'Announcement modal: previous',
  ANNOUNCEMENT_WIDGET_NEXT = 'Announcement widget: next',
  ANNOUNCEMENT_WIDGET_PREVIOUS = 'Announcement widget: previous',
  ANNOUNCEMENT_WIDGET_READ_MORE = 'Announcement widget: read more',

  SCHEDULED_CLASSES_VIEW_ASSIGNED = 'Scheduled classes: view assigned',
  SCHEDULED_CLASSES_VIEW_UNASSIGNED = 'Scheduled classes: view unassigned',
  SCHEDULED_CLASSES_CLICK_VIEW_CLASS_ASSIGNED = 'Scheduled classes: click view class assigned',
  SCHEDULED_CLASSES_CLICK_VIEW_CLASS_PENDING = 'Scheduled classes: click view class pending',
  SCHEDULED_CLASSES_CLICK_LOAD_MORE_ASSIGNED = 'Scheduled classes: click load more assigned',
  SCHEDULED_CLASSES_CLICK_LOAD_MORE_UNASSIGNED = 'Scheduled classes: click load more unassigned',
  SCHEDULED_CLASSES_CLICK_LOAD_MORE_PENDING = 'Scheduled classes: click load more pending',
  SCHEDULED_CLASSES_CLICK_PENDING_REJECT = 'Scheduled classes: click pending reject',
  SCHEDULED_CLASSES_CLAIM_CLASS_MODAL_CLAIM = 'Scheduled classes: claim class modal claim click',
  SCHEDULED_CLASSES_CLAIM_CLASS_MODAL_CANCEL = 'Scheduled classes: claim class modal claim cancel',
  SCHEDULED_CLASSES_CLICK_UNASSIGNED_CLAIM_CLASS = 'Scheduled classes: click unassigned claim class',
  SCHEDULED_CLASSES_CLICK_ASSIGNED_BOOK_LESSON = 'Scheduled classes: click assigned book lesson',
  SCHEDULED_CLASSES_EDIT_LESSON_TITLE_CLASS_PREP = 'Scheduled classes: edit lesson title class prep',

  SCHEDULED_CLASSES_VIEW = 'Scheduled classes: view',
  SCHEDULED_CLASSES_CLICK_CLASS = 'Scheduled classes: click class',
  SCHEDULED_CLASSES_CLICK_COURSE_DURATION_FILTER = 'Scheduled classes: click course duration filter',
  SCHEDULED_CLASSES_CLICK_LESSON_TYPE_FILTER = 'Scheduled classes: click lesson type filter',
  SCHEDULED_CLASSES_CLICK_STATUS_FILTER = 'Scheduled classes: click status filter',
  SCHEDULED_CLASSES_CLICK_ASSIGNMENT_STATUS_FILTER = 'Scheduled classes: click assignment status filter',
  SCHEDULED_CLASSES_CLICK_SEARCH_BAR = 'Scheduled classes: click search bar',
  VIEW_SCHEDULED_CLASS_UNASSIGN_TEACHER = 'View scheduled class: unassign teacher',
  VIEW_SCHEDULED_CLASS_VIEW_EMAIL = 'View scheduled class: view email',
  VIEW_SCHEDULED_BOOK_LESSON = 'View scheduled class: book lesson',
  VIEW_SCHEDULED_EDIT_TOPIC = 'View scheduled class: edit topic',
  VIEW_SCHEDULED_PREPARE = 'View scheduled class: prepare',

  UNCONFIRMED_SCHEDULED_CLASSES_VIEW = 'Unconfirmed scheduled classes: View',
  UNCONFIRMED_SCHEDULED_CLASSES_CLICK_CLASS = 'Unconfirmed scheduled classes: View scheduled class',
  UNCONFIRMED_SCHEDULED_CLASSES_CLICK_STARTS_IN_FILTER = 'Unconfirmed scheduled classes: Click starts in filter',
  UNCONFIRMED_SCHEDULED_CLASSES_CLICK_ASSIGNMENT_STATUS_FILTER = 'Unconfirmed scheduled classes: Click assignment status filter',
  UNCONFIRMED_SCHEDULED_CLASSES_CLICK_TIME_SINCE_CREATED_FILTER = 'Unconfirmed scheduled classes: Click time since created filter',
  UNCONFIRMED_SCHEDULED_CLASSES_CLICK_TIME_WITHOUT_TEACHER_FILTER = 'Unconfirmed scheduled classes: Click time without teacher filter',
  UNCONFIRMED_SCHEDULED_CLASSES_CLICK_LESSON_FILTER = 'Unconfirmed scheduled classes: Click lesson filter',
  UNCONFIRMED_SCHEDULED_CLASSES_CLICK_LOAD_MORE = 'Unconfirmed scheduled classes: Click load more',
}

export default Events;
