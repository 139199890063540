import useSWRImmutable from 'swr/immutable';

import { DropdownDataProps } from 'components/MultiselectDropdown';
import { InstructionLangauge } from 'model/misc';
import { EvcProxyTunnel, ITeacher, IUser } from 'model/profiles';
import makeRequest from 'network/helpers/makeRequest';
import { getTeacherFullName, isValidUUID } from 'utils/miscellaneous';

export const fetchProfile = (id: string): Promise<IUser> =>
  makeRequest<IUser>({
    pathname: `/users/${id}`,
  });

export type TeacherProfileToUpdate = Partial<Omit<ITeacher, 'id' | 'emailSubscribed' | 'status' | 'path'>>;

export const updateTeacherProfile = (teacherId: string, tProfile: TeacherProfileToUpdate): Promise<ITeacher> =>
  makeRequest<ITeacher>({
    pathname: `teachers/${teacherId}`,
    method: 'PATCH',
    body: JSON.stringify(tProfile),
  });

const getTeacherProfileUrl = (teacherId: string) => `teachers/${teacherId}`;

export const fetchTeacherProfile = (teacherId: string): Promise<ITeacher> =>
  makeRequest<ITeacher>({
    pathname: getTeacherProfileUrl(teacherId),
  });

export const useTeacherProfile = (teacherId: string) => {
  const cacheKey = teacherId && isValidUUID(teacherId) ? getTeacherProfileUrl(teacherId) : null;
  const { data, error, mutate } = useSWRImmutable<ITeacher>(cacheKey, () => fetchTeacherProfile(teacherId));

  return {
    teacher: data,
    teacherName: data && getTeacherFullName(data),
    isLoading: !error && !data,
    isError: error,
    refetch: mutate,
  };
};

export const useEvcProxyList = () =>
  useSWRImmutable('evc/tunnels', () =>
    makeRequest<{ data: EvcProxyTunnel[] }>({ pathname: 'evc/tunnels' }).then((response) => response.data),
  );

export const useInstructionLanguages = () =>
  useSWRImmutable('v1/languages', () =>
    makeRequest<{ data: InstructionLangauge[] }>({
      pathname: 'languages',
    }).then((response) => response.data),
  );

export const useLanguageOptions = (): DropdownDataProps[] => {
  const { data: languageOptions } = useInstructionLanguages();

  return languageOptions?.map((option) => ({ name: option.title, value: option.isoCode })) || [];
};
