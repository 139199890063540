import { CSSProperties } from '@mui/styles';
import React, { useState } from 'react';

import Icon from 'components/Icon';
import ListItem, { IMultiSelectListItemData } from 'components/MultiselectDropdown/ListItem';
import OnClickOutside from 'components/OnClickOutside';
import { pluralize } from 'utils/string';

import * as S from './multiselect-dropdown.styled';

interface MultiselectDropdownProps {
  size?: number;
  options: DropdownDataProps[];
  disabled?: boolean;
  name: string;
  id: string;
  onChange?: (id: string, values: string[]) => void;
  onClick?: () => void;
  values: any[]; // eslint-disable-line
  placeholder?: string;
  label?: string;
  width?: CSSProperties['width'];
  boxWidth?: CSSProperties['width'];
  height?: number;
  analytics?: string;
  analyticsTeacherId?: string;
  readOnly?: boolean;
  pluralName?: string;
  unsorted?: boolean;
}

export interface DropdownDataProps {
  name: string;
  value: string;
  input?: boolean;
  inputValue?: string;
}

const getPlaceholder = (
  values: string[],
  options: DropdownDataProps[],
  name: string,
  placeholder?: string,
  pluralName?: string,
): string => {
  if (values.length === options.length && options.length !== 1) {
    return `All ${pluralName || pluralize(name, values.length, false)}`;
  }
  if (values.length === 1) {
    return `${options.find((option) => values[0] === option.value)?.name}`;
  }
  if (values.length > 1) {
    return `${pluralName ? `${values.length} ${pluralName}` : pluralize(name, values.length, true)} selected`;
  }
  if (placeholder?.length) {
    return placeholder;
  }
  return 'Select all that apply';
};

const MultiselectDropdown = ({
  size,
  options,
  disabled,
  name,
  id,
  onChange,
  onClick,
  values,
  placeholder,
  label,
  width,
  boxWidth,
  height,
  analytics,
  analyticsTeacherId,
  readOnly,
  pluralName,
  unsorted,
}: MultiselectDropdownProps) => {
  const [opened, setOpened] = useState<boolean>(false);

  const checkBox = (value: string, checked: boolean) => {
    const checkedValues = [...values];
    if (checked) {
      checkedValues.push(value);
    } else {
      const index = checkedValues.indexOf(value);
      if (index > -1) {
        checkedValues.splice(index, 1);
      }
    }
    onChange?.(id, checkedValues);
  };

  const selectAll = () =>
    onChange?.(
      id,
      options.map(({ value }) => value),
    );

  const deselectAll = () => onChange?.(id, []);
  const onItemChange = (data: IMultiSelectListItemData) => {
    checkBox(data.value, data.checked);
  };
  const isChecked = (data: DropdownDataProps): boolean => values.includes(data.value as string);

  const sortOptions = () => (unsorted ? options : options.sort((a, b) => a.name.localeCompare(b.name)));

  return (
    <S.Wrapper id={id}>
      {label && <S.Label>{label}</S.Label>}
      <OnClickOutside
        fn={() => {
          onClick?.();
          setOpened(false);
        }}
      >
        <S.MultiselectWrapper style={{ height, width }}>
          <S.CloseArrow disabled={disabled!} onClick={disabled ? undefined : () => setOpened(!opened)}>
            <Icon icon="multiSelectArrow" size={15} />
          </S.CloseArrow>
          <S.SelectInput
            disabled={disabled || options.length === 0}
            type="text"
            name="Search"
            title={name}
            id={id}
            placeholder={getPlaceholder(values, options, name, placeholder, pluralName)}
            readOnly={readOnly}
            onFocus={disabled ? undefined : () => setOpened(true)}
            onKeyDown={(e) => e.preventDefault()}
            data-qa="dropdown-select"
            style={{ cursor: disabled ? 'default' : 'pointer', height }}
          />
          <S.SearchResult opened={opened} width={boxWidth} className="multi-select-list">
            <S.ListResults>
              <S.SelectDeselectAllWrapper>
                <S.Anchor $disabled={!!readOnly} onClick={!readOnly ? selectAll : undefined}>
                  Select All
                </S.Anchor>
                &nbsp;|&nbsp;
                <S.Anchor $disabled={!!readOnly} onClick={!readOnly ? deselectAll : undefined}>
                  Deselect All
                </S.Anchor>
              </S.SelectDeselectAllWrapper>
              {sortOptions().map((data) => (
                <ListItem
                  size={size}
                  key={data.name}
                  data={data}
                  isChecked={isChecked(data)}
                  onChanged={onItemChange}
                  analytics={analytics}
                  analyticsTeacherId={analyticsTeacherId}
                  readOnly={readOnly}
                />
              ))}
            </S.ListResults>
          </S.SearchResult>
        </S.MultiselectWrapper>
      </OnClickOutside>
    </S.Wrapper>
  );
};

export default MultiselectDropdown;
