import React from 'react';
import styled from 'styled-components';

import { preloaderImgSource } from 'utils/window';

const LoadingIconStyled = styled.img<{ size?: number }>`
  width: ${(props) => (props.size ? props.size : '60')}px;
  height: ${(props) => (props.size ? props.size : '60')}px;
`;

interface Props {
  msg?: string;
  size?: number;
}

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const LoadingMessage = ({ msg, size }: Props) => (
  <Container>
    <LoadingIconStyled size={size} src={preloaderImgSource} />
    {msg && <p>{msg}</p>}
  </Container>
);

export const LoadingIcon = <LoadingIconStyled src={preloaderImgSource} />;

const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`;

export const Loading = ({ size }: { size?: number }) => (
  <LoadingWrapper>
    <LoadingIconStyled size={size} src={preloaderImgSource} />
  </LoadingWrapper>
);

export default LoadingMessage;
