import { BrowserCacheLocation, Configuration, LogLevel } from '@azure/msal-browser';

import env from 'config/env';

const AUTHORITY_BASE_URL = env.LOGIN.AUTHORITY_BASE_URL;
const CLIENT_ID = env.LOGIN.CLIENT_ID;
const SIGNIN_SIGNUP_USER_FLOW = env.LOGIN.SIGNIN_SIGNUP_USER_FLOW;
const RESET_USER_FLOW = env.LOGIN.RESET_USER_FLOW;

const b2cPolicies = {
  names: {
    signUpSignIn: SIGNIN_SIGNUP_USER_FLOW,
    forgotPassword: RESET_USER_FLOW,
  },
  authorities: {
    signUpSignIn: {
      authority: AUTHORITY_BASE_URL + SIGNIN_SIGNUP_USER_FLOW,
    },
    forgotPassword: {
      authority: AUTHORITY_BASE_URL + RESET_USER_FLOW,
    },
  },
};

export const msalConfigSignin: Configuration = {
  auth: {
    clientId: CLIENT_ID,
    authority: b2cPolicies.authorities.signUpSignIn.authority,
    knownAuthorities: ['efiddev.b2clogin.com', 'efideu.b2clogin.com'],
    redirectUri: '/',
    postLogoutRedirectUri: '/',
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage,
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions: {
      logLevel: LogLevel.Verbose,
    },
    iframeHashTimeout: 10e3, // Applies just to silent calls - In milliseconds
    loadFrameTimeout: 10e3, // Applies to both silent and popup calls - In milliseconds
  },
};
