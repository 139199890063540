import AmplitudeSingleton from 'amplitude-js';
import { detect } from 'detect-browser';

import env from 'config/env';
import { NOOP } from 'utils/miscellaneous';
import window from 'utils/window';

import { amplitudeContainer } from './helpers';

const apiKey = env?.API_KEYS?.amplitude;

const browser = detect();

export const initAmplitude = !apiKey
  ? NOOP
  : (teacherId: string, origin: string, school?: string, center?: string, role?: string) => {
      amplitudeContainer.amplitude = AmplitudeSingleton.getInstance();
      amplitudeContainer.amplitude.init(apiKey, teacherId, {});
      amplitudeContainer.amplitude.setUserProperties({
        Origin: origin,
        School: school,
        Center: center,
        Role: role,
        Resolution: `${window.screen.width}x${window.screen.height}`,
        Browser: browser?.name || 'unknown',
        Version: browser?.version || 'unknown',
        OS: browser?.os || 'unknown',
      });
    };
