import React from 'react';

interface ISVG {
  className?: string;
}

export const Blue = ({ className, ...props }: ISVG) => (
  <svg width="75" height="179" xmlns="http://www.w3.org/2000/svg" {...props} className={className}>
    <path d="M26.179,1.872c18.335,-3.288 31.844,-0.255 40.145,8.98c8.303,9.235 8.576,16.733 8.576,33.774c0,17.041 -3.956,38.413 -5.465,53.79c-1.51,15.378 -3.177,35.622 -6.038,49.963c-2.861,14.341 -7.547,28.003 -21.887,30.244c-14.34,2.241 -20.89,-3.708 -26.415,-15.903c-5.525,-12.195 -4.281,-33.558 -7.252,-54.755c-2.972,-21.198 -7.843,-43.781 -7.843,-67.376c-0,-23.595 7.843,-35.427 26.179,-38.717Z" />
  </svg>
);

export const Pink = ({ className, ...props }: ISVG) => (
  <svg width="97" height="117" xmlns="http://www.w3.org/2000/svg" {...props} className={className}>
    <path
      d="M28.342,116.087c-16.665,-0 -24.241,-9.39 -25.755,-24.566c-1.515,-15.176 -4.546,-42.11 -0.758,-60.232c3.787,-18.121 11.363,-24.668 23.482,-28.817c12.12,-4.149 29.472,-1.459 45.45,8.194c15.979,9.653 25.31,19.425 25.31,31.293c-0,11.866 2.272,22.237 -2.6,34.386c-4.872,12.15 -6.16,14.153 -14.814,20.571c-8.653,6.417 -33.65,19.171 -50.316,19.171l0.001,-0Z"
      fill="#FFE8F5"
      fillRule="evenodd"
    />
  </svg>
);

export const Line = ({ className, ...props }: ISVG) => (
  <svg width="42" height="207" xmlns="http://www.w3.org/2000/svg" {...props} className={className}>
    <path
      d="M2.006,0.364c0.148,-0.027 0.298,-0.041 0.448,-0.041c1.344,-0 2.45,1.106 2.45,2.45c0,1.101 -0.743,2.074 -1.807,2.363l36.214,196.558c0.079,-0.007 0.158,-0.011 0.237,-0.011c1.344,-0 2.45,1.106 2.45,2.45c0,1.344 -1.106,2.45 -2.45,2.45c-1.344,-0 -2.449,-1.106 -2.449,-2.45c-0,-1.1 0.74,-2.071 1.8,-2.362l-36.215,-196.559c-0.079,0.008 -0.157,0.011 -0.236,0.011c-1.344,0 -2.45,-1.105 -2.45,-2.449c0,-1.178 0.849,-2.197 2.007,-2.41l0.001,0Z"
      fill="#FF95CF"
      fillRule="nonzero"
    />
  </svg>
);

export const Triangle = ({ className, ...props }: ISVG) => (
  <svg width="16" height="19" xmlns="http://www.w3.org/2000/svg" {...props} className={className}>
    <path d="M15.823,9.847l-15.649,9l-0,-18l15.649,9Z" fill="#ff95ce" />
  </svg>
);

interface IClassQueueSVG extends ISVG {
  alert?: boolean;
}

export const ClassQueueSVG = ({ className, alert, ...props }: IClassQueueSVG) => (
  <svg
    width="243"
    height="222"
    viewBox="0 0 243 222"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    className={className}
  >
    <path
      d="M214.214 34.4833C233.091 51.19 244.429 74.8196 242.675 96.2751C240.98 117.731 226.136 137.069 212.461 150.114C198.785 163.159 186.22 169.91 174.707 176.833C163.252 183.756 152.791 190.851 134.79 201.15C116.732 211.391 91.0755 224.779 68.283 221.461C45.4904 218.142 25.5615 198.06 23.516 176.032C21.4121 154.005 37.1331 130.032 43.2696 100.337C49.406 70.6429 45.8995 35.2271 60.8023 17.2617C75.7636 -0.703735 109.076 -1.16145 138.94 3.41572C168.862 8.05011 195.278 17.8338 214.214 34.4833Z"
      fill={!alert ? '#BFEBFF' : '#FFE8F5'}
      fillOpacity="0.7"
    />
    <circle
      cx="122.781"
      cy="142.843"
      r="67.1571"
      stroke={alert ? '#FF329B' : '#009EEB'}
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M122.781 98.7974V149.593L144.85 172.16"
      stroke={alert ? '#FF329B' : '#009EEB'}
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M38.8242 160.326H2.46191V23H118.388V62.5938"
      stroke={alert ? '#FF329B' : '#009EEB'}
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M95.9178 55.7784H24.7876"
      stroke={alert ? '#FF329B' : '#009EEB'}
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M24.7876 88.2206H58.8027"
      stroke={alert ? '#FF329B' : '#009EEB'}
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
