import { Moment } from 'moment-timezone';
import React, { useState, useEffect, useCallback } from 'react';

import Alert from 'assets/alert-offline.svg';
import { useNowUpdatedEverySecond } from 'utils/timer';

import { tips } from '../constants';
import { ScreenContainer, Text, TextHeader, AnimationContainer, TipsText } from '../styledComponents';
import '../animation.css';
import { Blue, Pink, Line, Triangle } from './Items';
import JoinedEarlyMessage from './joined-early-message.component';

const TIP_INTERVAL = 15000;

interface OfflineProps {
  pollFrequency: number;
}

const Offline = ({ pollFrequency }: OfflineProps) => (
  <>
    <Text>You are no longer connected to the classroom.</Text>
    <Text>Please check your internet connection.</Text>
    <AnimationContainer>
      <Alert />
    </AnimationContainer>
    {pollFrequency === 0 ? (
      <Text>Retrying connection...</Text>
    ) : (
      <Text>
        Retrying connection in <strong>{pollFrequency}</strong> seconds.
      </Text>
    )}
  </>
);

const TeachingTip = () => {
  const [tip, setTip] = useState<string>(tips[Math.floor(Math.random() * tips.length)]);
  const setNextTip = useCallback(() => {
    let i: number = Math.floor(Math.random() * tips.length);
    while (i === tips.indexOf(tip)) {
      i = Math.floor(Math.random() * tips.length);
    }
    setTip(tips[i]);
  }, [setTip, tip]);

  useEffect(() => {
    const timer = setTimeout(setNextTip, TIP_INTERVAL);
    return () => {
      clearTimeout(timer);
    };
  }, [tip, setNextTip]);
  return (
    <>
      <TextHeader>Teaching Tip</TextHeader>
      <TipsText>{tip}</TipsText>
    </>
  );
};

const Animation = () => (
  <AnimationContainer>
    <Blue className="blue-color-balance" />
    <Pink className="pink" />
    <Line className="line" />
    <Blue className="blue" />
    <Triangle className="triangle" />
  </AnimationContainer>
);

interface LobbyProps {
  bookingStart?: Moment;
}

const Loading = ({ bookingStart }: LobbyProps) => {
  const nowDate = useNowUpdatedEverySecond();
  const isJoinedEarly = nowDate && bookingStart && bookingStart.diff(nowDate) > 0;
  const [pollFrequency, setPollFrequency] = useState<number>(8);
  const [online, setOnline] = useState<boolean>(true);

  const checkConnection = () => {
    setOnline(window.navigator.onLine);
    setPollFrequency(8);
  };

  const checkCount = () => {
    if (pollFrequency === 0) {
      checkConnection();
    } else {
      setPollFrequency(pollFrequency - 1);
    }
  };

  useEffect(() => {
    window.addEventListener('offline', checkConnection);
    window.addEventListener('online', checkConnection);

    return () => {
      window.removeEventListener('offline', checkConnection);
      window.removeEventListener('online', checkConnection);
    };
  }, []);

  useEffect(() => {
    const timer = setTimeout(checkCount, 1e3);
    return () => {
      clearTimeout(timer);
    };
  }, [pollFrequency]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!online) {
    return (
      <ScreenContainer data-qa="lobby-loading">
        <Offline pollFrequency={pollFrequency} />
      </ScreenContainer>
    );
  }

  return (
    <ScreenContainer data-qa="lobby-loading">
      {isJoinedEarly ? (
        <>
          <JoinedEarlyMessage bookingStart={bookingStart} />
          <Animation />
          <TeachingTip />
        </>
      ) : (
        <>
          <Text>Waiting to enter the classroom.</Text>
          {pollFrequency === 0 ? (
            <Text>Checking connection...</Text>
          ) : (
            <Text>
              Checking connection in <strong>{pollFrequency}</strong> seconds.
            </Text>
          )}
          <Animation />
          <TeachingTip />
        </>
      )}
    </ScreenContainer>
  );
};

export default Loading;
