import { ApolloProvider } from '@apollo/client';
import { InteractionType } from '@azure/msal-browser';
import { AuthenticatedTemplate, MsalAuthenticationTemplate, MsalProvider } from '@azure/msal-react';
import { StyledEngineProvider } from '@mui/material';
import React from 'react';
import { Helmet } from 'react-helmet';
import { hot } from 'react-hot-loader/root'; // eslint-disable-line import/no-internal-modules
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import ErrorScreen from 'components/Error';
import ErrorBoundary from 'components/ErrorBoundary';
import { FeatureFlagProvider } from 'components/FeatureFlag/Provider';
import { Loading } from 'components/Loading';
import UnauthorizedUserScreen from 'components/Unauthorized';
import { GENERAL_ERROR_PATH, TF_CLASSROOM, UNAUTHORIZED_USER_PATH } from 'config/internalUrlPaths';
import { APP_NAMES } from 'config/pagesTitle';
import AppContext from 'context/App';
import { msalInstance } from 'context/Auth/AuthProvider';
import { ProvideTeacher, ProvideUser } from 'context/Profile';
import { ProvideSchool } from 'context/School/School';
import ThemeProvider from 'context/Theme';
import { apolloClient, useRefetchQueriesOnFocus } from 'network/graphql';
import { GlobalStyles } from 'theme/index';

import Routes from './Routes';
import Classroom from './views/Classroom';

const App = () => {
  useRefetchQueriesOnFocus();

  return (
    <>
      <Helmet>
        <title>{APP_NAMES.TF}</title>
      </Helmet>
      <AppContext.Provider value={{ app: 'teacherfirst' }}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider>
            <MsalProvider instance={msalInstance}>
              <MsalAuthenticationTemplate
                interactionType={InteractionType.Redirect}
                loadingComponent={() => <Loading />}
                errorComponent={({ error }) => <ErrorScreen error={error} />}
              >
                <AuthenticatedTemplate>
                  <GlobalStyles />
                  <ErrorBoundary>
                    <FeatureFlagProvider>
                      <Router>
                        <Switch>
                          <Route path={GENERAL_ERROR_PATH} exact component={ErrorScreen} />
                          <Route path={UNAUTHORIZED_USER_PATH} exact component={UnauthorizedUserScreen} />
                          <ApolloProvider client={apolloClient}>
                            <ProvideUser>
                              <ProvideSchool>
                                <ProvideTeacher>
                                  <Switch>
                                    <Route path={TF_CLASSROOM} component={Classroom} />
                                    <Routes />
                                  </Switch>
                                </ProvideTeacher>
                              </ProvideSchool>
                            </ProvideUser>
                          </ApolloProvider>
                        </Switch>
                      </Router>
                    </FeatureFlagProvider>
                  </ErrorBoundary>
                </AuthenticatedTemplate>
              </MsalAuthenticationTemplate>
            </MsalProvider>
          </ThemeProvider>
        </StyledEngineProvider>
      </AppContext.Provider>
    </>
  );
};

export default hot(App);
