import React from 'react';

import env from 'config/env';

import { Avatar, AvatarProps } from './avatar.component';

const AvatarWrapper = ({ profilePicture, ...props }: AvatarProps) => (
  <Avatar
    data-qa="avatar-wrapper"
    {...props}
    profilePicture={
      profilePicture
        ? profilePicture.includes('https://')
          ? profilePicture
          : `${env.MEDIA_URL}/cdn-cgi/image/width=100${profilePicture}`
        : undefined
    }
  />
);

export default AvatarWrapper;
