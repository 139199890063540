import React, { useRef } from 'react';

import { useOnClickOutside } from './useOnClickOutside';

interface Props {
  children: React.ReactNode;
  fn: (event?: MouseEvent) => void;
}

const OnClickOutside = ({ children, fn }: Props) => {
  const wrapper = useRef<HTMLElement>(null);

  useOnClickOutside({
    wrapperEl: wrapper,
    onClickOutside: fn,
  });

  const childElement = React.Children.only(children) as React.ReactElement;

  return childElement ? React.cloneElement(childElement, { ref: wrapper }) : null;
};
export default OnClickOutside;
