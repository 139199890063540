import styled from 'styled-components';

export const DrawerWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 100%;
  height: 100%;

  z-index: -100;
`;

export const BackgroundOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: transparent;
`;

export const Drawer = styled.div<{ active?: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 840px;
  height: 100%;
  z-index: 2;
  box-shadow: 8px 0px 32px rgb(0 0 0 / 16%);
  background: rgb(255, 255, 255, 0.95);

  /* if backdrop support: very transparent and blurred */
  @supports ((-webkit-backdrop-filter: blur(2em)) or (backdrop-filter: blur(2em))) {
    background-color: rgba(255, 255, 255, 0.7);
    -webkit-backdrop-filter: blur(2em);
    backdrop-filter: blur(2em);
  }

  &.slide-enter {
    transform: translateX(-840px);
  }
  &.slide-enter-active {
    transform: translateX(0);
    transition: transform 200ms;
  }
  &.slide-exit {
    transform: translateX(0);
    z-index: 1;
  }
  &.slide-exit-active {
    transform: translateX(-840px);
    transition: transform 200ms;
    z-index: 1;
  }
`;
