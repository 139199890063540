import moment, { Moment } from 'moment-timezone';
import React, { useMemo } from 'react';

import { useNowUpdatedEverySecond } from 'utils/timer';

import { Text } from '../styledComponents';

export const humanizeDuration = (bookingStart: Moment, nowDate: Date) =>
  moment.duration(bookingStart.diff(nowDate)).humanize(true, {
    ss: 0.1,
    s: 90,
    h: 24,
    m: 60,
  });

export const highlightNumbers = (text?: string) =>
  text?.split('').map((char) => (isNaN(Number(char)) ? <>{char}</> : <b key={char}>{char}</b>));

interface JoinedEarlyMessageProps {
  bookingStart?: Moment;
}

const JoinedEarlyMessage = ({ bookingStart }: JoinedEarlyMessageProps) => {
  const nowDate = useNowUpdatedEverySecond();

  const earlyDurationText = useMemo(() => {
    if (bookingStart) {
      return highlightNumbers(humanizeDuration(bookingStart, nowDate));
    }
  }, [bookingStart, nowDate]);

  return earlyDurationText ? (
    <>
      <Text>Classroom creation will start</Text>
      <Text>{earlyDurationText}.</Text>
    </>
  ) : null;
};

export default JoinedEarlyMessage;
