import { AcrMockHandler } from './acr';
import { handlers as classPrepHandlers } from './classPrep';
import { handlers as CommonHandlers } from './common';
import { handlers as DashboardHandlers } from './dashboard/full';
import { handlers as scheduledLessonHandlers } from './scheduledLessons';
import { StudentMockHandler } from './students';
import { TeacherMockHandler } from './teachers';

export const handlers = [
  ...AcrMockHandler,
  ...CommonHandlers,
  ...DashboardHandlers,
  ...scheduledLessonHandlers,
  ...classPrepHandlers,
  ...StudentMockHandler,
  ...TeacherMockHandler,
];
