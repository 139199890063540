import { gql } from '@apollo/client';

export const EVC_CLASSROOM_IDS_FOR_USER = gql`
  query EvcClassrooms($classroomsFilter: EvcClassroomFilter!, $participantsFilter: EvcParticipantsFilter!) {
    evcClassrooms(filter: $classroomsFilter) {
      edges {
        id
        participants(filter: $participantsFilter) {
          id
        }
      }
    }
  }
`;

export interface EvcClassroomInfos {
  evcClassrooms: {
    edges: [
      {
        id: string;
        participants: [{ id: string }];
      },
    ];
  };
}

export const EVC_CLASSROOM_JOIN_DETAILS = gql`
  query EvcClassroom($evcClassroomId: ID!, $participantId: ID!) {
    evcClassroom(id: $evcClassroomId) {
      location
    }
    evcClassroomParticipant(id: $evcClassroomId, participantId: $participantId) {
      attendanceToken
    }
  }
`;

export interface EvcClassroomJoinDetails {
  evcClassroom: {
    location: string;
    componentToken: string;
  };
  evcClassroomParticipant: {
    attendanceToken: string;
  };
}
