import lessonDefinitions from 'cypress/fixtures/teacherfirst/common/lessonDefinitions.json';
import student1 from 'cypress/fixtures/teacherfirst/common/student.json';
import student2 from 'cypress/fixtures/teacherfirst/common/student2.json';
import student3 from 'cypress/fixtures/teacherfirst/common/student3.json';
import profile from 'cypress/fixtures/teacherfirst/common/teacher.json';
import Teachers from 'cypress/fixtures/teacherfirst/common/teachers.json';
import teacher from 'cypress/fixtures/teacherfirst/common/user.json';
import { HttpResponse, graphql, http } from 'msw';

import env from 'config/env';

export const handlers = [
  http.get(`${env.TF_SVCGATEWAY_URL}/v1/users/me`, () => HttpResponse.json(teacher)),
  http.get(`${env.TF_SVCGATEWAY_URL}/v1/teachers/${teacher.id}`, () => HttpResponse.json(profile)),
  http.get(`${env.TF_SVCGATEWAY_URL}/v1/lesson-definitions`, () => HttpResponse.json(lessonDefinitions)),
  http.get(`${env.TF_SVCGATEWAY_URL}/v1/students/efpro/${student1.externalId}`, () => HttpResponse.json(student1)),
  http.get(`${env.TF_SVCGATEWAY_URL}/v1/students/efpro/${student2.externalId}`, () => HttpResponse.json(student2)),
  http.get(`${env.TF_SVCGATEWAY_URL}/v1/students/efpro/${student3.externalId}`, () => HttpResponse.json(student3)),

  graphql.query('Teachers', () => HttpResponse.json(Teachers)),
];
