import React from 'react';

import ErrorScreen from 'components/Error';
import { ddlog } from 'utils/miscellaneous';

interface IState {
  hasError: boolean;
}

class ErrorBoundary extends React.Component<unknown, IState> {
  public constructor(props: unknown) {
    super(props);
    this.state = { hasError: false };
  }

  public static getDerivedStateFromError(error: Error) {
    ddlog.warn('ErrorBoundary - uncaught error', { error });
    return { hasError: true };
  }

  public render() {
    if (this.state.hasError) {
      return <ErrorScreen data-qa="error-screen" />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
