import { IEnvironment } from 'config/env';

const environment: IEnvironment = {
  ENV: 'prod',
  SUPPORT_URL: 'https://ef-teachonline-helpcenter.force.com/s/',
  GQL_URL: 'https://api.ef.studio.ef.com.cn/gipfeli/',
  MEDIA_URL: 'https://teach-assets.ef.studio.ef.com.cn',
  TF_SVCGATEWAY_URL: 'https://api.ef.studio.ef.com.cn/teacher-first',
  DISCONNECT_AFTER: 3e5,
  ENTRY_STATUS_INTERVAL: 2e4, // 20 seconds
  AVAILABILITY_INTERVAL: 6e4, // 1 minute
  BOOKING_INTERVAL: 6e4, // 1 minute
  USER_INTERVAL: 6e5, // 10 minutes
  UNCLAIMED_LESSONS_INTERVAL: 6e4, // 1 minute
  ACR_TODO_INTERVAL: 3e5, // 5 minutes
  LESSON_DEFINITION_INTERVAL: 6e5, // 10 minutes
  EVC_TUNNELS: {
    US_NV: 'https://evc.ef.com',
    CN_SH: 'https://evc.ef.com.cn',
  },
  HELP_CENTER_URL: '',
  TECH_CHECK_URL_TEMPLATE:
    'https://evc.ef.com/evc15/meeting/tools/teachertechcheck?proxyInfo=&centerCode=UK#ret={0};s=304615050;m=35754986;cc=en;host=axis.eflabs.io;canskip=true',
  LOGIN: {
    SIGNIN_SIGNUP_USER_FLOW: 'B2C_1_Ops_Portal_Prod',
    RESET_USER_FLOW: 'B2C_1_Ops_Portal_Prod',
    AUTHORITY_BASE_URL: 'https://efideu.b2clogin.com/efideu.onmicrosoft.com/',
    TOKEN_SCOPE: 'https://efideu.onmicrosoft.com/teacher-first/tfapi',
    CLIENT_ID: '56a523f4-0fa3-44af-93b4-ffb9752ddc3d',
  },
  DATADOG: {
    APPLICATION_ID: '245a1708-51ca-43f6-9806-4589c62a5b4c',
    CLIENT_TOKEN: 'pub0a495fdb827a48e081f6944b66ce99fa',
    SERVICE_NAME: 'ops-portal-fe',
    VIRTUAL_ENV: 'prod',
  },
  API_KEYS: {},
};

export default environment;
