export const grid = 8;

export const stickyHeader = {
  height: 25 * grid,
  paddingTop: 11.5 * grid,
};

export const wrapper = {
  maxWidth: 144 * grid,
  paddingWidth: 8 * grid,
};

export const sidebar = {
  fullWidth: grid * 30,
  collapsedWidth: grid * 9,
};
