import useSWR from 'swr';

import { TeacherJoinStatus } from 'model/Lobby';
import makeRequest from 'network/helpers/makeRequest';
import { ddlog } from 'utils/miscellaneous';

interface IEvcEntry {
  webUrl: string;
}

export interface IEntryResponse {
  evc: IEvcEntry;
}

interface IClassroomRecordingURLResponse {
  url: string;
}

export const loadTeacherJoinDetails = (teacherId: string, bookingId: string) =>
  makeRequest<IEntryResponse>({
    method: 'POST',
    pathname: `teachers/${teacherId}/bookings/${bookingId}/group-lesson/join`,
  });

export interface IStudentGLJoin {
  id: string;
  name: string;
  level: string;
  acrType: string;
  contentId: string;
  lessonDefId: string;
  start: string;
}

export const loadStudentJoinDetails = (payload: IStudentGLJoin) =>
  makeRequest<IEntryResponse>({
    method: 'POST',
    pathname: `students/${payload.id}/group-lesson/join`,
    body: {
      student: {
        acrType: payload.acrType,
        level: payload.level,
        name: payload.name,
      },
      contentId: payload.contentId,
      lessonDefinitionId: payload.lessonDefId,
      start: payload.start,
    },
  });

export const getClassroomRecordingURL = (bookingId: string) =>
  makeRequest<IClassroomRecordingURLResponse>({
    method: 'GET',
    pathname: `bookings/${bookingId}/recording`,
  });

export const useClassroomRecordingURL = (bookingId: string, polling?: number, pause?: boolean) => {
  const cacheKey = bookingId ? `{type: 'classroom-recording-url', bookingId: ${bookingId}` : null;
  const { data, error, mutate } = useSWR<IClassroomRecordingURLResponse>(
    cacheKey,
    () => getClassroomRecordingURL(bookingId),
    {
      refreshInterval: polling || 0,
      isPaused: () => pause || false,
    },
  );

  return {
    classroomRecordingURL: data,
    isLoading: !error && !data,
    isError: error,
    refetch: mutate,
  };
};

const FIVE_SECONDS = 5 * 1000;

const fetchTeacherJoinDetails = async (bookingId: string, teacherJoinRequestId: string | null) => {
  if (teacherJoinRequestId) {
    return makeRequest<TeacherJoinStatus>({
      pathname: `group-lessons/teacher-join-requests/${teacherJoinRequestId}`,
    });
  }

  return new Promise<TeacherJoinStatus>((resolve, reject) => {
    makeRequest<TeacherJoinStatus>({
      method: 'POST',
      pathname: 'group-lessons/teacher-join-requests',
      body: {
        bookingId,
      },
    })
      .then((response) => resolve(response))
      .catch(async (error) => {
        if (error?.json) {
          const errorObj = await error.json();

          if (errorObj?.message === 'the session has not started yet') {
            resolve({
              bookingId,
              id: null,
              state: 'waiting',
            });
          }

          reject(errorObj);
          return;
        } else {
          ddlog.warn('Teacher join request error', error);
        }

        reject(error);
      });
  });
};

export const useTeacherJoinStatus = (bookingId: string, teacherJoinRequestId: string | null) =>
  useSWR(
    `group-lessons/teacher-join-requests/${teacherJoinRequestId ?? bookingId}`,
    () => fetchTeacherJoinDetails(bookingId, teacherJoinRequestId),
    {
      refreshInterval: FIVE_SECONDS,
      refreshWhenHidden: true,
    },
  );

export const useTeacherJoinRequest = (joinRequestId: string) =>
  useSWR(`group-lessons/teacher-join-requests?bookingId=${joinRequestId}`, () =>
    makeRequest<{ data: TeacherJoinStatus[] }>({
      pathname: 'group-lessons/teacher-join-requests',
      searchParams: {
        bookingId: joinRequestId,
      },
    }).then((response) => response.data),
  );
