import {
  AccountInfo,
  AuthenticationResult,
  IPublicClientApplication,
  Logger,
  SilentRequest,
  WrapperSKU,
} from '@azure/msal-browser';

const mockAccount = {
  name: 'Test User',
  username: 'test_user',
} as AccountInfo;

const MsalBrowserMock = {
  getLogger: (): Logger => new Logger({}),
  getAllAccounts: (): AccountInfo[] => [mockAccount],
  getActiveAccount: (): AccountInfo | null => mockAccount,
  setActiveAccount: (_account: AccountInfo | null): void => undefined,
  addEventCallback: (_: () => Function): string | null => null, // eslint-disable-line @typescript-eslint/ban-types
  // @ts-ignore to ignore unused variables
  initializeWrapperLibrary: (sku: WrapperSKU, version: string) => {}, // eslint-disable-line @typescript-eslint/no-empty-function
  initialize: (): Promise<void> => Promise.resolve(),
  handleRedirectPromise: (_: string): Promise<AuthenticationResult | null> => Promise.resolve(null),
  acquireTokenSilent: (_: SilentRequest): Promise<AuthenticationResult> =>
    Promise.resolve({ accessToken: 'test-access-token' } as AuthenticationResult),
} as IPublicClientApplication;

export default MsalBrowserMock;
