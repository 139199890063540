import moment, { Moment } from 'moment-timezone';
import useSWR from 'swr';

import {
  AxisBooking,
  Booking,
  BookingStatus,
  IAttendanceRecords,
  IBookingWithAcrInfo,
  IEvents,
  StudentBooking,
} from 'model/Booking';
import { IRange } from 'model/misc';
import { IStudentExternalID, Org } from 'model/Students';
import { getAcrByBookingIdBulk } from 'network/Acr';
import makeRequest from 'network/helpers/makeRequest';

export interface FetchBookingsArgs {
  start: Moment;
  end: Moment;
  status: BookingStatus[];
  org?: Org;
  lessons?: string[];
  teacherId?: string;
  studentId?: IStudentExternalID;
  limit?: number;
  order?: 'asc' | 'desc';
}

export const fetchBookings = (args: FetchBookingsArgs) =>
  makeRequest<{ data: Booking[] }>({
    pathname: '/bookings',
    searchParams: buildSearchParams(args),
  })
    .then((payload) => payload.data || [])
    .then(sortContents)
    .then(parseBookingDatesBulk);

const buildSearchParams = (args: FetchBookingsArgs) => ({
  start: args.start.toISOString(),
  end: args.end.toISOString(),
  ...(args.org && { org: args.org }),
  ...(args.teacherId && { teacherId: args.teacherId }),
  ...(args.studentId && { studentId: args.studentId.externalId }),
  ...(args.status?.length && { status: args.status.join() }),
  ...(args.lessons?.length && { lessonDefinitionId: args.lessons.join() }),
  ...(args.limit && { limit: args.limit }),
  ...(args.order && { order: args.order }),
});

export const useBookings = (teacherId: string, start: Moment, end: Moment, polling?: number, pause?: boolean) => {
  const cacheKey = teacherId
    ? `{type: 'booking', teacherId: ${teacherId}, start: ${start.format()}, end: ${end.format()}}`
    : null;
  const { data, error, mutate } = useSWR<Booking[]>(
    cacheKey,
    () =>
      fetchBookings({
        teacherId,
        start,
        end,
        status: [BookingStatus.confirmed, BookingStatus.started, BookingStatus.completed],
        limit: 1000,
      }),
    {
      refreshInterval: polling || 0,
      isPaused: () => pause || false,
    },
  );

  return {
    bookings: data,
    isLoading: !error && !data,
    isError: error,
    refetch: mutate,
  };
};

export const useBookingsWithAcr = (
  teacherId: string,
  start: Moment,
  end: Moment,
  polling?: number,
  pause?: boolean,
) => {
  const cacheKey = teacherId
    ? `{type: 'bookingWithAcr', teacherId: ${teacherId}, start: ${start.format()}, end: ${end.format()}}`
    : null;
  const { data, error, mutate } = useSWR<IBookingWithAcrInfo[]>(
    cacheKey,
    () =>
      fetchBookings({
        teacherId,
        start,
        end,
        status: [BookingStatus.confirmed, BookingStatus.started, BookingStatus.completed],
        limit: 1000,
      }).then((bookings) =>
        getAcrByBookingIdBulk(
          teacherId,
          bookings.map((bk) => bk.id),
        ).then((acrs) =>
          bookings.map((bk) => {
            const bkWithAcrInfo: IBookingWithAcrInfo = {
              ...bk,
              acrSubmitted: acrs.find((acr) => acr.bookingId === bk.id)?.submitted ?? true,
            };
            return bkWithAcrInfo;
          }),
        ),
      ),
    {
      refreshInterval: polling || 0,
      isPaused: () => pause || false,
    },
  );

  return {
    bookings: data,
    isLoading: !error && !data,
    isError: error,
    refetch: mutate,
  };
};

export const fetchAxisBookings = (teacherId: string, start: Moment, end: Moment) =>
  makeRequest<{ data: AxisBooking[] }>({
    pathname: `teachers/${teacherId}/axis-bookings`,
    searchParams: {
      start: start.toISOString(),
      end: end.toISOString(),
    },
  })
    .then((payload) => payload.data || [])
    .then(parseAxisBookingDatesBulk);

export const useAxisBookings = (teacherId: string, start: Moment, end: Moment, polling?: number, pause?: boolean) => {
  const cacheKey = teacherId
    ? `{type: 'axis-booking', teacherId: ${teacherId}, start: ${start.format()}, end: ${end.format()}}`
    : null;
  const { data, error, mutate } = useSWR<AxisBooking[]>(cacheKey, () => fetchAxisBookings(teacherId, start, end), {
    refreshInterval: polling || 0,
    isPaused: () => pause || false,
  });

  return {
    axisBookings: data,
    isLoading: !error && !data,
    isError: error,
    refetch: mutate,
  };
};

export const fetchAxisBookingsByTeacherList = (teacherIds: string[], start: Moment, end: Moment) =>
  makeRequest<{ data: AxisBooking[] }>({
    pathname: 'axis-bookings',
    searchParams: {
      teacherId: teacherIds.toString(),
      start: start.toISOString(),
      end: end.toISOString(),
    },
  })
    .then((payload) => payload.data || [])
    .then(parseAxisBookingDatesBulk);

export const fetchBookingById = (bookingId: string) =>
  makeRequest<Booking>({
    pathname: `bookings/${bookingId}`,
  })
    .then(sortContentOfBooking)
    .then(parseBookingDates);

export const useFetchBookingById = (bookingId: string) => {
  const cacheKey = bookingId ? `bookings/${bookingId}}` : null;
  const { data, error, mutate } = useSWR<Booking>(cacheKey, () => fetchBookingById(bookingId));

  return {
    booking: data,
    isLoading: !error && !data,
    isError: error,
    refetch: mutate,
  };
};

export const adminFetchBookingById = (bookingId: string) =>
  makeRequest<Booking>({
    pathname: `bookings/${bookingId}`,
  }).then(parseBookingDates);

export const useAdminFetchBookingById = (bookingId: string) => {
  const cacheKey = bookingId ? `bookings/${bookingId}(admin)` : null;
  const { data, error, mutate } = useSWR(cacheKey, () => adminFetchBookingById(bookingId));

  return {
    booking: data,
    loading: !error && !data,
    isError: error,
    refetch: mutate,
  };
};

export const fetchBookingsByIdBulk = (teacherId: string, bookingIds: string[]) =>
  makeRequest<{ data: Booking[] }>({
    pathname: '/bookings',
    searchParams: {
      teacherId,
      bookingId: bookingIds.join(','),
      limit: bookingIds.length,
    },
  })
    .then((payload) => payload.data || [])
    .then(sortContents)
    .then(parseBookingDatesBulk);

export const fetchBookingBulkByTeacherList = (teachers: string[], start: Moment, end: Moment) =>
  makeRequest<{ data: Booking[] }>({
    pathname: 'bookings',
    searchParams: {
      teacherId: teachers.join(','),
      start: start.toISOString(),
      end: end.toISOString(),
      status: [BookingStatus.confirmed, BookingStatus.started, BookingStatus.completed].join(','),
      limit: 1000,
    },
  })
    .then((result) => result.data.map(sortContentOfBooking))
    .then(parseBookingDatesBulk);

const sortContents = (bookings: Booking[]) => {
  bookings.forEach(sortContentOfBooking);
  return bookings;
};

const sortContentOfBooking = (booking: Booking) => {
  if (booking.content?.evc) {
    booking.content.evc.items.sort((a, b) => +a.id - +b.id);
  }

  return booking;
};

const parseBookingDatesBulk = (bookings: Booking[]) => {
  bookings.forEach(parseBookingDates);
  return bookings;
};

const parseAxisBookingDatesBulk = (bookings: AxisBooking[]) => {
  bookings.forEach(parseBookingDates);
  return bookings;
};

const parseBookingDates = <T extends IRange>(booking: T): T => {
  booking.start = moment(booking.start, 'YYYY-MM-DDTHH:mm:ssZ');
  booking.end = moment(booking.end, 'YYYY-MM-DDTHH:mm:ssZ');
  return booking;
};

const adminFetchBookings = ({
  start,
  end,
  page,
  limit,
  sort,
  teacherId,
  status,
  lessons,
  studentId,
}: {
  start: Moment;
  end: Moment;
  page?: number;
  limit?: string;
  sort?: string;
  teacherId?: string;
  status?: string;
  lessons?: string[];
  studentId?: string;
}) => {
  const parameters: Record<string, string | number> = {
    start: start.toISOString(),
    end: end.toISOString(),
    status: status || [BookingStatus.confirmed, BookingStatus.started, BookingStatus.completed].join(','),
    page: page || '1',
    limit: limit || '100',
    order: sort || 'asc',
  };

  if (teacherId) {
    parameters.teacherId = teacherId;
  }

  if (lessons && lessons.length > 0) {
    parameters.lessonDefinitionId = lessons.toString();
  }

  if (studentId) {
    parameters.studentId = studentId;
  }

  return makeRequest<{ data: Booking[] }>({
    pathname: '/bookings',
    searchParams: parameters,
  })
    .then((payload) => payload.data || [])
    .then(sortContents)
    .then(parseBookingDatesBulk);
};

export const useAdminFetchBookings = ({
  start,
  end,
  page,
  limit,
  sort,
  teacherId,
  status,
  lessons,
  studentId,
  polling,
}: {
  start: Moment;
  end: Moment;
  page?: number;
  limit?: string;
  sort?: string;
  teacherId?: string;
  status?: string;
  lessons?: string[];
  studentId?: string;
  polling?: number;
}) => {
  const cacheKey = teacherId
    ? `{type: 'adminFetchBookings', teacherId: ${teacherId}, start: ${start?.format()}, end: ${end?.format()}}, page: ${page}, limit: ${limit}, sort: ${sort}, status: ${status}, studentId: ${studentId}}`
    : null;
  const { data, error, mutate } = useSWR<Booking[]>(
    cacheKey,
    () => adminFetchBookings({ start, end, page, limit, sort, teacherId, status, lessons, studentId }),
    {
      refreshInterval: polling || 0,
    },
  );

  return {
    bookings: data,
    isLoading: !error && !data,
    isError: error,
    refetch: mutate,
  };
};

export const cancelBookingByBookingId = (bookingId: string, reason: string) =>
  makeRequest({
    pathname: `/bookings/${bookingId}/cancel`,
    method: 'POST',
    body: {
      reason,
    },
  });

export const adminGetBookingAttendance = (bookingId: string) =>
  makeRequest<{ data: IAttendanceRecords[] }>({
    pathname: `/bookings/${bookingId}/attendance`,
  }).then((payload) => payload.data || []);

export const useBookingAttendance = (bookingId: string) => {
  const cacheKey = bookingId ? `/bookings/${bookingId}/attendance` : null;
  const { data } = useSWR<IAttendanceRecords[]>(cacheKey, () => adminGetBookingAttendance(bookingId));

  return {
    attendance: data,
  };
};

export const adminGetBookingHistory = (bookingId: string) =>
  makeRequest<{ data: IEvents[] }>({
    pathname: `/bookings/${bookingId}/history`,
  })
    .then((payload) => payload.data || [])
    .then((res) => res.reverse());

export const useBookingHistory = (bookingId: string) => {
  const cacheKey = bookingId ? `/bookings/${bookingId}/history` : null;
  const { data } = useSWR<IEvents[]>(cacheKey, () => adminGetBookingHistory(bookingId));

  return { events: data };
};

export const patchBookingTitle = (bookingId: string, title: string) =>
  makeRequest<void>({
    method: 'POST',
    pathname: `/bookings/${bookingId}/content`,
    body: {
      custom: title,
    },
  });

export interface FetchStudentBookingsArgs {
  start: Moment;
  end: Moment;
  studentId: IStudentExternalID;
  org: Org;
  lessons?: string[];
  limit?: number;
}

export const fetchStudentBookings = (args: FetchStudentBookingsArgs) =>
  makeRequest<{ data: StudentBooking[] }>({
    pathname: '/student-bookings',
    searchParams: buildStudentBookingSearchParams(args),
  })
    .then((payload) => payload.data || [])
    .then((bookings) => {
      bookings.forEach((booking) => {
        if (booking.content?.evc) {
          booking.content.evc.items.sort((a, b) => +a.id - +b.id);
        }
        booking.start = moment(booking.start, 'YYYY-MM-DDTHH:mm:ssZ');
        booking.studentId = args.studentId;
      });

      return bookings;
    });

const buildStudentBookingSearchParams = (args: FetchStudentBookingsArgs) => ({
  studentId: args.studentId.externalId,
  org: args.org,
  start: args.start.toISOString(),
  end: args.end.toISOString(),
  ...(args.limit && { limit: args.limit }),
  ...(args.lessons?.length && { lessonDefinitionId: args.lessons.join() }),
  status: ['confirmed', 'cancelled'].join(),
});
