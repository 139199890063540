import { v4 as uuidv4 } from 'uuid';

import { IMakeRequestArgs } from 'network/helpers/config';
import { NetworkError, ddlog } from 'utils/miscellaneous';
import { tryWithNRetries } from 'utils/promise';

import { addAuthorizationHeader, partitionAndConvertParams } from './parameterProcessors';

const retryCallCondition = ({ status }: Response) => status === 401 || (status >= 500 && status < 600);

// args contain thing to be used to produce url, initObject for fetch and for preprocessing of data
const correlationKey = 'X-Request-ID';
const makeRequest = <ResponseType>(args: IMakeRequestArgs): Promise<ResponseType> => {
  const { url, initObject } = partitionAndConvertParams(args);
  const correlationId = uuidv4();
  initObject.headers = {
    ...initObject.headers,
    [correlationKey]: correlationId,
  };
  const call = () => addAuthorizationHeader(initObject).then((arg) => actualRequest(url, arg));

  return tryWithNRetries(call, 1, retryCallCondition)
    .then(async (resp) => {
      if (resp.status === 401) {
        const isJson = resp.headers.get('content-type')?.includes('application/json');
        const response = isJson ? await resp.json() : resp;
        const unAuthError: NetworkError = {
          ...new Error('Unauthorised'),
          response,
        };

        ddlog.error('service responded with 401', { response: resp });

        throw unAuthError;
      }
      return resp;
    })
    .then(async (resp: Response) => {
      if (!resp.ok) {
        return Promise.reject(resp);
      }
      const isJson = resp.headers.get('content-type')?.includes('application/json');
      if (!isJson || resp.status === 204) {
        return {} as ResponseType;
      }
      return (await resp.json()) as ResponseType;
    })
    .catch((err) => {
      ddlog.error(`request failed: ${typeof err === 'object' ? JSON.stringify(err) : err.toString()}`, {
        error: { stack: err?.stack },
        url,
        [correlationKey]: correlationId,
        statusCode: err?.statusCode,
        errorPayload: err,
      });
      throw err;
    });
};

const actualRequest = (url: string, initObject: unknown): Promise<Response> =>
  fetch(url, initObject as RequestInit).catch((error) => {
    ddlog.error('Caught error in actualRequest', { error, initObject });
    throw error;
  });

export default makeRequest;
