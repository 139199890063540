import { useEffect, useState } from 'react';

const os = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;

const isMobile = () => window.innerWidth <= 500 || os.test(navigator.userAgent);

const useWindowSize = () => {
  const [width, setWidth] = useState<number>(window.innerWidth);
  const [height, setHeight] = useState<number>(window.innerWidth);
  const [mobile, setMobile] = useState<boolean>(isMobile());

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
      setMobile(isMobile());
    };

    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return {
    width,
    height,
    mobile,
    orientation: width < height ? ('portrait' as const) : ('landscape' as const),
  };
};

export default useWindowSize;
