import Departments from 'cypress/fixtures/departments.json';
import LessonPackages from 'cypress/fixtures/lesson-packages/lesson-packages.json';
import Teachers from 'cypress/fixtures/teacherfirst/common/teachers.json';
import { HttpResponse, graphql } from 'msw';

export const handlers = [
  graphql.query('LessonPackages', () => HttpResponse.json(LessonPackages)),
  graphql.query('Departments', () => HttpResponse.json(Departments)),
  graphql.query('Teachers', () => HttpResponse.json(Teachers)),
];
