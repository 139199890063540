import { Checkbox, FormControlLabel } from '@mui/material';
import React from 'react';

import { useReservedTimeslotsToggle } from 'network/Settings';
import Events from 'utils/analytics/events';
import { logEvent } from 'utils/analytics/helpers';

interface ReserveTimeSlotsToggleProps {
  teacherId?: string;
  className?: string;
}

const ReserveTimeSlotsToggle: React.FC<ReserveTimeSlotsToggleProps> = ({ teacherId, className }) => {
  const { updateReservedTimeslots, isUpdatingReservedTimeslot, isReservedTimeslots } =
    useReservedTimeslotsToggle(teacherId);

  return (
    <FormControlLabel
      control={
        <Checkbox
          className={className}
          color="primary"
          data-qa="reserve-timeslot-toggle"
          disabled={isUpdatingReservedTimeslot}
          checked={isReservedTimeslots}
          onChange={(e) => {
            updateReservedTimeslots(e.target.checked);
            logEvent(Events.CALENDAR_RESERVE_TIMESLOT_XHR, { enabled: e.target.checked });
          }}
        />
      }
      label="I'm open to any lesson starting within 48 hours. Reserved slots within this timeframe are ignored."
    />
  );
};

export default ReserveTimeSlotsToggle;
