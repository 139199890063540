import { ApolloClient, InMemoryCache, from, createHttpLink, TypePolicy, ObservableQuery } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { GQL_URL } from 'config/env';
import { getAccessToken } from 'context/Auth/AuthProvider';
import { LessonPackagesResponse } from 'model/LessonPackage';
import { ddlog } from 'utils/miscellaneous';
import { isCypress } from 'utils/testing';

const httpLink = createHttpLink({
  uri: GQL_URL,
});

const authLink = setContext(async (_, { headers }) => {
  const token = await getAccessToken();
  if (!token) {
    ddlog.error('getAccessToken returned empty access token');
  }
  return {
    headers: {
      ...headers,
      authorization: `Bearer ${token}`,
      'x-request-id': uuidv4().toString(),
    },
  };
});

const mergeFn: TypePolicy['merge'] = (existing, incoming, {}) => {
  if (!existing) {
    return incoming;
  }
  return {
    edges: [...existing.edges, ...incoming.edges],
    pageInfo: incoming.pageInfo,
    __typename: existing.typename,
  };
};

export const apolloClient = new ApolloClient({
  link: from([authLink, httpLink]),
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          announcements: {
            keyArgs: ['filter', ['departmentIds']],
            merge: mergeFn,
          },
          blockedSlots: {
            keyArgs: ['filter', ['schoolId']],
            merge: mergeFn,
          },
          lessonPackages: {
            keyArgs: ['filter', ['lessonDefinitionIds', 'startDate', 'endDate', 'teacherState', 'query', 'state']],
            merge: mergeFn,
          },
        },
      },
    },
  }),
});

const shouldIgnoreQuery = (observableQuery: ObservableQuery, result: unknown) => {
  if (observableQuery.queryName === 'LessonPackages') {
    const pageLimit = observableQuery.options.variables?.filter?.limit;
    const lessonPackagesResult = result as LessonPackagesResponse;
    const numberOfResults = lessonPackagesResult?.lessonPackages?.edges?.length;

    if (numberOfResults > pageLimit) {
      return true;
    }
  }
  return false;
};

export const useRefetchQueriesOnFocus = () => {
  useEffect(() => {
    const registerWindowFocused = () => {
      if (!isCypress()) {
        apolloClient.refetchQueries({
          include: 'active',
          onQueryUpdated: (observableQuery, { result }) => !shouldIgnoreQuery(observableQuery, result),
        });
      }
    };

    window.addEventListener('focus', registerWindowFocused);

    return () => {
      window.removeEventListener('focus', registerWindowFocused);
    };
  });
};
