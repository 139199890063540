import moment from 'moment-timezone';
import useSWR from 'swr';

import { Acr, IEvcNotes } from 'model/Acr';
import makeRequest from 'network/helpers/makeRequest';
import { isValidUUID } from 'utils/miscellaneous';
import safeJSONParse from 'utils/safeJSONParse';

interface ITeacherNotesResponse {
  note: {
    evc: string;
  };
}

const getTeacherNotesUrl = (bookingId: string) => `bookings/${bookingId}/class-note`;

export const fetchTeacherNotes = (bookingId: string) =>
  makeRequest<ITeacherNotesResponse>({
    pathname: getTeacherNotesUrl(bookingId),
  })
    .then((resp) => resp && safeJSONParse<IEvcNotes>(resp.note.evc))
    .catch((err) => {
      if (err.status === 404) {
        return undefined;
      }
      throw err;
    });

export const useTeacherNotes = (bookingId: string) =>
  useSWR(getTeacherNotesUrl(bookingId), () => fetchTeacherNotes(bookingId));

const parseAcr = (acr: Acr) => {
  if (acr && acr.submitBefore) {
    acr.submitBefore = moment(acr.submitBefore, 'YYYY-MM-DDTHH:mm:ssZ');
  }
  return acr;
};

export const getAcrById = (acrId: string) =>
  makeRequest<Acr>({
    pathname: `acrs/${acrId}`,
  }).then(parseAcr);

export const useFetchAcrById = (acrId: string) => {
  const cacheKey = acrId ? `{type: 'acrById', acrId: ${acrId}}` : null;
  const { data, error, mutate } = useSWR<Acr>(cacheKey, () => getAcrById(acrId));

  return {
    acr: data,
    isLoading: !error && !data,
    isError: error,
    refetch: mutate,
  };
};

export const saveAcrDraft = (acr: Acr) =>
  makeRequest<Acr>({
    method: 'POST',
    pathname: `acrs/${acr.id}/save-draft`, // TODELETE check
    body: { ...acr },
  }).then(parseAcr);

export const submitAcr = (acr: Acr) =>
  makeRequest<Acr>({
    method: 'POST',
    pathname: `acrs/${acr.id}/submit`,
    body: { ...acr },
  }).then(parseAcr);

export const getAcrByBookingId = (bookingId: string) =>
  makeRequest<{ data: Acr[] | undefined }>({
    pathname: 'acrs',
    searchParams: { bookingId },
  })
    .then((payload) => payload.data || [])
    .then((res) => parseAcr(res[0]));

export const useAcrById = (bookingId: string) => {
  const cacheKey = bookingId && isValidUUID(bookingId) ? `{type: 'AcrById', bookingId: ${bookingId}` : null;
  const { data, error, mutate } = useSWR<Acr>(cacheKey, () => getAcrByBookingId(bookingId));

  return {
    acr: data,
    isLoading: !error && !data,
    isError: error,
    refetch: mutate,
  };
};

export const getAcrByBookingIdBulk = (teacherId: string, bookingIds: string[]) =>
  bookingIds.length > 0
    ? makeRequest<{ data: Acr[] | undefined }>({
        pathname: 'acrs',
        searchParams: { teacherId, bookingId: bookingIds.join(','), limit: bookingIds.length },
      })
        .then((payload) => payload.data || [])
        .then((res) => res.map((acr) => parseAcr(acr)))
    : Promise.resolve([]);
