import {
  PublicClientApplication,
  EventMessage,
  EventType,
  AuthenticationResult,
  InteractionRequiredAuthError,
  ClientAuthError,
  ClientAuthErrorCodes,
  BrowserAuthErrorCodes,
} from '@azure/msal-browser';

import env from 'config/env';
import { msalConfigSignin } from 'context/Auth/constants';
import { ddlog } from 'utils/miscellaneous';
import { isCypress } from 'utils/testing';
import MsalBrowserMock from 'utils/testing/msalBrowserMock';

// Following the typescript sample auth as described in link 👇
// https://github.com/AzureAD/microsoft-authentication-library-for-js/tree/dev/samples/msal-react-samples/typescript-sample

// errors reference - https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/errors.md

const isTesting = isCypress() || process.env.JEST_WORKER_ID !== undefined;

export const msalInstance = isTesting ? MsalBrowserMock : new PublicClientApplication(msalConfigSignin);

msalInstance.initialize().then(() => {
  msalInstance.handleRedirectPromise().then(() => {
    const accounts = msalInstance.getAllAccounts();
    if (accounts.length > 0) {
      msalInstance.setActiveAccount(accounts[0]);
    }

    msalInstance.addEventCallback((event: EventMessage) => {
      if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
        const payload = event.payload as AuthenticationResult;
        const account = payload.account;
        msalInstance.setActiveAccount(account);
      }
    });
  });
});

const isInteractionRequiredError = (error: Error) => error instanceof InteractionRequiredAuthError;

const isTokenRefreshRequiredError = (error: Error) =>
  (error as ClientAuthError)?.errorCode === ClientAuthErrorCodes.tokenRefreshRequired;

const isPostRequestFailedError = (error: Error) =>
  (error as ClientAuthError)?.errorCode === BrowserAuthErrorCodes.postRequestFailed;

const isEndpointResolutionError = (error: Error) =>
  (error as ClientAuthError)?.errorCode === ClientAuthErrorCodes.endpointResolutionError;

const isInvalidGrant = (error: Error) =>
  (error as ClientAuthError)?.errorCode === BrowserAuthErrorCodes.monitorWindowTimeout;
const isMultipleMatchingTokens = (error: Error) =>
  (error as ClientAuthError)?.errorCode === ClientAuthErrorCodes.multipleMatchingTokens;

export const getAccessToken = async (): Promise<string | void> => {
  const account = msalInstance.getActiveAccount();
  if (!account) {
    ddlog.error('No active account found. Session expired or logged out from a different tab.');

    // redirect to login
    return msalInstance.acquireTokenRedirect({
      scopes: [env.LOGIN.TOKEN_SCOPE],
      redirectUri: `${window.location.origin}/`,
    });
  }

  const response = await msalInstance
    .acquireTokenSilent({
      scopes: [env.LOGIN.TOKEN_SCOPE],
      account,
      redirectUri: `${window.location.origin}/assets/blank.html`,
    })
    .catch((error) => {
      if (
        isInteractionRequiredError(error) ||
        isTokenRefreshRequiredError(error) ||
        isInvalidGrant(error) ||
        isPostRequestFailedError(error) ||
        isEndpointResolutionError(error)
      ) {
        // fallback to interaction when silent call fails
        return msalInstance.acquireTokenRedirect({
          scopes: [env.LOGIN.TOKEN_SCOPE],
          account,
          redirectUri: `${window.location.origin}/`,
        });
      } else if (isMultipleMatchingTokens(error)) {
        msalInstance.logoutRedirect();
      } else {
        ddlog.error('Unable to acquire silent token', { error });
      }
    });

  return response?.accessToken;
};
