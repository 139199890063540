import React, { CSSProperties, useState } from 'react';

import Icon from 'components/Icon';
import { copyToClipboard } from 'utils/miscellaneous';

import { CopiedIconWrapper, IdWrapper } from './styledComponents';

interface Props {
  id: string;
  children?: React.ReactNode;
  className?: string;
  style?: CSSProperties;
}

const ClickToCopyID = ({ id, children, className = '', style }: Props) => {
  const [idCopied, setIdCopied] = useState(false);

  return (
    <div style={{ position: 'relative', width: '100%' }}>
      <IdWrapper
        className={className}
        style={style}
        onClick={() => {
          setIdCopied(true);
          setTimeout(() => setIdCopied(false), 2000);
          copyToClipboard(id);
        }}
      >
        {children || id}

        {idCopied && (
          <CopiedIconWrapper>
            <Icon icon="tick" size={9} />
          </CopiedIconWrapper>
        )}
      </IdWrapper>
    </div>
  );
};

export default ClickToCopyID;
