import { useQuery } from '@apollo/client';
import { useMsal } from '@azure/msal-react';
import React, { useCallback, useEffect, useState, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';

import OpenMenu from 'assets/appswitcher/open-menu.svg';
import Logo from 'assets/TF_Logo_White.svg'; // eslint-disable-line
import SmallLogo from 'assets/TF_Logo_White_Small.svg'; // eslint-disable-line
import Avatar from 'components/Avatar';
import Icon from 'components/Icon';
import Loading from 'components/Loading';
import { Ellipsis } from 'components/sharedStyles';
import env from 'config/env';
import {
  TF_LESSON_HISTORY_OVERVIEW,
  TF_DASHBOARD_ROUTE,
  TF_CALENDAR_ROUTE,
  HOME_PATH,
  TF_STUDENT_GROUPS_PATH,
} from 'config/internalUrlPaths';
import { useTeacherContext, useUserContext } from 'context/Profile';
import { ITeacher, ITeacherProfile, IUserRoleType } from 'model/profiles';
import { IGQLTeachersResponse, TEACHER_DEPARTMENT_SCHOOL_GQL } from 'network/graphql/Teachers';
import Events from 'utils/analytics/events';
import { logEvent } from 'utils/analytics/helpers';
import { useTeacherPermissions } from 'utils/hooks/usePermissions';
import useWindowSize from 'utils/hooks/useWindowSize';
import { getTeacherFullName } from 'utils/miscellaneous';
import { AppSwitcher } from 'utils/window';

import ProfileSelect from './components/ProfileSelect';
import SideBarDrawer from './components/SideBarDrawer';
import { DrawerTypes } from './components/SideBarDrawer/DrawerTypes';
import { SideMenuItem, SideMenuTargetBlankItem, SideBarFade } from './helpers';
import {
  BottomMenuContainer,
  HorizontalSeparator,
  LinkLabel,
  MainMenuContainer,
  SideBarSticky,
  SideBarStickyBackup,
  SideBarWrapper,
  LogoWrapper,
  ProfileAvatar,
  AppSwitcherChevron,
  ProfileDetailsWrapper,
  TeacherSelectWrapper,
} from './styledComponents';

const Sidebar = () => {
  const { instance: msalInstance } = useMsal();
  const { user } = useUserContext();
  const { selectedTeacher: teacher } = useTeacherContext();
  const location = useLocation();

  const { data } = useQuery<IGQLTeachersResponse<ITeacherProfile>>(TEACHER_DEPARTMENT_SCHOOL_GQL, {
    variables: {
      filter: {
        userId: user.id,
      },
      pagination: { size: 1000, num: 1 },
    },
  });

  const teacherProfiles = data?.teachers.edges || [];
  const school = teacherProfiles.find((profile) => profile.id === teacher.id)?.department.school.name;
  const department = teacherProfiles.find((profile) => profile.id === teacher.id)?.department.name;

  const { width, mobile } = useWindowSize();
  const shouldBeLarge = useCallback((pathname: string) => pathname === TF_DASHBOARD_ROUTE && width > 1370, [width]);

  const [isLarge, setIsLarge] = useState(shouldBeLarge(location.pathname));
  const [didMount, setDidMount] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState<boolean>(false);
  const [profileSelect, setProfileSelect] = useState<boolean>(false);

  const [drawer, setDrawer] = useState<DrawerTypes | null>(null);
  const closeDrawer = () => {
    setIsLarge(false);
    setDrawer(null);
  };
  const sidebarRef = useRef<HTMLDivElement>(null);
  const { hasRole } = useTeacherPermissions();

  useEffect(() => {
    setIsLarge(shouldBeLarge(location.pathname));
    setDidMount(true);
  }, [width, shouldBeLarge, location.pathname]);

  const expandedSideBarRoute = shouldBeLarge(location.pathname);
  const sideBarOverlap = isLarge && !expandedSideBarRoute;
  const expanded = expandedSideBarRoute || isLarge || drawer !== null;

  const headerTitle = () => {
    const path = window.location.pathname.split('/')[1].replace('-', ' ');
    if (path) {
      return `${path[0].toUpperCase()}${path.substring(1)}`;
    }

    return '';
  };

  if (mobile) {
    return (
      <div
        style={{
          minHeight: 64,
          height: 64,
          width: '100%',
          backgroundColor: '#003c64',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: 20,
        }}
      >
        <Link to={HOME_PATH}>
          <div>
            <SmallLogo />
          </div>
        </Link>
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            height: 64,
            width: '100%',
            color: '#fff',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            pointerEvents: 'none',
          }}
        >
          {headerTitle()}
        </div>
        <div onClick={() => setMobileMenuOpen(true)}>
          <Icon icon="menu" color="#fff" />
        </div>
        {mobileMenuOpen && (
          <div
            style={{
              height: `${window.innerHeight}px`,
              width: '100vw',
              position: 'absolute',
              top: 0,
              left: 0,
              backgroundColor: '#003c64',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              zIndex: 99999,
            }}
          >
            <div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  padding: 20,
                  height: 64,
                }}
              >
                <Link to={HOME_PATH}>
                  <div>
                    <Logo />
                  </div>
                </Link>
                <div onClick={() => setMobileMenuOpen(false)}>
                  <Icon icon="close" color="#fff" />
                </div>
              </div>
              <div style={{ height: 1, backgroundColor: '#fffefe', opacity: 0.2, margin: '0 20px 34px' }} />
              <Link
                to={TF_DASHBOARD_ROUTE}
                style={{
                  height: 62,
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  padding: '0 25px',
                  backgroundColor: window.location.pathname.startsWith(TF_DASHBOARD_ROUTE)
                    ? 'rgb(63, 98, 128)'
                    : 'transparent',
                }}
                onClick={() => setMobileMenuOpen(false)}
              >
                <Icon icon="dashboard" color="#fff" />
                <LinkLabel
                  style={{
                    color: '#fff',
                    fontSize: 12,
                    textTransform: 'uppercase',
                    letterSpacing: '1.6px',
                    fontWeight: 450,
                  }}
                >
                  Dashboard
                </LinkLabel>
              </Link>
              <Link
                to={TF_CALENDAR_ROUTE}
                style={{
                  height: 62,
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  padding: '0 25px',
                  backgroundColor: window.location.pathname.startsWith(TF_CALENDAR_ROUTE)
                    ? 'rgb(63, 98, 128)'
                    : 'transparent',
                }}
                onClick={() => setMobileMenuOpen(false)}
              >
                <Icon icon="calendar" color="#fff" />
                <LinkLabel
                  style={{
                    color: '#fff',
                    fontSize: 12,
                    textTransform: 'uppercase',
                    letterSpacing: '1.6px',
                    fontWeight: 450,
                  }}
                >
                  Calendar
                </LinkLabel>
              </Link>
            </div>
            <div>
              <div style={{ height: 1, backgroundColor: '#fffefe', opacity: 0.2, margin: '0 20px' }} />
              <div
                style={{
                  height: 104,
                  padding: '0 25px',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  marginBottom: 50,
                }}
                data-test={window.location.pathname}
                onClick={() => {
                  logEvent(Events.LOGOUT, undefined, () => msalInstance.logout());
                }}
              >
                <Icon icon="logout" color="#fff" />
                <LinkLabel
                  style={{
                    color: '#fff',
                    fontSize: 12,
                    textTransform: 'uppercase',
                    letterSpacing: '1.6px',
                    fontWeight: 450,
                  }}
                >
                  Sign out
                </LinkLabel>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }

  return (
    <div style={{ width: expandedSideBarRoute ? '314px' : '120px' }}>
      <SideBarWrapper
        enableTransitions={didMount}
        className={expanded ? 'sidebar__container--large' : 'sidebar__container--small'}
      >
        <SideBarSticky
          ref={sidebarRef}
          onMouseEnter={() => setIsLarge(true)}
          onMouseLeave={() => !profileSelect && setIsLarge(false)}
          key="sidebar-sticky"
          data-qa="sidebar"
        >
          <Link to={HOME_PATH} style={{ width: '100%' }}>
            <LogoWrapper className={expanded ? 'large' : ''}>
              <Logo />
            </LogoWrapper>
          </Link>
          {expanded && hasRole([IUserRoleType.SCHOOL_ADMIN, IUserRoleType.TENANT_ADMIN]) && (
            <SideBarFade fadeIn={expanded}>
              <AppSwitcherChevron
                onClick={() =>
                  AppSwitcher({ parentEl: sidebarRef.current, currentAppIdx: 0, env: env?.ENV, posX: 222, posY: 63 })
                }
              >
                <OpenMenu />
              </AppSwitcherChevron>
            </SideBarFade>
          )}

          <MainMenuContainer>
            <HorizontalSeparator className="sidebar_separator" style={{ marginTop: 30 }} />
            <SideMenuItem className="sidebar__link" linkTo={TF_DASHBOARD_ROUTE} closeDrawer={closeDrawer}>
              <Icon icon="dashboard" className="sidebar_icon" />
              <SideBarFade fadeIn={expanded}>
                <LinkLabel className="sidebar_linklabel">Dashboard</LinkLabel>
              </SideBarFade>
            </SideMenuItem>
            <SideMenuItem className="sidebar__link" linkTo={TF_CALENDAR_ROUTE} closeDrawer={closeDrawer}>
              <Icon icon="calendar" className="sidebar_icon" />
              <SideBarFade fadeIn={expanded}>
                <LinkLabel className="sidebar_linklabel">Calendar</LinkLabel>
              </SideBarFade>
            </SideMenuItem>
            <SideMenuItem linkTo={TF_LESSON_HISTORY_OVERVIEW} className="sidebar__link" closeDrawer={closeDrawer}>
              <Icon icon="lessonHistoryClock" className="sidebar_icon" />
              <SideBarFade fadeIn={expanded}>
                <LinkLabel className="sidebar_linklabel">Lesson History</LinkLabel>
              </SideBarFade>
            </SideMenuItem>
            <SideMenuItem linkTo={TF_STUDENT_GROUPS_PATH} className="sidebar__link" closeDrawer={closeDrawer}>
              <Icon icon="people" className="sidebar_icon" />
              <SideBarFade fadeIn={expanded}>
                <LinkLabel className="sidebar_linklabel">Scheduled classes</LinkLabel>
              </SideBarFade>
            </SideMenuItem>
            <SideMenuTargetBlankItem
              onClick={() => logEvent(Events.HELP_CENTER)}
              href={env.HELP_CENTER_URL}
              target="_blank"
              className="sidebar__link"
            >
              <Icon icon="helpcenter" className="sidebar_icon" />
              <SideBarFade fadeIn={expanded}>
                <LinkLabel className="sidebar_linklabel">Help Center</LinkLabel>
              </SideBarFade>
            </SideMenuTargetBlankItem>
          </MainMenuContainer>
          <BottomMenuContainer>
            <div style={{ width: '100%' }}>
              <HorizontalSeparator className="sidebar_separator" style={{ opacity: 0.2 }} />
            </div>

            <SideMenuTargetBlankItem className="sidebar__link" isActive={drawer === DrawerTypes.PROFILE}>
              {!teacher ? (
                <Loading />
              ) : (
                <>
                  <div
                    style={{ cursor: 'pointer', display: 'flex', flexDirection: 'row' }}
                    onClick={() => {
                      logEvent(Events.SETTINGS);
                      setDrawer(drawer === DrawerTypes.PROFILE ? null : DrawerTypes.PROFILE);
                    }}
                  >
                    <ProfileAvatar>
                      <Avatar
                        initials={`${teacher?.firstName.charAt(0)}${teacher?.lastName.charAt(0)}`.toLocaleUpperCase()}
                        size={36}
                        fontSize={14}
                        paddingTop={2}
                        avatar="profile"
                      />
                    </ProfileAvatar>

                    <SideBarFade fadeIn={expanded}>
                      <ProfileDetailsWrapper isExpanded={expanded}>
                        <LinkLabel className="sidebar_linklabel">
                          <Ellipsis style={{ paddingRight: 20 }}>
                            {teacher ? getTeacherFullName(teacher as unknown as ITeacher) : 'Profile'}
                          </Ellipsis>
                        </LinkLabel>
                        <LinkLabel className="sidebar_linklabel" style={{ textTransform: 'none' }}>
                          <Ellipsis style={{ paddingRight: 20 }}>
                            {school} | {department}
                          </Ellipsis>
                        </LinkLabel>
                      </ProfileDetailsWrapper>
                    </SideBarFade>
                  </div>
                  {teacherProfiles.length > 1 && (
                    <SideBarFade fadeIn={expanded}>
                      <TeacherSelectWrapper
                        className="sidebar_profile-switch"
                        data-qa="profile-select-button"
                        onClick={() => setProfileSelect(true)}
                        isExpanded={expanded}
                      >
                        <Icon icon="caret" size={10} rotation={270} />
                      </TeacherSelectWrapper>
                    </SideBarFade>
                  )}
                </>
              )}
            </SideMenuTargetBlankItem>

            <SideMenuTargetBlankItem
              className="sidebar__link"
              data-qa="sidebar-settings"
              isActive={drawer === DrawerTypes.SETTINGS}
              onClick={() => {
                logEvent(Events.SETTINGS);
                setDrawer(drawer === DrawerTypes.SETTINGS ? null : DrawerTypes.SETTINGS);
              }}
            >
              <Icon icon="settingCog" className="sidebar_icon" />
              <SideBarFade fadeIn={expanded}>
                <LinkLabel className="sidebar_linklabel">Settings</LinkLabel>
              </SideBarFade>
            </SideMenuTargetBlankItem>
            <SideMenuTargetBlankItem
              className="sidebar__link"
              data-test={window.location.pathname}
              onClick={() => {
                logEvent(Events.LOGOUT, undefined, () => msalInstance.logout());
              }}
            >
              <Icon icon="logout" className="sidebar_icon" />
              <SideBarFade fadeIn={expanded}>
                <LinkLabel className="sidebar_linklabel">Sign out</LinkLabel>
              </SideBarFade>
            </SideMenuTargetBlankItem>
          </BottomMenuContainer>
        </SideBarSticky>
        <SideBarStickyBackup />
        {teacher && (
          <SideBarDrawer
            teacherId={teacher?.id}
            school={school}
            drawer={drawer}
            closeDrawer={closeDrawer}
            showOverlay={sideBarOverlap}
          />
        )}
        {profileSelect && <ProfileSelect teacherProfiles={teacherProfiles} onClose={() => setProfileSelect(false)} />}
      </SideBarWrapper>
    </div>
  );
};

export default Sidebar;
