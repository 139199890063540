import { gql, useQuery } from '@apollo/client';

import { Department } from 'model/misc';

export const DEPARTMENTS_WITH_SCHOOL_GQL = gql`
  query Departments($filter: DepartmentsFilter) {
    departments(filter: $filter) {
      edges {
        id
        name
        school {
          id
          name
        }
        center
      }
    }
  }
`;

export interface DepartmentFilter {
  schoolId?: string;
}

export const useDepartmentsWithSchool = (filter?: DepartmentFilter) => {
  const { data, ...rest } = useQuery<{ departments: { edges: Department[] } }>(DEPARTMENTS_WITH_SCHOOL_GQL, {
    variables: {
      filter,
    },
  });

  return {
    departments: data?.departments?.edges,
    ...rest,
  };
};

export const DEPARTMENTS_GQL = gql`
  query Departments($filter: DepartmentsFilter) {
    departments(filter: $filter) {
      edges {
        id
        name
        center
      }
    }
  }
`;

export const useDepartments = (filter?: DepartmentFilter) => {
  const { data, ...rest } = useQuery<{ departments: { edges: Department[] } }>(DEPARTMENTS_GQL, {
    variables: {
      filter,
    },
  });

  return {
    data: data?.departments?.edges,
    ...rest,
  };
};

// TODO: this should use a dedicated GQL query, that fetches a department by ID, instead of all departments.
export const useDepartmentWithSchool = (id: string | undefined) => {
  const { departments } = useDepartmentsWithSchool();

  return departments?.find((department) => department.id === id);
};
