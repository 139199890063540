import { BE_URL } from 'config/env';

export const API_PREFIX = '/v1';

export const DEFAULT_REQUEST_PARAMS: Partial<IMakeRequestArgs> = {
  method: 'GET',
  mode: 'cors',
  credentials: 'include',
  origin: BE_URL,
};

export interface IDefaultHttpHeaders {
  'content-type': string;
  accept: string;
  'cache-control': string;
  pragma: string;
}

export const DEFAULT_HTTP_HEADERS = {
  'content-type': 'application/json',
  accept: 'application/json',
  'cache-control': 'no-cache',
  pragma: 'no-cache',
};
export interface IMakeRequestArgs {
  origin?: string; // BE_URL
  apiPrefix?: string;
  pathname: string;
  searchParams?: Record<string, string | number>; // default: undefined

  body?: Record<string, unknown> | string | FormData; // body for method === GET will throw in fetch
  cache?: string;
  credentials?: string; // include
  headers?: Record<string, string | undefined>; // DEFAULT_HTTP_HEADERS
  method?: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE'; // default: GET, if needed more just add them
  mode?: string; // cors
  redirect?: string;
  referrerPolicy?: string;
}

export interface IMakeRequestProcessedArgs extends Omit<IMakeRequestArgs, 'body' | 'searchParams'> {
  searchParams?: string;
  body?: string;
}
