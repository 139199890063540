import buttonConstants from './button';
import calendar from './calendar';
import { border, calendar as calendarColor, main, paper, role, shadow, text as textColor } from './colors';
import * as container from './container';
import form from './form';
import gradients from './gradients';
import layer from './layer';
import { columnGutters, containerSettings, gridColumns, layoutScale, Size as SizeImport, ISized } from './layout';
import { withoutUnits, withUnits } from './spacing';
import { fontSize, fontWeight, lineHeight } from './typography';

const theme = {
  name: 'Default',
  calendar,
  color: {
    border,
    shadow,
    ...main,
    ...paper,
    ...role,
    text: textColor,
    calendar: calendarColor,
  },
  columnGutters,
  containerSettings,
  gridColumns,
  fontSize,
  fontWeight,
  gradients,
  layoutScale,
  layer,
  lineHeight,
  spacingScale: {
    withUnits,
    withoutUnits,
  },
  form,
  buttonConstants,
  container,
};

export type Sized<T> = ISized<T>;
export type Size = SizeImport;
export type Theme = typeof theme;
export { SpacingScale } from './spacing';
export { default as GlobalStyles } from './GlobalStyle';
export default theme;
