import { useEffect, RefObject } from 'react';

interface IUseOnClickOutside {
  wrapperEl: RefObject<HTMLElement>;
  onClickOutside: (event?: MouseEvent) => void;
}

export const useOnClickOutside = ({ onClickOutside, wrapperEl }: IUseOnClickOutside) => {
  useEffect(() => {
    const handleClickOutsideFn = (event: MouseEvent) => {
      if (!wrapperEl.current) {
        return;
      }
      if (!wrapperEl.current.contains(event.target as HTMLDivElement)) {
        onClickOutside(event);
      }
    };

    document.addEventListener('mousedown', handleClickOutsideFn);
    return () => {
      document.removeEventListener('mousedown', handleClickOutsideFn);
    };
  }, [wrapperEl, onClickOutside]);
};
