const calendar = {
  headerHeight: 62,
  condensedHeaderHeight: 46,
  darkStripes:
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYAgMAAACdGdVrAAAACVBMVEXv7+/h4eHl5eUz20XJAAAAK0lEQVQI12OIYAACToYwEMXEMLWBAQRUE8AUZwSYYgoDU/SWbABLMiSAJQF77w3zn8ZtDQAAAABJRU5ErkJggg==',
  // TODO: Leaving this in lest the design be reverted.
  lightStripes:
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYAgMAAACdGdVrAAAACVBMVEX////w8PD19fWyglTuAAAAK0lEQVQI12OIYAACToYwEMXEMLWBAQRUE8AUZwSYYgoDU/SWbABLMiSAJQF77w3zn8ZtDQAAAABJRU5ErkJggg==',
  backgroundSize: '12px',
  condensedBackgroundSize: '8px',
};

export default calendar;
