import React, { CSSProperties, createRef, useLayoutEffect, useState } from 'react';
import styled from 'styled-components';

import { InputStyleProps, InputStyles } from 'components/sharedStyles';

export const SelectDeselectAllWrapper = styled.div`
  position: absolute;
  top: 12px;
  right: 8px;
  margin-bottom: 8px;
  font-size: 10px;
  text-align: right;
  color: #017ada;
`;

export const Anchor = styled.a<{ $disabled: boolean }>`
  color: ${({ $disabled, theme }) => ($disabled ? theme.color.text.disabled : theme.color.tfBlue)};

  &:hover {
    text-decoration: ${({ $disabled }) => ($disabled ? 'none' : 'underline')};
  }

  cursor: ${({ $disabled }) => ($disabled ? 'default' : 'pointer')};
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Label = styled.div`
  margin-right: 8px;
`;

export const MultiselectWrapper = styled.div`
  position: relative;
  text-align: left;
  display: flex;
  flex-direction: row;
  width: 220px;
  height: 32px;
  & > input {
    padding-bottom: 6px;
  }
`;

// InputStyles
export const SelectInput = styled.input<InputStyleProps>`
  ${InputStyles}
  caret-color: transparent;
`;

interface SearchResultProps {
  opened: boolean;
  width?: CSSProperties['width'];
  className?: string;
}

export const SearchResultBase = styled.div<SearchResultProps>`
  position: absolute;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 8px 0 rgba(25, 25, 25, 0.16);
  display: ${(props) => (props.opened ? 'block' : 'none')};
  list-style: none;
  width: ${(props) => (props.width ? `${props.width}px` : '100%')};
  z-index: 10;
  max-height: 310px;
  overflow-y: scroll;
`;

export const SearchResult: React.FC<SearchResultProps> = ({ opened, children, width, className }) => {
  const [styleTop, setStyleTop] = useState<number>(34);

  const searchResultRef = createRef<HTMLDivElement>();

  useLayoutEffect(() => {
    if (opened) {
      const domRect = searchResultRef?.current?.getBoundingClientRect();
      const bodyHeight = document.body.clientHeight;

      const isDomRectOutsideScreen = !!domRect && domRect.bottom > bodyHeight;

      if (isDomRectOutsideScreen) {
        const howMuchOutsideIsIt = domRect.bottom - bodyHeight;
        setStyleTop(styleTop - howMuchOutsideIsIt);
      }
    }
    // Only need to recalculate when opening the dropdown
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [opened]);

  return (
    <SearchResultBase
      opened={opened}
      width={width}
      className={className}
      style={{ top: styleTop }}
      ref={searchResultRef}
    >
      {children}
    </SearchResultBase>
  );
};

export const ListResults = styled.div`
  padding: 32px 5px 10px 10px;
  width: 100%;
  height: auto;
`;

export const CloseArrow = styled.div<{ disabled: boolean }>`
  height: 15px;
  width: 15px;
  position: absolute;
  right: 10px;
  top: 6px;
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  user-select: none;
`;
