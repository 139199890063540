export const fontSize = {
  12: '12px',
  14: '14px',
  16: '16px',
  20: '20px',
  26: '26px',
  28: '28px',
  40: '40px',
  60: '60px',
  80: '80px',
};

export const fontWeight = {
  black: 900,
  bold: 700,
  book: 400,
  subtitle: 450,
  light: 300,
  medium: 500,
};

export const lineHeight = {
  20: '20px',
  22: '22px',
  24: '24px',
  26: '26px',
  32: '32px',
  34: '34px',
  44: '44px',
  62: '62px',
  80: '80px',
};
