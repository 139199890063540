import { TransformationType } from 'class-transformer';
import moment, { Moment } from 'moment-timezone';

export const MomentTransformer = (
  value: string | Moment | null | undefined,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  _obj: any,
  type: TransformationType,
) => {
  if (!value) {
    return value;
  }
  switch (type) {
    case TransformationType.PLAIN_TO_CLASS:
      return moment(value as string);
    case TransformationType.CLASS_TO_PLAIN:
      return moment(value).toISOString();
    default:
      return value;
  }
};
