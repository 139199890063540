import { IEnvironment } from 'config/env';

const environment: IEnvironment = {
  ENV: 'prod',
  SUPPORT_URL: 'https://ef-teachonline-helpcenter.force.com/s/',
  GQL_URL: 'https://api.ef.studio/gipfeli/',
  MEDIA_URL: 'https://teach-assets.ef.studio',
  TF_SVCGATEWAY_URL: 'https://api.ef.studio/teacher-first',
  DISCONNECT_AFTER: 3e5,
  HELP_CENTER_URL: 'https://ef-teachonline.my.site.com/s/',
  ENTRY_STATUS_INTERVAL: 2e4, // 20 seconds
  AVAILABILITY_INTERVAL: 3e6, // 5 minutes
  BOOKING_INTERVAL: 6e4, // 1 minute
  USER_INTERVAL: 6e5, // 10 minutes
  UNCLAIMED_LESSONS_INTERVAL: 6e4, // 1 minute
  ACR_TODO_INTERVAL: 3e5, // 5 minutes
  LESSON_DEFINITION_INTERVAL: 6e5, // 10 minutes
  TECH_CHECK_URL_TEMPLATE:
    'https://evc.ef.com/evc15/meeting/tools/teachertechcheck?proxyInfo=&centerCode=UK#ret={0};s=304615050;m=35754986;cc=en;host=axis.eflabs.io;canskip=true',
  LOGIN: {
    SIGNIN_SIGNUP_USER_FLOW: 'B2C_1_Teacher_First_Prod',
    RESET_USER_FLOW: 'B2C_1_Teacher_First_Prod',
    AUTHORITY_BASE_URL: 'https://efideu.b2clogin.com/efideu.onmicrosoft.com/',
    TOKEN_SCOPE: 'https://efideu.onmicrosoft.com/teacher-first/tfapi',
    CLIENT_ID: '56a523f4-0fa3-44af-93b4-ffb9752ddc3d',
  },
  DATADOG: {
    APPLICATION_ID: '9d00dff8-74e2-4ba3-b391-1fdd000b21aa',
    CLIENT_TOKEN: 'pub0242cd2f1e3f4db91a4a78865b52fe00',
    SERVICE_NAME: 'teacher-first-fe',
    VIRTUAL_ENV: 'prod',
  },
  API_KEYS: {
    amplitude: 'b6fb67583bb05bbb64d3dfc28f1e01ce',
  },
  EVC_TUNNELS: {
    US_NV: 'https://evc.ef.com',
    CN_SH: 'https://evc.ef.com.cn',
  },
};
export default environment;
