import { datadogLogs } from '@datadog/browser-logs';
import equal from 'fast-deep-equal';

import { BLANK_ID, DASH, REGEX_ID } from 'config/constants';
import { ILessonDef } from 'model/LessonDefinitions';
import { ILessonBookingType, LessonBookingType } from 'model/misc';
import { isCypress } from 'utils/testing';

import window from './window';

export type INOOP = () => void;
export const NOOP = () => {}; // eslint-disable-line @typescript-eslint/no-empty-function

export interface NetworkError extends Error {
  response: Response;
}

export type IIdentity<T> = (a: T) => T;

export const compose = (
  ...fns: Array<IIdentity<any>> // eslint-disable-line @typescript-eslint/no-explicit-any
) => fns.reduce((composed, nextFn) => (arg) => nextFn(composed(arg)));

export const copyToClipboard = (str: string) => window.navigator.clipboard.writeText(str);

export const throwError = (message: string): never => {
  throw new Error(message);
};

export const groupBy = <T>(arr: T[], getKey: (arg: T) => string) =>
  arr.reduce(
    (acc, itm) => {
      const key = getKey(itm);
      if (key) {
        // if returning falsy value item gets omited
        acc[key] = acc[key] || [];
        acc[key].push(itm);
      }
      return acc;
    },
    {} as Record<string, T[]>, // eslint-disable-line
  );

export enum alphaCasing {
  lower = 'lower',
  upper = 'upper',
}

export const getTeacherNameInitials = (
  teacherProfile: ITeacherName | undefined,
  defaultInitials: string = 'N/A',
  alphaCase: string = alphaCasing.upper,
) => {
  const initials =
    ((teacherProfile?.firstName[0] || '') + (teacherProfile?.lastName[0] || '')).trim() || defaultInitials;
  return alphaCase === alphaCasing.upper ? initials.toLocaleUpperCase() : initials.toLocaleLowerCase();
};

export interface ITeacherName {
  firstName: string;
  lastName: string;
}

export const getTeacherFullName = (teacherProfile?: ITeacherName, fallback?: string) =>
  teacherProfile?.firstName && teacherProfile?.lastName
    ? `${teacherProfile.firstName.trim()} ${teacherProfile.lastName.trim()}`.trim()
    : fallback || DASH;

export const langNames = new Intl.DisplayNames(['en'], { type: 'language' });

export const getEnglishFullLangName = (a: string) => langNames.of(a) as string;

export const diffChangedProps = <J extends Partial<K>, K extends Record<string, unknown>>(
  obj: J,
  base: K,
): Partial<J> =>
  Object.keys(obj).reduce((diff, key) => (!equal(base[key], obj[key]) ? { ...diff, [key]: obj[key] } : diff), {} as J);

export const removeEmptyProps = (obj: Record<string, unknown>) => {
  const empty = ['', undefined, null] as unknown[];
  return Object.keys(obj).reduce((acc, key) => {
    if (empty.includes(obj[key])) {
      return acc;
    }
    return { ...acc, [key]: obj[key] };
  }, {});
};

export const isEvcPLTopicAllowed = (lessonDef?: ILessonDef): boolean =>
  lessonDef?.bookingType
    ? ([LessonBookingType.Private, LessonBookingType.Scheduled] as ILessonBookingType[]).includes(lessonDef.bookingType)
    : false;

export const bankersRound = (n: number, d: number = 2) => {
  const x = n * Math.pow(10, d);
  const r = Math.round(x);
  const br = Math.abs(x) % 1 === 0.5 ? (r % 2 === 0 ? r : r - 1) : r;
  return (br / Math.pow(10, d)).toFixed(2);
};

export const formatCurrency = (n: number | string, currencyCode: string | null | undefined) => {
  const numberValue = typeof n === 'string' ? Number(n) : n;
  if (!currencyCode) {
    return bankersRound(Number(numberValue));
  }
  return Intl.NumberFormat('en-US', { style: 'currency', currency: currencyCode }).format(
    Number(bankersRound(numberValue)),
  );
};

export const getCurrencySymbol = (currencyCode: string | undefined) => {
  if (!currencyCode) {
    return DASH;
  }
  const formatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: currencyCode });
  let formatted = formatter.format(0);
  // Remove digits and decimal separator
  formatted = formatted.replace(/\d/g, '').replace(/\./g, '').trim();
  return formatted;
};

export const isValidUUID = (id: string) => (id && id !== BLANK_ID && REGEX_ID.test(id)) || isCypress();

export const scrollTop = () => document?.getElementById('scrollable')?.scrollTo(0, 0);

export const ddlog = datadogLogs.logger;
