import styled from 'styled-components';

export const CopiedIconWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  height: 10px;
  width: 10px;
  border-radius: 100%;
  background-color: #fff;
  color: rgba(25, 25, 25);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-right: 4px;
  margin-bottom: 1px;
`;

export const IdWrapper = styled.div`
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: calc(100% - 15px);
`;
