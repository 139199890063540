import useSWR from 'swr';

import {
  ISettings,
  INotificationSettings,
  ICalendarSettings,
  IWebcal,
  IWebcalResponse,
  ReservedTimeslotsToggleResponse,
} from 'model/Settings';
import { timezones } from 'model/Timezone';
import makeRequest from 'network/helpers/makeRequest';

const LOCAL_STORAGE_SETTINGS_KEY = 'SettingsLocalstorageMock';

const getDefaultCalendar = (): ICalendarSettings => ({
  secondaryTimezone: 'America/New_York' as (typeof timezones)[number],
  useSecondaryTimezone: true,
  reserveTimeslots: false,
});

const getDefaultNotifications = (): INotificationSettings => ({
  invoicing: false,
  classRecords: false,
});

export const getDefaultSettings = (): ISettings => ({
  calendar: getDefaultCalendar(),
  notifications: getDefaultNotifications(),
});

// TODO: Implementation with backend
export const updateTeachersSettings = (teacherId: string, settings: ISettings): Promise<ISettings> => {
  let localStorageSettings;
  try {
    localStorageSettings = JSON.parse(localStorage.getItem(LOCAL_STORAGE_SETTINGS_KEY) || '{}');
    if (typeof localStorageSettings !== 'object') {
      throw new Error('invalid settings in localstorage');
    }
  } catch (e) {
    localStorageSettings = {};
  }
  localStorageSettings[teacherId] = settings;
  localStorage.setItem(LOCAL_STORAGE_SETTINGS_KEY, JSON.stringify(localStorageSettings));
  return Promise.resolve(settings);
};

// TODO: Implementation with backend
export const fetchTeachersSettings = async (teacherId: string): Promise<ISettings> => {
  let localStorageSettings;
  try {
    localStorageSettings = JSON.parse(localStorage.getItem(LOCAL_STORAGE_SETTINGS_KEY) || '{}');
    if (typeof localStorageSettings !== 'object') {
      throw new Error('invalid settings in localstorage');
    }
  } catch (e) {
    localStorageSettings = {};
  }

  if (!localStorageSettings[teacherId]) {
    return updateTeachersSettings(teacherId, {
      calendar: getDefaultCalendar(),
      notifications: getDefaultNotifications(),
    });
  }

  return Promise.resolve(localStorageSettings[teacherId] as ISettings);
};

export const useTeacherSettings = (teacherId?: string) => {
  const { data, mutate } = useSWR<ISettings>(teacherId ? `teachers/${teacherId}/settings` : null, () =>
    fetchTeachersSettings(teacherId!),
  );
  const storeTeacherSettings: (id: string, settings: ISettings) => void = (id: string, settings: ISettings) => {
    mutate(settings, false); // optimistic UI update
    mutate(updateTeachersSettings(id, settings));
  };
  return [data || getDefaultSettings(), storeTeacherSettings] as const;
};

export const useTeacherWebCal = (teacherId?: string) =>
  useSWR(`/webcals?teacherId=${teacherId}`, () => fetchTeacherWebcal(teacherId!));

export const fetchTeacherWebcal = (teacherId: string) =>
  makeRequest<IWebcalResponse>({
    pathname: 'webcals',
    method: 'GET',
    searchParams: {
      teacherId,
      limit: 1,
    },
  }).then((result) => result.data[0]);

export const postTeacherWebcal = (teacherId: string) =>
  makeRequest<IWebcal>({
    pathname: 'webcals',
    method: 'POST',
    body: {
      teacherId,
    },
  });

export const updateTeacherCal = (webcalId: string) =>
  makeRequest<IWebcal>({
    pathname: `webcals/${webcalId}/generate-secret`,
    method: 'POST',
  });

export const useReservedTimeslotsToggle = (teacherId?: string) => {
  const { data, isValidating, mutate } = useSWR(teacherId ? `/teachers/${teacherId}/availability-settings` : null, () =>
    makeRequest<ReservedTimeslotsToggleResponse>({
      pathname: `/teachers/${teacherId}/availability-settings`,
      method: 'GET',
    }),
  );

  const updateReservedTimeslots = (value: boolean) =>
    makeRequest({
      pathname: `/teachers/${teacherId}/availability-settings`,
      method: 'POST',
      body: {
        flexibleReservation: value,
      },
    }).then(() => mutate({ flexibleReservation: value }));

  return {
    isReservedTimeslots: !!data?.flexibleReservation,
    isUpdatingReservedTimeslot: isValidating,
    updateReservedTimeslots,
  };
};
