import React from 'react';
import { Link } from 'react-router-dom';

import { ScreenContainer, Text, EnterClassButton, AnimationContainer } from '../styledComponents';
import { ClassQueueSVG } from './Items';

const QueueClosed = () => (
  <ScreenContainer>
    <Text>Sorry, classrooms are no longer being created.</Text>
    <Text>You have not been matched with any students.</Text>
    <AnimationContainer>
      <ClassQueueSVG alert />
    </AnimationContainer>
    <EnterClassButton as={Link} to={{ pathname: '/' }} rel="noreferrer noopener">
      Exit classroom
    </EnterClassButton>
  </ScreenContainer>
);

export default QueueClosed;
