export default {
  borderRadius: {
    big: '20px',
    small: '4px',
  },
  height: {
    big: '40px',
    small: '32px',
  },
  lineHeight: 1.2,
  padding: {
    big: {
      horizontal: '25px',
      top: '13px',
      bottom: '11px',
    },
    small: {
      horizontal: '25px', // Nihir: Changed from 12px based on: https://app.zeplin.io/project/5c6d6bd0809f735df64e6d1f/screen/5c9901b8361d0e055155a639
      top: '9px',
      bottom: '7px',
    },
  },
};
