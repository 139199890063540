export const tryWithNRetries = async <T>(
  fn: () => Promise<T>,
  n: number,
  retryCondition: (arg: T) => boolean,
): Promise<T> => {
  let result;
  n += 1;

  do {
    result = await fn();
    n -= 1;
  } while (retryCondition(result) && n > 0);

  return result;
};
