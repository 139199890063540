import React, { createContext, ReactNode, useContext } from 'react';
import { Redirect } from 'react-router-dom';

import { Loading } from 'components/Loading';
import { UNAUTHORIZED_USER_PATH } from 'config/internalUrlPaths';
import { School } from 'model/misc';
import { useSchools } from 'network/graphql/Schools';
import { useLocalStorage } from 'utils/hooks/useStorage';

export interface ISchoolContext {
  // schoolId is the currently selected school. it will never be null\undefined
  schoolId: string;
  // school is the school object for the currently selected school. it will never be null\undefined
  school: School;
  // availableSchools a collection of all available schools.
  availableSchools: School[];
  // setSchoolId allows to set the current school.
  // only one of the schools from the availableSchools can be picked.
  // if the school that is not in the list is selected, it will fall back to the first school in the list.
  setSchoolId: (schoolID: string) => void;
}

export const SchoolContext = createContext<ISchoolContext>({} as ISchoolContext);

interface Props {
  children: ReactNode;
}

export const ProvideSchool = (props: Props) => {
  const { schools, loading } = useSchools();

  const [selectedSchoolId, setSelectedSchoolId] = useLocalStorage<string | undefined>(
    'selectedSchool',
    schools?.find((_) => true)?.id,
  );

  const selectedSchool = schools?.find((s) => s.id === selectedSchoolId) ?? schools?.find((_) => true);

  if (loading) {
    return <Loading />;
  }

  if (!selectedSchool) {
    return <Redirect to={UNAUTHORIZED_USER_PATH} />;
  }

  return (
    <SchoolContext.Provider
      value={{
        schoolId: selectedSchool.id,
        school: selectedSchool,
        availableSchools: schools!,
        setSchoolId: setSelectedSchoolId,
      }}
    >
      {props.children}
    </SchoolContext.Provider>
  );
};

export const useSchoolContext = () => useContext(SchoolContext);
