import styled from 'styled-components';

export const Avatar = styled.div`
  margin: 75px auto 0 auto;
  background-image: url('/assets/avatarBg.png');
  background-position: 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const HorizontalSeparator = styled.div`
  height: 1px;
  margin: 0px 45px 30px 45px;
  width: auto;
  background-color: #fffefe;
`;

export const MainMenuContainer = styled.div`
  height: 100%;
  padding: 0;
`;

export const UserName = styled.div`
  margin: 12px auto 0 auto;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.44;
  color: white;
  text-transform: capitalize;
  text-align: center;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const LinkLabel = styled.div`
  margin-left: 12px;
  overflow: hidden;
  width: 100%;
  white-space: nowrap;
`;

export const BottomMenuContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 32px;
`;

export const SideMenuItemContainer = styled.div`
  color: white;
  cursor: pointer;
  display: flex;
  height: 60px;
  line-height: 60px;
  margin: 0 auto 0 auto;
  background: ${({ theme }) => theme.color.educationBlue};
  transition: background-color 0.3s ease-out;
  user-select: none;

  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 1.6px;
  font-weight: 450;

  .sidebar_profile-switch {
    background: ${({ theme }) => theme.color.educationBlue};
    transition: background-color 0.3s ease-out;
  }

  &.active,
  &:hover {
    background-color: rgb(51, 99, 131);
    font-weight: 900;
    .sidebar_profile-switch {
      background-color: rgb(92, 130, 156);
    }
  }
`;

export const SideBarStickyBackup = styled.div`
  background-color: ${({ theme }) => theme.color.educationBlue};
  width: 120px;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
`;

export const SideBarSticky = styled.div`
  display: flex;
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  align-items: center;
  flex-direction: column;
  background-color: ${({ theme }) => theme.color.educationBlue};
  box-shadow: 8px 0px 32px rgb(0 0 0 / 16%);
`;

export const SideBarWrapper = styled.div<{ enableTransitions: boolean }>`
  height: 100%;
  background-color: ${({ theme }) => theme.color.educationBlue};
  z-index: ${({ theme }) => theme.layer.sidebar};
  position: relative;

  .sidebar__link {
    margin-bottom: 0;
  }

  .sidebar_icon {
    transform: translateY(3px);
    padding-right: 35px;
  }

  & .sidebar__link {
    width: 120px;
    & > div {
      padding-left: 50px;
    }
  }

  ${(props) =>
    props.enableTransitions
      ? `
    &,
    & .sidebar__link,
    & #sidebar_username,
    & .sidebar_separator {
      transition: width .3s, height .3s, font-size .1s, margin .3s;
    }
  `
      : ''}

  /* LARGE container */
  &.sidebar__container--large {
    width: 314px;

    & .sidebar__link {
      width: 314px;
      font-size: 16px;
    }

    & #sidebar_avatar {
      width: 120px;
      height: 69px;
      font-size: 24px;
      background-size: 72px 69px;
      padding-top: 5px;
    }

    & #sidebar_username {
      height: 26px;
    }
  }

  /* SMALL container */
  &.sidebar__container--small {
    width: 120px;

    & .sidebar_linklabel {
      width: 0;
    }

    & .sidebar_separator {
      margin: 30px 45px 30px 45px;
    }

    & #sidebar_avatar {
      width: 40px;
      height: 40px;
      font-size: 14px;
      background-size: 40px 40px;
      padding-top: 3px;
    }

    & #sidebar_username {
      font-size: 0;
      height: 0;
      margin: 0;
    }
  }
`;

export const SideBarAvatar = styled.div`
  margin: 0 auto;
  position: relative;
  > div {
    height: 100px;
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: end;
    > div {
      margin-left: auto;
      margin-right: auto;
      &:nth-of-type(2) {
        margin-bottom: -15px;
      }
    }
  }
`;

export const LogoWrapper = styled.div`
  margin-top: 30px;
  padding-left: 42px;
  text-align: left;
  overflow: hidden;
  svg {
    & > path {
      transition: opacity 200ms;
      opacity: 0;
    }
  }
  &.large {
    svg > path {
      transition: opacity 300ms cubic-bezier(0.4, 0, 0.2, 1) 100ms;
      opacity: 1;
    }
  }
`;

export const ProfileAvatar = styled.div`
  margin-left: -10px;
  padding-right: 10px;
  margin-top: 10px;
`;

export const AppSwitcherChevron = styled.button`
  color: #fffefe;
  position: absolute;
  top: 25px;
  right: 6px;
  padding: 10px;
  background: none;
  border: none;
  cursor: pointer;
`;

export const ProfileDetailsWrapper = styled.div<{ isExpanded: boolean }>`
  display: flex;
  flex-direction: column;
  line-height: 20px;
  padding-top: 10px;
  width: ${({ isExpanded }) => (isExpanded ? '180px' : 'initial')};
`;

export const TeacherSelectWrapper = styled.div<{ isExpanded: boolean }>`
  width: ${({ isExpanded }) => (isExpanded ? '48px' : 'initial')};
  position: absolute;
  right: 0;
  height: 60px;
  display: flex;
  justify-content: center;
`;
