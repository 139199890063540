import styled, { css } from 'styled-components';

import { ReserveTimeslotToggle } from 'components/ReserveTimeSlotsToggle';

export const Label = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: ${({ theme }) => theme.color.inkBlack};
  margin-top: 37px;
  margin-bottom: 11px;
  width: 250px;
`;

export const SettingsCalendarStyles = styled.div`
  margin: 20px;
  h2 {
    font-size: 20px;
    margin-top: 0px;
    margin-bottom: 20px;
  }
`;

const checkboxStyles = css`
  .MuiIconButton-root {
    align-self: flex-start;
  }
  .MuiCheckbox-colorPrimary.Mui-checked {
    color: ${({ theme }) => theme.color.tfBlue};
    padding-top: 9px;
  }
  .MuiFormControlLabel-label {
    padding-top: 2px;
    font-size: 14px;
  }
`;

export const CheckBoxStyle = styled.div`
  padding-bottom: 15px;
  ${checkboxStyles}
`;

export const CalendarUrl = styled.div`
  width: 400px;
  height: 32px;
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 9px;
  font-size: 14px;
  color: ${({ theme }) => theme.color.inkBlack};
`;

export const ReserveTimeslotToggleStyled = styled(ReserveTimeslotToggle)`
  ${checkboxStyles}
`;
