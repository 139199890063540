import { Snackbar } from '@mui/material';
import React from 'react';
import ReactDOM from 'react-dom';

import useWindowSize from 'utils/hooks/useWindowSize';

import { ToasterWrapper, subcenterStyle, DismissButton } from './styledComponents';

export enum ToasterType {
  SUCCESS = 'success',
  INFO = 'info',
  WARN = 'warn',
  ERROR = 'error',
}

export const styles = {
  [ToasterType.SUCCESS]: {
    backgroundColor: '#339885',
    ...subcenterStyle,
  },
  [ToasterType.INFO]: {
    backgroundColor: '#D6D6D6',
    ...subcenterStyle,
  },
  [ToasterType.WARN]: {
    backgroundColor: '#F7B124',
    ...subcenterStyle,
  },
  [ToasterType.ERROR]: {
    backgroundColor: '#D1334A',
    ...subcenterStyle,
  },
};

interface Props {
  message: string;
  type: ToasterType;
  onClose: () => void;
}

const SnackbarCenter: React.FC<Props> = ({ message, type, onClose }) => {
  const { mobile } = useWindowSize();

  return (
    <ToasterWrapper responsive={mobile} data-qa={`toaster-${type}`}>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        autoHideDuration={type !== ToasterType.ERROR ? 5e3 : null}
        ContentProps={{
          style: styles[type],
        }}
        ClickAwayListenerProps={{
          onClickAway: type !== ToasterType.ERROR ? () => onClose() : () => null,
        }}
        color="#001c5c"
        open
        onClose={onClose}
        message={message}
        action={type === ToasterType.ERROR ? <DismissButton onClick={onClose}>Dismiss</DismissButton> : null}
        style={{ zIndex: 9750, textAlign: 'center' }}
      />
    </ToasterWrapper>
  );
};

export const toaster = (message: string, type: ToasterType) => {
  const node = document.getElementById('toaster')!;

  const onClose = () => ReactDOM.unmountComponentAtNode(node);

  ReactDOM.render(<SnackbarCenter message={message} type={type} onClose={() => onClose()} />, node);
};
