import { Moment } from 'moment-timezone';

import { Booking } from 'model/Booking';

export enum Weekday {
  SUN = 'sunday',
  MON = 'monday',
  TUE = 'tuesday',
  WED = 'wednesday',
  THU = 'thursday',
  FRI = 'friday',
  SAT = 'saturday',
}

export const mapWeekDay = new Map<Weekday, number>([
  [Weekday.MON, 1],
  [Weekday.TUE, 2],
  [Weekday.WED, 3],
  [Weekday.THU, 4],
  [Weekday.FRI, 5],
  [Weekday.SAT, 6],
  [Weekday.SUN, 7],
]);

export const weekDays: Weekday[] = [
  Weekday.MON,
  Weekday.TUE,
  Weekday.WED,
  Weekday.THU,
  Weekday.FRI,
  Weekday.SAT,
  Weekday.SUN,
];

// this is specially for geting day names from Date
// date.getDay()
export const namesGetDay: Weekday[] = [
  Weekday.SUN,
  Weekday.MON,
  Weekday.TUE,
  Weekday.WED,
  Weekday.THU,
  Weekday.FRI,
  Weekday.SAT,
];

type WeekRecord<T> = Record<Weekday, T>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getWeekMap = <T>(Constructor: any): WeekRecord<T> =>
  weekDays.reduce(
    (acc, weekDay) => {
      acc[weekDay] = new Constructor();

      return acc;
    },
    {
      [Weekday.SUN]: [] as any, // eslint-disable-line @typescript-eslint/no-explicit-any
      [Weekday.MON]: [] as any, // eslint-disable-line @typescript-eslint/no-explicit-any
      [Weekday.TUE]: [] as any, // eslint-disable-line @typescript-eslint/no-explicit-any
      [Weekday.WED]: [] as any, // eslint-disable-line @typescript-eslint/no-explicit-any
      [Weekday.THU]: [] as any, // eslint-disable-line @typescript-eslint/no-explicit-any
      [Weekday.FRI]: [] as any, // eslint-disable-line @typescript-eslint/no-explicit-any
      [Weekday.SAT]: [] as any, // eslint-disable-line @typescript-eslint/no-explicit-any
    },
  );

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getEmptyWeekObj = () => getWeekMap<any>({}.constructor);

export type WeekAvailability = WeekRecord<number[]>;
export type WeekBookings = WeekRecord<Booking[]>;

export const getEmptyWeek = (): WeekAvailability =>
  weekDays.reduce<WeekAvailability>((acc, weekDay) => {
    acc[weekDay] = [];
    return acc;
  }, {} as WeekAvailability); // eslint-disable-line

export const getEmptyBookingWeek = (): WeekBookings =>
  weekDays.reduce<WeekBookings>((acc, weekDay) => {
    acc[weekDay] = [];
    return acc;
  }, {} as WeekBookings); // eslint-disable-line

export const calendarDefaultProps = {
  dayStart: 0,
  cellsPerHour: 2,
  weekStartsOn: 1,
  dayEnd: 24,
  cellHeight: 40,
};

export enum ViewMode {
  WEEK = 'week',
  DAY = 'day',
}

export interface ICellCoordinate {
  weekday: Weekday;
  hourNum: number;
}

export interface IAvailabilitySelection {
  from: ICellCoordinate;
  to: ICellCoordinate;
}

export interface AvailabilityBlock {
  id?: string;
  block: IAvailabilitySelection;
  lessonTypes: string[];
}

export interface ExclusiveAvailabilityResponse {
  start: string;
  end: string;
  lessonDefinitions: string[];
  teacherId: string;
  id: string;
}

export type DateRange = [Moment, Moment];

export enum CalendarMode {
  View,
  Edit,
}

export interface IAvSelection {
  start: number;
  end: number;
}

export const allWeekDays = [Weekday.MON, Weekday.TUE, Weekday.WED, Weekday.THU, Weekday.FRI, Weekday.SAT, Weekday.SUN];

export interface ModalResolverRef {
  resolve: (value: string[] | PromiseLike<string[]>) => void;
  reject: (reason: string) => void;
  context: {
    timeslot: IAvailabilitySelection;
    weekId: string;
  };
}
