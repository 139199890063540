import moment, { Moment, unitOfTime as allUnitsOfTime } from 'moment-timezone';
import { useEffect, useState } from 'react';

type unitOfTime = allUnitsOfTime.Diff;
export type TimerCallback = (arg: Moment) => void;

enum Events {
  SECOND = 1, // in s
  MINUTE = 60, // 60
  HOUR = 3600, // 3600
  DAY = 86400, // 24 * 3600 = 86400
}
const conditionalUpdates = [
  // Events.SECOND, for now we always update for evt SECOND
  Events.MINUTE,
  Events.HOUR,
  Events.DAY,
];

const evtUnit: Record<Events, unitOfTime> = {
  [Events.SECOND]: 's',
  [Events.MINUTE]: 'm',
  [Events.HOUR]: 'h',
  [Events.DAY]: 'd',
};

const evtLevelBelowUnit: Record<Events, unitOfTime[]> = {
  [Events.SECOND]: ['ms'],
  [Events.MINUTE]: ['s'],
  [Events.HOUR]: ['m', 's'],
  [Events.DAY]: ['h', 'm', 's'],
};

let _now: Moment = moment();

const lastUpdates: Record<Events, Moment> = {
  [Events.SECOND]: _now,
  [Events.MINUTE]: _now,
  [Events.HOUR]: _now,
  [Events.DAY]: _now,
};

const updateMoment = () => {
  _now = process.env.NODE_ENV === 'test' ? _now.clone().add(1, 's') : moment();
};

let timerId: any; // eslint-disable-line @typescript-eslint/no-explicit-any
let delay = Events.SECOND;
const subscribers: Record<string, Map<number, TimerCallback>> = {};
Object.keys(Events).forEach((evt) => {
  subscribers[evt] = new Map();
});

const runUpdatesAsync = (evt: Events) => {
  for (const cb of subscribers[evt].values()) {
    setTimeout(() => cb(moment()), 0); // async invocations of callbacks
  }
};
const execute = () => {
  runUpdatesAsync(Events.SECOND);
  conditionalUpdates.forEach((evt: Events) => {
    if (
      _now.diff(lastUpdates[evt], 's') >= evt ||
      evtLevelBelowUnit[evt].every((unit) => _now.get(unit) === 0) ||
      lastUpdates[evt].get(evtUnit[evt]) !== _now.get(evtUnit[evt])
    ) {
      lastUpdates[evt] = _now;
      runUpdatesAsync(evt);
    }
  });
};

const updateAndExecute = () => {
  updateMoment();
  execute();
};

const stop = () => clearTimeout(timerId);
const start = () => {
  if (Number.isFinite(delay)) {
    timerId = setTimeout(() => {
      updateAndExecute();
      start();
    }, 1000);
  }
};
const updateDelay = () => {
  stop();
  /* eslint-disable indent */
  delay =
    subscribers[Events.SECOND].size > 0
      ? Events.SECOND
      : subscribers[Events.MINUTE].size > 0
      ? Events.MINUTE
      : subscribers[Events.HOUR].size > 0
      ? Events.HOUR
      : subscribers[Events.DAY].size > 0
      ? Events.DAY
      : Infinity;
  /* eslint-enable indent */
  start();
};

let counter = -1;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const subscribe = (cb: any, evt: Events) => {
  counter += 1;
  subscribers[evt].set(counter, cb);
  updateDelay();
  setTimeout(() => cb(moment()), 0);
  // eslint-disable-next-line
  return (function (key) {
    return () => {
      subscribers[evt].delete(key);
      updateDelay();
    };
  })(counter);
};

export const updateTimeOnFullDays = (cb: TimerCallback) => subscribe(cb, Events.DAY);

export const updateTimeOnFullHours = (cb: TimerCallback) => subscribe(cb, Events.HOUR);

export const updateTimeOnFullMinutes = (cb: TimerCallback) => subscribe(cb, Events.MINUTE);

export const isPast = (date: Moment) => moment().isAfter(date);

export const useNowUpdatedEveryMinute = () => {
  const [nowMoment, setNowMoment] = useState(moment());

  useEffect(() => updateTimeOnFullMinutes(setNowMoment), []);

  return nowMoment.toDate();
};

export const useNowUpdatedEverySecond = () => {
  const [nowMoment, setNowMoment] = useState(moment());
  const millisecondsFromEpoch = nowMoment.valueOf();

  useEffect(() => {
    const timer = setTimeout(() => {
      setNowMoment(moment());
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
  }, [millisecondsFromEpoch]);

  return nowMoment.toDate();
};
