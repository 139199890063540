import React, { SyntheticEvent } from 'react';
import styled from 'styled-components';

import { DropdownDataProps } from './multiselect-dropdown.component';
import ResizeableCheckbox from './ResizeableCheckbox';

export interface ListItemProps {
  data: DropdownDataProps;
  isChecked: boolean;
  onChanged: (data: IMultiSelectListItemData) => void;
  size?: number;
  inputValue?: string | null;
  'data-test'?: string;
  analytics?: string;
  readOnly?: boolean;
  analyticsTeacherId?: string;
}

const ListContainer = styled.label`
  display: flex;
  align-items: center;
  position: relative;
  padding: 10px;
  cursor: pointer;
  font-size: 14px;
  user-select: none;

  &:hover {
    background-color: ${({ theme }) => theme.color.efGrey};
  }
`;

const ListCheckmark = styled.span`
  position: relative;
  top: 2px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
`;

const ListItemDescription = styled.span`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: normal;
  color: ${({ theme }) => theme.color.inkBlack};
`;

export const CharacterCountWrapper = styled.p`
  font-size: 12px;
  text-align: right;
`;

export interface IMultiSelectListItemData {
  value: string;
  checked: boolean;
  inputData?: string;
}

class ListItem extends React.Component<ListItemProps> {
  public onInputChange = (evt: SyntheticEvent) => {
    const target: HTMLInputElement = evt.target as HTMLInputElement;
    this.props.onChanged({
      value: this.props.data.value,
      checked: this.props.isChecked,

      inputData: target.value,
    });
  };

  public onItemChange = (evt: SyntheticEvent) => {
    const target: HTMLInputElement = evt.target as HTMLInputElement;
    this.props.onChanged({ value: target.value, checked: target.checked });
  };

  public render() {
    const { data, isChecked, size, readOnly } = this.props;
    return (
      <ListContainer>
        <ListCheckmark data-test={data.value}>
          <ResizeableCheckbox
            size={size}
            key={data.name}
            name="checkbox"
            disabled={readOnly}
            value={data.value}
            checked={isChecked}
            onChange={this.onItemChange}
            analytics={this.props.analytics}
            analyticsTeacherId={this.props.analyticsTeacherId}
          />
        </ListCheckmark>
        <ListItemDescription>{data.name}</ListItemDescription>
      </ListContainer>
    );
  }
}

export default ListItem;
