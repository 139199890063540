import { Fade } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { SideMenuItemContainer } from './styledComponents';

const linkStyles = `
  display: block;
  position: relative;
  margin-bottom: 8px;

  &:focus {
    outline: 0;
  }

`;

const StyledLink = styled(Link)`
  ${linkStyles}
`;
const StyledLinkTargetBlank = styled.a`
  ${linkStyles}
`;

const getActiveClassName = (linkTo: string, isActive: boolean) =>
  isActive || window.location.pathname.startsWith(linkTo) ? 'active' : '';

export const SideMenuItem = (
  { linkTo, closeDrawer, children, isActive, ...rest }: any, // eslint-disable-line  @typescript-eslint/no-explicit-any
) => (
  <StyledLink to={linkTo} {...rest} onClick={() => closeDrawer && closeDrawer()}>
    <SideMenuItemContainer className={getActiveClassName(linkTo, isActive)}>{children}</SideMenuItemContainer>
  </StyledLink>
);

export const SideMenuTargetBlankItem = (
  { isActive, linkTo, children, ...rest }: any, // eslint-disable-line  @typescript-eslint/no-explicit-any
) => (
  <StyledLinkTargetBlank href={linkTo} {...rest} rel="noreferrer noopener">
    <SideMenuItemContainer className={getActiveClassName(linkTo, isActive)}>{children}</SideMenuItemContainer>
  </StyledLinkTargetBlank>
);

interface SideBarFadeProps {
  children?: React.ReactElement;
  fadeIn: boolean;
  timeout?: number;
  delayIn?: number;
  delayOut?: number;
}

export const SideBarFade = ({ children, fadeIn, timeout = 300, delayIn = 100, delayOut = 0 }: SideBarFadeProps) =>
  !!children ? (
    <Fade
      in={fadeIn}
      style={{ transitionDelay: fadeIn ? `${delayIn}ms` : `${delayOut}ms` }}
      timeout={{ enter: timeout, exit: timeout }}
    >
      {children}
    </Fade>
  ) : null;
