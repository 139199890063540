import MockDate from 'mockdate';
import moment from 'moment-timezone';
import { setupWorker } from 'msw/browser';

import { config } from './config';
import { handlers } from './handlers';

export const worker = setupWorker(...handlers);

export const enableMocking = async (isMocked?: boolean) => {
  if (!isMocked) {
    return;
  }

  MockDate.set(moment(config.date, config.dateFormat).toDate());

  return worker.start({
    onUnhandledRequest: (req, print) => {
      const url = new URL(req.url);
      if (
        config.ignoredHostnames.includes(url.hostname) ||
        config.ignoredPathnames.some((p) => url.pathname.startsWith(p))
      ) {
        return;
      }

      print.warning();
    },
  });
};
