import { styled, Autocomplete } from '@mui/material';

export const DropdownIconWrapper = styled('div')`
  position: relative;
  margin-top: -3px;
`;

export const StyledAutocomplete = styled(Autocomplete)`
  z-index: 2000;
  & .MuiAutocomplete-inputRoot {
    padding-top: 0;
    padding-bottom: 0;
    font-size: 14px;
    height: 32px;
  }
  :not(.Mui-focused) input:not([disabled]) ::placeholder {
    opacity: 1;
  }
` as typeof Autocomplete;

interface IDrowdownIconContainer {
  disabled: boolean;
}

export const DropdownIconContainer = styled('span')<IDrowdownIconContainer>`
  position: absolute;
  right: 14px;
  top: 5px;
  transform: rotate(90deg);
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`;
