import BookingPL1 from 'cypress/fixtures/booking/data/booking-id-1.json';
import BookingPL2 from 'cypress/fixtures/booking/data/booking-id-2.json';
import BookingPL3 from 'cypress/fixtures/booking/data/booking-id-3.json';
import BookingPL4 from 'cypress/fixtures/booking/data/booking-id-4.json';
import BookingPL5 from 'cypress/fixtures/booking/data/booking-id-5.json';
import BookingGL6 from 'cypress/fixtures/booking/data/booking-id-6.json';
import LessonDefinitions from 'cypress/fixtures/lessonDefinitions.json';
import Teacher from 'cypress/fixtures/teachers/teacher.json';
import { HttpResponse, http } from 'msw';

import env from 'config/env';

export const handlers = [
  http.get(`${env.TF_SVCGATEWAY_URL}/v1/lesson-definitions`, () => HttpResponse.json(LessonDefinitions)),
  http.get(`${env.TF_SVCGATEWAY_URL}/v1/teachers/teacher-id`, () => HttpResponse.json(Teacher)),
  http.get(`${env.TF_SVCGATEWAY_URL}/v1/bookings/booking-id-1`, () => HttpResponse.json(BookingPL1)),
  http.get(`${env.TF_SVCGATEWAY_URL}/v1/bookings/booking-id-2`, () => HttpResponse.json(BookingPL2)),
  http.get(`${env.TF_SVCGATEWAY_URL}/v1/bookings/booking-id-3`, () => HttpResponse.json(BookingPL3)),
  http.get(`${env.TF_SVCGATEWAY_URL}/v1/bookings/booking-id-4`, () => HttpResponse.json(BookingPL4)),
  http.get(`${env.TF_SVCGATEWAY_URL}/v1/bookings/booking-id-5`, () => HttpResponse.json(BookingPL5)),
  http.get(`${env.TF_SVCGATEWAY_URL}/v1/bookings/booking-id-6`, () => HttpResponse.json(BookingGL6)),
];
