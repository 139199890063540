import cfgDefault from './defaultConfig';
import cfgDevEliveQa from './dev-elive-qa';
import cfgQaTFCn from './dev-elive-qa-cn';
import cfgDevOps from './dev-ops';
import cfgDevOpsCn from './dev-ops-cn';
import cfgDevOpsEliveQa from './dev-ops-elive-qa';
import cfgQaOpsCn from './dev-ops-elive-qa-cn';
import cfgDevTF from './dev-tf';
import cfgDevTFCn from './dev-tf-cn';
import cfgLocalOps from './local-ops';
import cfgLocalTF from './local-tf';
import cfgProdOps from './prod-ops';
import cfgProdOpsCn from './prod-ops-cn';
import cfgProdTF from './prod-tf';
import cfgProdTFCn from './prod-tf-cn';

export const defaultConfig = cfgDefault;
export const devEliveQa = cfgDevEliveQa;
export const devOps = cfgDevOps;
export const devOpsEliveQa = cfgDevOpsEliveQa;
export const devTF = cfgDevTF;
export const devTFCn = cfgDevTFCn;
export const devOpsCn = cfgDevOpsCn;
export const qaTFCn = cfgQaTFCn;
export const qaOpsCn = cfgQaOpsCn;

export const prodTFCn = cfgProdTFCn;
export const prodOpsCn = cfgProdOpsCn;
export const prodOps = cfgProdOps;
export const prodTF = cfgProdTF;
export const localTF = cfgLocalTF;
export const localOps = cfgLocalOps;
