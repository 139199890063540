import styled from 'styled-components';

import { PrimaryButton, SecondaryButton } from 'components/Button';

export const LobbyWrapper = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: ${({ theme }) => theme.color.zircon};
  z-index: 9000;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ClassInfo = styled.div`
  margin: 16px;
  font-size: 12px;
  line-height: 1.5;
  color: #003c64;
  font-weight: normal;
  position: absolute;
  top: 0;
  left: 0;
`;

export const ClassInfoHeader = styled.span`
  font-weight: bold;
`;

export const ScreenContainer = styled.div`
  width: 420px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Text = styled.div`
  color: #003c64;
  line-height: 22px;
  font-size: 16px;
`;

export const TextHeader = styled(Text)`
  font-weight: bold;
`;

export const AnimationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
  width: 300px;
  margin: 15px 0 20px;
  position: relative;
`;

export const TipsText = styled(Text)`
  height: 100px;
`;

export const EnterClassButton = styled(SecondaryButton)`
  margin-left: 16px;
  width: 160px;
  height: 40px;
`;

export const StudentEnterClassButton = styled(PrimaryButton)<{ disabled: boolean }>`
  margin-left: 16px;
  margin-bottom: 8px;
  width: 148px;
  height: 32px;
  ${(props) =>
    props.disabled
      ? `
    opacity: .5;
    pointer-events: none;
  `
      : ''}
`;
