import { PersonalInterest, Skill, TeacherRole, TeacherStatus } from 'config/teacherProfiles';

export interface IUser {
  id: string;
  lastName: string;
  firstName: string;
  email: string;
  permissions: IGrantedPermission[];
  roles: IUserRole[];
  isActive: boolean;
}

export interface ITeacher {
  id: string;
  lastName: string;
  firstName: string;
  email: string;
  emailSubscribed: boolean;
  departmentId?: string;
  personalInterests: PersonalInterest[];
  instructionLanguages: string[];
  skills: Skill[];
  path: string;
  asotMemberId?: string;
  role?: TeacherRole;
  status: TeacherStatus;
  school?: string;
  profilePicturePath?: string;
}

export interface ITeacherDerived extends ITeacher {
  fullName: string;
  fullNameLowerCase: string;
  initials: string;
}

export interface IGrantedPermission {
  permission: Permissions;
  resourceType: ResourceTypes;
  resourceId: string;
}

export interface IUserRole {
  role: IUserRoleType;
  resourceType: ResourceTypes;
  resourceId: string;
  id: string;
}

export enum IUserRoleType {
  THIRD_PARTY = 'thirdParty',
  INDEPENDENT_CONTRACTOR = 'independentContractor',
  STAFF = 'staff',
  FLEXIBLE_STAFF = 'flexibleStaff',
  TENANT_ADMIN = 'tenantAdmin',
  CENTER_ADMIN = 'toCenterAdmin',
  FRANCHISE_ADMIN = 'franchiseAdmin',
  SCHOOL_ADMIN = 'toSchoolAdmin',
  STUDENT_ADMIN = 'studentAdmin',
  STUDENT_INTEGRATOR = 'studentIntegrator',
  ASOT_INTEGRATOR = 'asotIntegrator',
}

export enum Permissions {
  VIEW_TEACHERS_PORTAL = 'ViewTeachersPortal',
  VIEW_OPS_PORTAL = 'ViewOpsPortal',
  VIEW_OPS_PORTAL_FOR_STUDENTS_PORTAL = 'ViewOpsPortalForStudentsPortal',
  VIEW_STUDENTS_PORTAL = 'ViewStudentsPortal',
  CREATE_INVOICES = 'CreateInvoices',
  CREATE_TEACHER_PROFILE = 'CreateTeacherProfile',
  VIEW_TEACHERS = 'ViewTeachers',
  VIEW_ATTENDANCE = 'ViewAttendance',
  EDIT_TEACHER_ROLE = 'EditTeacherRole',
  VIEW_CLASS_RECORDS = 'ViewClassRecords',
  EDIT_CLASS_RECORDS_CENTER_STATUS = 'EditClassRecordsCenterStatus',
  EDIT_CLASS_RECORDS_SYSTEM_STATUS = 'EditClassRecordsSystemStatus',
  VIEW_INVOICES = 'ViewInvoices',
  REVIEW_INVOICES = 'ReviewInvoices',
  SUBMIT_INVOICES = 'SubmitInvoices',
  VIEW_AVAILABILITY = 'ViewAvailability',
  EDIT_AVAILABILITY = 'EditAvailability',
  EDIT_TEACHER_FIRST_NAME = 'EditTeacherFirstName',
  EDIT_TEACHER_LAST_NAME = 'EditTeacherLastName',
  EDIT_TEACHER_EMAIL = 'EditTeacherEmail',
  EDIT_TEACHER_SKILLS = 'EditTeacherSkills',
  EDIT_TEACHER_INSTRUCTION_LANGUAGES = 'EditTeacherInstructionLanguages',
  EDIT_TEACHER_PERSONAL_INTERESTS = 'EditTeacherPersonalInterests',
  EDIT_TEACHER_PATH = 'EditTeacherPath',
  VIEW_TEACHER_PAY_RATE = 'ViewTeacherPayRate',
  EDIT_TEACHER_PAY_RATE = 'EditTeacherPayRate',
  EDIT_TEACHER_EMAIL_SUBSCRIPTION = 'EditTeacherEmailSubscription',
  EDIT_TEACHER_ASOT_MEMBER_ID = 'EditTeacherAsotMemberID',
  SUBOUT_BOOKING = 'SubOutBooking',
  VIEW_BOOKING_EXIT_SURVEY = 'ViewBookingExitSurvey',
  VIEW_PEAK_RANGES = 'ViewPeakRanges',
  CREATE_BOOKING_BY_TEACHER = 'CreateBookingsByTeacher',
  CREATE_BLOCK = 'CreateBlock',
  VIEW_BLOCK = 'ViewBlock',
  DELETE_BLOCK = 'DeactivateBlock',
  VIEW_CLASSROOM_RECORDING = 'ViewClassroomRecording',
  UPDATE_BOOKING_EXIT_SURVEY = 'UpdateBookingExitSurvey',
  VIEW_UNCLAIMED_BOOKINGS = 'ViewUnclaimedBookings',
  CLAIM_UNCLAIMED_BOOKINGS = 'ClaimUnclaimedBookings',
  CHATROOM_CREATE = 'CreateChatRoom',
  FORCE_APPROVE_INVOICES = 'ForceApproveInvoices',
  VIEW_EXCLUSIVE_AVAILABILITY = 'ExclusiveAvailabilityView',
  EDIT_EXCLUSIVE_AVAILABILITY = 'ExclusiveAvailabilityEdit',
  EDIT_RESERVE_TIMESLOT_SETTINGS = 'AvailabilitySettingsEdit',
  ANNOUNCEMENT_EDIT = 'AnnouncementEdit',
  ANNOUNCEMENT_VIEW = 'AnnouncementView',
  BLOCKED_SLOT_VIEW = 'BlockSlotView',
  BLOCKED_SLOT_EDIT = 'BlockSlotEdit',
}

const PermissionValues: Permissions[] = Object.values(Permissions);
export type PermissionTypes = (typeof PermissionValues)[number];

export enum Resources {
  TEACHER = 'teacher',
  HIERARCHY = 'hierarchy',
}

const ResourceValues: Resources[] = Object.values(Resources);
export type ResourceTypes = (typeof ResourceValues)[number];

export interface ITeacherProfile {
  id: string;
  firstName: string;
  lastName: string;
  department: {
    id: string;
    name: string;
    school: {
      id: string;
      name: string;
    };
  };
}

export interface EvcProxyTunnel {
  description: string;
  domain: string;
  id: string;
}
